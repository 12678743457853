/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
import './ChatMessage.css'
import React, { useContext, useEffect, useRef } from 'react'
import pfp_path from '../../../util/pfp_path'
import Linkify from 'linkify-react';
import { UserContext } from '../../../contexts/UserContextProvider';
import { ChatContext } from '../../../contexts/ChatContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const options = {
  target: '_blank',
  rel: 'nofollow noreferrer',
};

function ChatMessage({ op, timestamp, message, autofocus, firstNew }) {
  const { user: me } = useContext(UserContext);
  const { contact } = useContext(ChatContext);
  const author = op ? contact : me;
  const other = op ? me : contact;
  const ref = useRef();

  useEffect(() => {
    if(autofocus) {
      ref.current.scrollIntoView();
    }
  }, [autofocus]);

  let content = null;
  if(author) {
    if(message.type === 'text') {
      content = message.content.map((line, i) => (
        <span key={i}><Linkify options={options}>{line}</Linkify><br /></span>
      ))
    } else if(message.type === 'img') {
      content = message.content.map(({ file, width, height }, i) => {
        const renderWidth = Math.min(width, 500);
        const renderHeight = Math.round(height * renderWidth / width);

        return (
          <img key={i} className="chat-img" src={`/users/${author.id}/chat-img/${file}`} alt="" width={renderWidth.toString()} height={renderHeight.toString()} />
        )
    });
    } else if(message.type === 'attach') {
      content = message.content.map(({ file, name }, i) => {
        return (
          <div key={i} className="chat-attachment">
            <FontAwesomeIcon icon="fa-solid fa-download" /> <a href={`/users/${author.id}/chat-attach/${file}`} download={name}>{name}</a>
          </div>
        )
    });
    } else if(message.type === 'product') {
      content = (
        <div className="chat-product">
          <div>
            <h2 style={{ margin: '0 0 10px 0' }}>Product Request</h2>
          </div>
          <div>
            <img
              src={`/users/${other.id}/products/_${message.product.image}`} 
              width={message.product.width} height={message.product.height}
              alt="[image updated]" />
          </div>
          <div style={{ marginTop: '10px' }}>
            <a href={`/products/view/${message.product.id}`} target="_blank">{message.product.title}</a>
          </div>
          <div style={{ marginTop: '20px' }}>
          <span><b>Message:</b><br /></span>
          {
            message.content.map((line, i) => (
              <span key={i}><Linkify options={options}>{line}</Linkify><br /></span>
            ))
          }
          </div>
        </div>
      )
    }
  }

  return (
    <>
      {firstNew && 
      <div className="chat-new-messages">
        NEW MESSAGES
      </div>
      }
      <div ref={ref} className={`chat-parent ${!op && 'chat-parent-right'}`}>
        {content && <>
        <div className="chat-message-user">
          <img src={pfp_path(author.id, author.pfp)} width="48" height="48" alt="" />
          <div className="user-link">{author.username}</div>
        </div>
        <div>
          <div className="chat-timestamp">{timestamp}</div>
          <div className="chat-message">
            {content}
          </div>
        </div>
        </>}
      </div>
    </>
  )
}

export default ChatMessage