import React from 'react';
import './MenuToggle.css';
import { ReactComponent as Toggle } from './toggle.svg';
import useClickOutside from '../../../hooks/useClickOutside';

function MenuToggle(props) {
  const toggleRef = useClickOutside(props.onClose);

  return (
    <div className={`menu-toggle ${props.active && 'menu-toggle-active'}`}>
      <Toggle ref={toggleRef} onClick={props.onToggle} />
    </div>
  );
}

export default MenuToggle;