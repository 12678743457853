
export const rules = {
  email: (value) => {
    value = value.trim();
    if(!value) {
      return false;
    }
    if(value.length > 128) {
      return 'Email cannot be longer than 128 characters.';
    }
    return true;
  },
  username: (value) => {
    value = value.trim();
    if(!value) {
      return false;
    }
    if(value.length < 3) {
      return 'Username must be at least 3 characters long.';
    }
    if(value.length > 24) {
      return 'Username cannot be longer than 24 characters.';
    }
    if(!/^[a-zA-Z0-9_]+$/.test(value)) {
      return 'Username can only contain letters, numbers, and underscores.';
    }
    if(!/[a-zA-Z]/.test(value)) {
      return 'Username must contain at least one letter.';
    }
    return true;
  },
  password: (value) => {
    if(!value) {
      return false;
    }
    if(value.length < 8) {
      return 'Password must be at least 8 characters long.';
    }
    if(value.length > 128) {
      return 'Password cannot be longer than 128 characters.';
    }
    return true;
  },
};
