import './LoadingTimetable.css';
import React from 'react';
import Loading from '../../../Loading/Loading';

function LoadingTimetable({ failed, retry }) {
  return (
    <div className="shifts-loading-timetable form-segment">
      {
        !failed ? <Loading /> : <>
          <h2>Failed to load your timetable</h2>
          <button className="wbtn" onClick={retry}>Try Again</button>
        </>
      }
    </div>
  );
}

export default LoadingTimetable;