
export function getDisplayPrice(priceType, price) {
  const priceDisplay = {
    fixed: `$${price?.toLocaleString('en-US')} CAD`,
    contact: 'Please Contact',
    trade: 'Swap/Trade',
  };

  return priceType ? priceDisplay[priceType] : 'Looking for / Wanted, Please Contact';
}
