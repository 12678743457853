import './ChatContacts.css';

import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import pfp_path from '../../../util/pfp_path';
import { ChatContext } from '../../../contexts/ChatContextProvider';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContextProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { socket } from '../../../App';

function ChatContacts(props, ref) {
  const { user: me } = useContext(UserContext);
  const { page, contact, setContact, hasGhost, ghost, setGhost, notifications, searchParams } = useContext(ChatContext);
  const [contacts, setContacts] = useState(null);
  const [loadedPage, setloadedPage] = useState(null);
  const useDefault = useRef(true);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    axios.get('/chat/get-contacts/'+page)
    .then(({ data }) => {
      setContacts(data);
      setloadedPage(page);
    });
  }, [page]);

  useEffect(() => {
    if(!me || !useDefault.current || searchParams.get('r')) return;
    if(hasGhost && ghost !== null) {
      setContact({...ghost, page: 'message'});
      useDefault.current = false;
    } else if(!hasGhost && contacts !== null) {
      setContact(contacts[0] ? {...contacts[0], page} : null);
      useDefault.current = false;
    }
  });

  useImperativeHandle(ref, () => {
    return {
      filter: setFilter,
    };
  }, []);

  const useDefaultRequest = useRef(true);
  useEffect(() => {
    if(!searchParams.get('r') || !contacts || !useDefaultRequest.current) return;

    // eslint-disable-next-line eqeqeq
    const requested = contacts?.find(({ id }) => id == searchParams.get('r'));
    if(requested) setContact({ ...requested, page: 'request' });
    useDefaultRequest.current = false;
  }, [searchParams, contacts, setContact]);

  const shownContacts = useMemo(() => {
    if(!contacts) return [];
    
    let res = [...contacts];
    if(page === 'message' && ghost)
      res = [ghost, ...res.filter(({ id }) => id !== ghost.id)];

    if(filter) {
      return res.filter(({ username }) => {
        if(filter.length < 3)
          return username.toUpperCase().startsWith(filter.toUpperCase());
        return username.toUpperCase().includes(filter.toUpperCase());
      }).sort((a, b) => {
        const au = a.username.toUpperCase();
        const bu = b.username.toUpperCase();
        if(au === bu) return 0;
        return au < bu ? -1 : 1;
      });
    }

    return res;
  }, [filter, contacts, ghost, page]);

  useEffect(() => {
    socket.on('chat author', (author, rel) => {
      if(author.id !== ghost?.id) {
        setContacts((contacts) => {
          if(page !== rel) return contacts;
          return [author, ...contacts.filter(({ id }) => id !== author.id)];
        });
      } else {
        setGhost(author);
      }
    });
    return () => {
      socket.off('chat author');
    };
  }, [page, ghost, setGhost]);

  if(loadedPage !== page) return null;

  const renderEmpty = () => {
    if(filter) {
      return 'No results found.';
    }
    if(page === 'message') {
      return (
        <>
          You haven't messaged anyone yet. <Link to="/browse-users">Browse users here.</Link>
        </>
      )
    }
    if(page === 'request') {
      return 'No message requests.';
    }
    if(page === 'block') {
      return "You haven't blocked anyone.";
    }
    return null;
  };

  return (
    <div className="chat-users no-select">
      {shownContacts.length ? shownContacts.map((other) => {
        const { username, id, pfp, last_msg } = other;
        const lastRead = notifications.get(`chat_messages.${id}`);
        const isUnread = me && me.id !== id && last_msg && (!lastRead || new Date(lastRead) < new Date(last_msg));
        const isSelected = id === contact?.id;
        return (
          <div key={id} className={`chat-user ${isSelected && 'chat-user-active'}`} onClick={() => setContact({...other, page})}>
            {!isSelected && isUnread && 
              <div className="user-unread">
                <FontAwesomeIcon icon="fa-solid fa-circle" />
              </div>
            }
            <img src={pfp_path(id, pfp)} width="64" height="64" alt="" />
            <span className="user-link">{username}</span>
          </div>
        )
      }) : <div className="chat-users-empty">{renderEmpty()}</div>}
    </div>
  )
}

export default forwardRef(ChatContacts)