import React, { useContext, useMemo, useRef, useState } from 'react';
import DateObject from "react-date-object";
import DatePicker from "react-multi-date-picker";
import { DateContext } from '../../contexts/DateContextProvider';
import SelectHours from '../SelectHours/SelectHours';
import './DateScheduler.css';
import AllowNotifications from '../AllowNotifications/AllowNotifications';

const ZEROFILL_TIME = Array(96).fill(0);
const labels = {
  search: {
    days: 'Select your available dates below.',
    desc: `
    Select your available hours for each day down below.
    Not specifying any hours for a day means that you are available that entire day.
    `,
  },
  job: {
    days: 'Select your available shift dates below.',
    desc: `
    Select your available work hours for each day down below.
    `,
  }
};

function DateScheduler({ mode }) {
  const c = labels[mode];
  const datePickerRef = useRef();
  const { days, setDays, setTimelineData } = useContext(DateContext);
  const [dates, setDates] = useState(() => days.map(day => new DateObject(day)));

  const minDate = useMemo(() => new DateObject(), []);
  const maxDate = useMemo(() => {
    const now = new Date();
    now.setMonth(now.getMonth() + 4);
    return new DateObject(now);
  }, []);

  const onDateChange = (ndates) => {
    // close calendar every time a date is added
    // if(ndates.length > dates.length) {
    //   datePickerRef.current.closeCalendar();
    // }
    if(ndates.length > 10) {
      alert('You can only select up to 10 dates. You can remove previously selected dates by clicking on them.');
      return false;
    }
    setDates(ndates);
    setDays(ndates.map(date => date.toString()));
    setTimelineData(intervals => ({
      ...Object.fromEntries(ndates.map(day => [day.toString(), ZEROFILL_TIME])),
      ...intervals,
    }));
  };

  return (<>
    <div className="form-field">
      <div className="shifts-label">{c.days}</div>
    </div>
    <DatePicker 
      ref={datePickerRef}
      value={dates} onChange={onDateChange}
      multiple
      minDate={minDate}
      maxDate={maxDate}
      highlightToday={false}
      sort={true}
      disableYearPicker={true}
      placeholder="Click to display calendar"
      inputClass="toggle-placeholder"
      containerStyle={{ width: '100%' }}
      fixMainPosition={true}
      calendarPosition="top-left"
    />
    <SelectHours context={DateContext} desc={c.desc} />
    {days.length > 0 && mode === 'search' && <AllowNotifications context={DateContext} />}
  </>)
}

export default DateScheduler