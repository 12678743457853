import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Autocomplete from "react-google-autocomplete";
import { useNavigate, useParams } from 'react-router';
import { UserContext } from '../../../../contexts/UserContextProvider';
import useDebounce from '../../../../hooks/useDebounce';
import useUnsavedChanges from '../../../../hooks/useUnsavedChanges';
import { SUPPORTED_EXTENSIONS, getext } from '../../../../util/extensions';
import { validateUrl } from '../../../../util/url';
import Button from '../../../Button/Button';
import CustomEditor from '../../../CustomEditor/CustomEditor';
import Redirect from '../../../Redirect/Redirect';
import './PostShift.css';
import DateContextProvider from '../../contexts/DateContextProvider';
import WeekContextProvider from '../../contexts/WeekContextProvider';
import AdvancedSearch from '../AdvancedSearch/AdvancedSearch';
import { DateObject } from 'react-multi-date-picker';

function PostShift() {
  const { id } = useParams();
  const { user } = useContext(UserContext)
  const navigate = useNavigate();
  const today = useMemo(() => new DateObject().toString(), []);
  const timeDataRef = useRef();

  const formRef = useRef();
  const editorRef = useRef();
  const logoRef = useRef();
  const submitRef = useRef();
  const placeRef = useRef('');
  const created = useRef(false);
  const originalLoaded = useRef(false);
  const dirty = useRef(false);

  const [locType, setLocType] = useState('site');
  const [applyMethod, setApplyMethod] = useState('email');
  const [logo, setLogo] = useState(null);
  const [errors, setErrors] = useState([]);
  const [ready, setReady] = useState(false);
  const [bodySaveData, setBodySaveData] = useState(null);

  useEffect(() => {
    if(!id) return;

    const controller = window.AbortController ? new window.AbortController() : null;

    axios.get('/shifts/info/'+id, { signal: controller?.signal })
    .then(({ data }) => {
      loadData(data);
      
      setTimeActive(data.hours.active);
      if(data.hours.active === 'date') {
        setDateData({
          days: data.hours.days,
          timelineData: data.hours.timelineData,
        });
      } else if(data.hours.active === 'week') {
        setWeekData({
          days: data.hours.days,
          timelineData: data.hours.timelineData,
        });
      }
      setTimeLoaded(true);

      setTimeout(() => {
        originalLoaded.current = true;
      }, 1000);
    }).catch(() => {});

    return () => {
      controller?.abort();
    };
  }, [id]);

  useEffect(() => {
    if(!ready || !bodySaveData) return;
    setTimeout(() => {
      editorRef.current.setData(bodySaveData);
    }, 0);
  }, [ready, bodySaveData]);

  useEffect(() => {
    if(!user || id || localStorage.getItem('shift_post')) return;
    formRef.current.elements['applyEmail'].value = user.email;
  }, [user, id]);

  useUnsavedChanges(() => {
    return id && dirty.current && !created.current;
  });

  const jobLocTypeChange = (e) => {
    setLocType(e.target.value);
  };

  const jobPostMethod = (e) => {
    setApplyMethod(e.target.value);
  };

  const logoChange = (e) => {
    const files = Array.from(e.target.files);
    if(!files.length) return;

    const [logo] = files;
    if(!SUPPORTED_EXTENSIONS.includes(getext(logo.name))) {
      alert('Logo: file extension not allowed.');
      e.target.value = null;
      return;
    }
    if(logo.size > 10*1024*1024) {
      alert('Logo: file is too large. The limit is 10MB.');
      e.target.value = null;
      return;
    }

    const formData = new FormData();
    formData.append('logo', logo);
    axios.post('/post-job/logo', formData)
    .then(({ data }) => {
      setLogo(data);
    })
    .catch((error) => {
      if(error.response.status === 429) {
        alert('File was not uploaded. You have reached your daily upload limit.');
      } else if(error.response.data) {
        alert(error.response.data);
      } else {
        alert('Something went wrong.');
      }
    })
    .finally(() => {
      e.target.value = null;
    });
  };

  const clearLogo = (e) => {
    logoRef.current.value = null;
    setLogo(null);
    onFormChange();
  };

  const onFormChange = () => {
    if(originalLoaded.current) dirty.current = true;
    autosave();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = Object.fromEntries(new FormData(e.target).entries());
    data.desc = editorRef.current.getData();

    const errors = [];
    const validate = (name, fn) => {
      const res = fn(data[name]);
      if(typeof res === 'string')
        errors.push({ name, value: res });
    };

    validate('title', (title) => {
      if(!title.trim())
        return 'Enter a title.';
      if(title.trim().replace(/\s+/g, ' ').length < 3)
        return 'Title is too short.';
    });

    validate('desc', (desc) => {
      if(!desc)
        return 'Enter a description.';
    });

    validate('loc', (loc) => {
      if(data.locType !== 'remote' && !loc.trim())
        return 'Enter a location.';
      if(loc.trim() && loc.trim() !== placeRef.current)
        return 'Select a location from the list of suggestions that appears when you start typing.';
    });

    const validateComp = (num) => {
      num = num.trim();
      if(!num) return;

      num = num.replace(/[$\s,_]/g, '');
      if(num.includes('.'))
        return 'Decimal places are not allowed.';

      if(/\dk$/i.test(num))
        num = num.slice(0, -1) + '000';
      if(!/^\d+$/.test(num))
        return 'Enter a numeric value.';
      if(/^0+$/.test(num))
        return "Can't be zero. Leave it blank instead."
      if(/^0+/.test(num))
        return 'Leading zeroes are not allowed.';
      if(num.length > 6)
        return 'Number is too large. Must be under a million.';
    };

    validate('compMin', (value) => {
      const err = validateComp(value);
      if(err)
        return 'Compensation Min: ' + err;
    });
    validate('compMax', (value) => {
      if(validateComp(data.compMin)) return;
      const err = validateComp(value);
      if(err)
        return 'Compensation Max: ' + err;
    });
    validate('companyName', (company) => {
      if(!company.trim())
        return 'Enter a company name.';
    });
    validate('companyUrl', (url) => {
      url = url.trim();
      if(!url) return;
      if(!validateUrl(url))
        return 'Enter a valid company URL.';
    });

    validate('applyEmail', (email) => {
      if(applyMethod !== 'email') return;
      if(!email.trim())
        return 'Enter an email address under How to Apply.';
    });

    validate('applyUrl', (url) => {
      if(applyMethod !== 'url') return;
      if(!url.trim())
        return 'Enter a URL under How to Apply, or select the Apply by Email option instead.';
      if(!validateUrl(url))
        return 'Enter a valid URL under How to Apply.';
    });

    timeSave[1](); // flush

    const timeData = timeDataRef.current;
    if(!timeData.active) {
      errors.push({name: 'hours', value: "You need to specify your job's schedule."});
    } else {
      data.type = timeData.active;
      const isWeek = data.type === 'week';
      if(isWeek) {
        data.timetable = timeData.week.timelineData;
      } else {
        data.timetable = timeData.date.timelineData;
      }

      const days = Object.keys(data.timetable).filter(day => day >= today).sort().slice(0, isWeek ? 7 : 10);
      const unspecifiedDays = days.filter(day => {
        return data.timetable[day].every(bit => !bit);
      });

      if(!days.length) {
        errors.push({name: 'hours', value: "You need to specify your job's schedule."});
      } else if(unspecifiedDays.length) {
        errors.push({name: 'hours', value: "You need to specify your shift's available hours for the following days: " + unspecifiedDays.join(', ')});
      } else {
        data.timetable = Object.fromEntries(days.map(day => [day, data.timetable[day]]));
      }
    }

    if(errors.length) {
      setErrors(errors);
      window.scrollTo(0, 0);
      return;
    }

    const transformComp = (num) => {
      num = num.trim();
      if(!num) return 0;
      num = num.replace(/[$\s,_]/g, '');
      if(/\dk$/i.test(num))
        num = num.slice(0, -1) + '000';
      return num;
    };
    data.compMin = transformComp(data.compMin);
    data.compMax = transformComp(data.compMax);
    if(!data.companyUrl.trim()) delete data.companyUrl;

    submitRef.current.setPending(true);
    axios.post('/shifts/post' + (id ? `/${id}` : ''), data)
    .then(({ data }) => {
      created.current = true;
      if(!id) {
        localStorage.removeItem('shift_post');
        localStorage.removeItem('shift_post_t');
      }
      navigate('/shifts/view/'+data.id, { replace: !id });
      setErrors([]);
    })
    .catch((error) => {
      if(error.response.status === 429) {
        const rl_error = id ? 
                        'You have reached your daily job editing limit.' :
                        'You can only post up to 2 jobs for free during this limited-time promotion.';
        alert(rl_error);
        setErrors([]);
        return;
      }
      const msg = error.response.status === 413 ? 
                  'Your job post contains too much data.' :
                  error.response.data || 'Something went wrong.';
      setErrors([{ name: 'generic', value: msg }]);
      window.scrollTo(0, 0);
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  const clear = (e) => {
    if(!window.confirm("Are you sure you want to clear the contents of your entire job post? This will delete all of your current progress.")) {
      e.preventDefault();
      return;
    }
    editorRef.current.setData('');
    if(!id) localStorage.removeItem('shift_post');
    setLocType('site');
    setApplyMethod('email');
    setLogo(null);
    setErrors([]);
    setTimeout(() => {
      if(user) formRef.current.elements['applyEmail'].value = user.email;
    }, 0);
    timeClear();
    window.scrollTo(0, 0);
  };

  const err = Object.fromEntries(
    errors.map(error => {
      return [error.name, error.value];
    })
  );

  const [autosave, flush] = useDebounce(() => {
    if(!editorRef.current || created.current || id) return;
    const body = editorRef.current.getData();
    const data = Object.fromEntries(new FormData(formRef.current).entries());
    data.placeRef = placeRef.current ?? '';
    localStorage.setItem('shift_post', JSON.stringify({ body, data }));
  }, 1000, { flushOnUnmount: false, stall: 3000 });

  const loadData = ({ body, data }) => {
    for(let [key, val] of Object.entries(data)) {
      let inputs = formRef.current.elements[key];
      if(!inputs) continue;
      if(!(inputs instanceof NodeList)) inputs = [inputs];
      inputs.forEach(input => {
        switch(input.type) {
          case 'checkbox':
            input.checked = !!val;
            break;
          case 'radio':
            input.checked = input.value === val;
            break;
          default:
            input.value = val;
            break;
        }
      });
    }
    placeRef.current = data.placeRef ?? data.loc;
    setBodySaveData(body);
    setLocType(data.locType);
    setApplyMethod(data.method);
    setLogo(data.logo ?? null);
  };

  const onReady = (editor) => {
    setReady(true);
    if(id) return;

    editor.on('destroy', () => {
      flush();
    });

    const saved = localStorage.getItem('shift_post');
    if(!saved) return;
    loadData(JSON.parse(saved));
  };

  const locPlaceholder = locType === 'remote' ? 'Optional: enter office location' : 'Example: Toronto, ON, Canada';

  const [timeLoaded, setTimeLoaded] = useState(false);
  const [timeActive, setTimeActive] = useState(null);
  const [dateData, setDateData] = useState(null);
  const [weekData, setWeekData] = useState(null);

  const timeSaveFn = useCallback((_event, type, state, newType) => {
    if(created.current) return;

    if(id) {
      dirty.current = true;
      return;
    }

    const saved = localStorage.getItem('shift_post_t');
    const oldData = saved ? JSON.parse(saved) : null;
    const active = newType ?? type;

    if(!state) {
      localStorage.setItem('shift_post_t', JSON.stringify({
        active,
        date: oldData?.date ?? null,
        week: oldData?.week ?? null,
      }));
    } else {
      const newState = {
        days: state.days,
        timelineData: Object.fromEntries(
          state.days
          .map(day => [day, state.timelineData[day]])
        ),
      };

      if(type === 'date') {
        localStorage.setItem('shift_post_t', JSON.stringify({
          active,
          date: newState,
          week: oldData?.week ?? null,
        }));
      } else if(type === 'week') {
        localStorage.setItem('shift_post_t', JSON.stringify({
          active,
          date: oldData?.date ?? null,
          week: newState,
        }));
      }
    }
  }, [id]);

  const timeSave = useDebounce(timeSaveFn, 1000, { stall: 3000 });

  const timeClear = useCallback(() => {
    timeSave[1](true); // flush

    setTimeActive(null);
    setDateData(null);
    setWeekData(null);

    if(!id) localStorage.removeItem('shift_post_t');
    setTimeLoaded(false);
    setTimeout(() => {
      setTimeLoaded(true);
    }, 0);
  }, [id, timeSave]);

  useEffect(() => {
    if(!id) {
      const saved = localStorage.getItem('shift_post_t');
      if(!saved) {
        timeClear();
      } else {
        const data = JSON.parse(saved);
        setTimeActive(data.active);
        setDateData(data.date);
        setWeekData(data.week);
      }
      setTimeLoaded(true);
    }   
    return () => {
      setTimeLoaded(false);
    };
  }, [timeClear, id]);

  return (
    <div className="post-job page">
      <Redirect to="/register" unless="user" />
      <div className="post-job-view">
        <h1>Post a Shift</h1>
        {errors.length > 0 &&
          <div className="form-error post-job-error">
            <div>Error:</div>
            <ul>
              {errors.map(error => <li key={error.name}>{error.value}</li>)}
            </ul>
          </div>
        }
        <form ref={(elem) => formRef.current = elem ?? formRef.current} onSubmit={onSubmit} onChange={onFormChange}>
          <div className="form-field">
            <label htmlFor="post-job-title" data-err={err.title}>Job Title*</label>
            <input id="post-job-title" maxLength="200" name="title" />
            <div className="alert" style={{ marginTop: '5px' }}>
              <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />
              Make sure your job title also includes your company name for better search results.
            </div>
          </div>
          <div className="form-field">
            <div className="post-job-label" data-err={err.desc}>Description*</div>
            <CustomEditor ref={editorRef} uploadUrl="/upload/j" onReady={onReady} onChange={onFormChange} />
          </div>
          <div className="form-field">
            <div className="post-job-label" data-err={err.loc}>Location*</div>
            <div className="post-job-loc">
              <select onChange={jobLocTypeChange} name="locType">
                <option value="site">On Site</option>
                <option value="remote">Remote</option>
                <option value="hybrid">Hybrid</option>
              </select>
              <Autocomplete onFocus={(e) => e.target.autocomplete = 'new-password'} onPlaceSelected={(_, input) => placeRef.current = input.value} name="loc" maxLength="128" placeholder={locPlaceholder} apiKey={process.env.REACT_APP_GOOGLE_API} />
            </div>
          </div>
          <div className="form-field">
            <div className="post-job-label">
              <span data-err={err.compMin || err.compMax}>Hourly Pay</span>
            </div>
            <div className="post-job-comp">
              <div style={{ margin: 'auto 5px', verticalAlign: 'middle' }}>$</div>
              <input name="compMin" maxLength="16" placeholder="Min amount" />
              <div style={{ margin: 'auto 5px' }}>-</div>
              <input name="compMax" maxLength="16" placeholder="Max amount" />
              <div style={{ margin: 'auto 5px', verticalAlign: 'middle' }}>CAD</div>
            </div>
          </div>
          <div className="post-job-company form-field">
            <div>
              <label htmlFor="post-job-cn" data-err={err.companyName}>Company Name*</label>
              <input name="companyName" id="post-job-cn" maxLength="128" />
            </div>
            <div>
              <label htmlFor="post-job-cs" data-err={err.companyUrl}>Company URL</label>
              <input name="companyUrl" id="post-job-cs" placeholder="Link to website" maxLength="128" />
            </div>
          </div>
          <div className="form-field" style={{ width: '200px' }}>
            <div className="post-job-label">Company Logo</div>
            <input name="logo" type="hidden" value={logo ?? ''} />
            <input ref={logoRef} type="file" hidden accept={SUPPORTED_EXTENSIONS.join()} onChange={logoChange} />
            <button type="button" className="wbtn wbtn-outline slim" onClick={(e) => e.target.previousSibling.click()}>UPLOAD IMAGE</button>
            {
              logo && user?.perm && <>
                <img src={`/users/${user.id}/jobs/${logo}`} alt="Logo Preview" style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }} />
                <button onClick={clearLogo} type="button" className="wbtn wbtn-outline slim" style={{ width: 'fit-content', display: 'block', marginTop: '5px' }}>
                  <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                </button>
              </>
            }
          </div>
          <div className="form-field" style={{marginBottom: '0'}}>
            <div className="post-job-label" data-err={err.applyEmail || err.applyUrl}>How to Apply*</div>
            <div className="post-job-types">
              <label className="b-contain">
                <span>Apply by Email</span>
                <input type="radio" name="method" value="email" defaultChecked onChange={jobPostMethod} />
                <div className="b-input"></div>
              </label>
              <label className="b-contain">
                <span>Apply by URL</span>
                <input type="radio" name="method" value="url" onChange={jobPostMethod} />
                <div className="b-input"></div>
              </label>
            </div>
          </div>
          <div style={{ display: applyMethod === 'email' ? 'block' : 'none' }}>
            <input name="applyEmail" maxLength="128" style={{ width: '290px' }} placeholder="Your email" />
            <br /><small>Applications for this job will be sent to this email.</small>
          </div>
          <div style={{ display: applyMethod === 'url' ? 'block' : 'none' }}>
            <input name="applyUrl" maxLength="128" style={{ width: '290px' }} placeholder="i.e. https://yourwebsite.com/apply" />
            <br /><small>Applicants will go to this URL to apply for the job.</small>
          </div>
          {timeLoaded &&
          <DateContextProvider data={dateData} autosave={timeSave}>
            <WeekContextProvider data={weekData} autosave={timeSave}>
              <AdvancedSearch ref={timeDataRef} mode="job" style={{ marginTop: 30 }} autosave={timeSave} clearData={timeClear} active={timeActive} />
            </WeekContextProvider>
          </DateContextProvider>}
          <div className="write-post-buttons">
            <button type="reset" className="wbtn wbtn-outline" onClick={clear}>Clear</button>
            <Button ref={submitRef} className="wbtn" type="submit">{id ? 'Update Shift' : 'Post Shift'}</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PostShift