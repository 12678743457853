import { useNavigate, useParams } from 'react-router';
import './ViewJob.css';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios';
import Error404 from '../../Error404/Error404';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import transformPost from '../../GenericPost/transformPost';
import { linkifyUrl, prettifyUrl } from '../../../util/url';
import { UserContext } from '../../../contexts/UserContextProvider';
import Button from '../../Button/Button';
import useTitle from '../../../hooks/useTitle';
import ShowMore from '../../ShowMore/ShowMore';
import useShowMore from '../../../hooks/useShowMore';
import useObserver from '../../../hooks/useObserver';
import useDescription from '../../../hooks/useDescription';

function ViewJob() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [job, setJob] = useState();
  const body = useMemo(() => job && transformPost(job.body), [job]);
  const listRef = useRef();
  const setTitle = useTitle();
  const setDescription = useDescription();

  const showMore = useShowMore();
  const [scrollObserver] = useObserver(showMore);

  const jobTypes = {
    site: 'In person',
    remote: 'Remote',
    hybrid: 'Hybrid',
  };
  const positions = {
    fulltime: 'Full Time',
    parttime: 'Part Time',
    contract: 'Contract',
    temporary: 'Temporary',
  };

  useEffect(() => {
    axios.get('/get-job/'+id)
    .then(({ data }) => {
      setJob(data);
      setTitle(data.title);
      setDescription(data.description);
    })
    .catch((error) => {
      if(error.response.status === 404)
        setJob(null);
    });
  }, [id, setTitle, setDescription]);

  if(job === null) return <Error404 />;
  if(!job) return null;

  let comp = null;
  if(job.comp_min) {
    comp = '$' + job.comp_min.toLocaleString('en-US');
    if(job.comp_min !== job.comp_max) comp += ' - $' + job.comp_max.toLocaleString('en-US');
    comp += ' per ' + job.comp_freq;
    if(job.is_comm) comp += ' + Commission';
  } else if(job.is_comm) {
    comp = 'Commission';
  }

  const applyNow = job.apply_url ? linkifyUrl(job.apply_url) : '/jobs/apply/'+id;
  const applyTarget = job.apply_url ? '_blank' : '_self';
  const toggleListing = () => {
    if(job.listed && !window.confirm([
      "Are you sure you want to unlist this job?",
      "You won't be able to receive any new applications while unlisted.",
    ].join('\n'))) {
      return;
    }

    listRef.current.setPending(true);
    const listed = job.listed ? 0 : 1;
    axios.post('/list-job', { listed, id })
    .then(() => {
      setJob({ ...job, listed });
    })
    .catch(() => {
      alert('Something went wrong.');
    })
    .finally(() => {
      listRef.current.setPending(false);
    });
  };

  return (
    <div className="view-job page">
      <div className="view-job-area">
        <div className="view-job-left">
          <div className="view-job-box" style={{ flex: 1 }}>
            <h1>{job.title}</h1>
            <ShowMore ref={scrollObserver} scroll={800}>
              <div className="ck-content preview" dir="ltr" dangerouslySetInnerHTML={{ __html: body }} />
            </ShowMore>
          </div>
          {user?.id === job.user_id &&
          <div className="view-job-box view-job-actions">
            <button className="wbtn" onClick={() => navigate('/post-job/'+id)}>Edit</button>
            <Button ref={listRef} className="wbtn wbtn-outline" onClick={toggleListing}>{job.listed ? 'Unlist' : 'Relist'}</Button>
          </div>
          }
        </div>
        <div className="view-job-box view-job-right">
          {job.logo && <img className="view-job-logo" src={`/users/${job.user_id}/jobs/${job.logo}`} alt="Logo" />} 
          <div className="view-job-info">
            <div className="view-job-label">Company</div>
            <strong>{job.company_name}</strong>
          </div>
          {job.company_url && <div className="view-job-info">
            <div className="view-job-label">Website</div>
            <FontAwesomeIcon icon="fa-solid fa-link" />
            &nbsp;<Link to={linkifyUrl(job.company_url)} target="_blank">{prettifyUrl(job.company_url)}</Link>
          </div>}
          {job.loc && <div className="view-job-info">
            <div className="view-job-label">{job.loc_type === 'remote' ? 'Office Location' : 'Location'}</div>
            <FontAwesomeIcon icon="fa-solid fa-location-dot" />
            &nbsp;{job.loc}
          </div>}
          <div className="view-job-info">
            <div className="view-job-label">Job Type</div>
            <div style={{ fontWeight: 600 }}>{jobTypes[job.loc_type]}</div>
          </div>
          <div className="view-job-info">
            <div className="view-job-label">Available Position(s)</div>
            <div style={{ fontWeight: 600 }}>{job.positions.map(position => positions[position]).join(', ')}</div>
          </div>
          {comp && <div className="view-job-info">
            <div className="view-job-label">Compensation</div>
            <div style={{ fontWeight: job.comp_min ? 300 : 600, fontSize: job.comp_min ? '16px' : '18px' }}>{comp}</div>
          </div>}
          <div className="form-field">
            <Link to={applyNow} target={applyTarget} className="wbtn slim">Apply Now</Link>
          </div>
        </div>
      </div>
      {job.applications && 
        <div className="view-job-applications view-job-box">
          <h2 style={{ margin: 0 }}>Applications</h2>
          {
            !job.applications.length ?
            <p style={{ margin: 0, marginTop: '20px' }}>No one has applied yet.</p> :
            <ul>
              {job.applications.map(({ id, full_name, timestamp }) =>
                <li key={id}>
                  <Link to={`/job-application/${id}`}>{full_name}</Link>
                  <br />
                  <span className="post-timestamp">{timestamp}</span>
                </li>
              )}
            </ul>
          }
        </div>
      }
    </div>
  )
}

export default ViewJob