
import { useCallback, useEffect, useRef } from 'react';
import useIsMounted from './useIsMounted';

function useTitle() {
  const titleRef = useRef(document.title);
  const isMounted = useIsMounted();

  useEffect(() => {
    const oldTitle = titleRef.current;
    return () => {
      document.title = oldTitle;
    };
  }, []);

  return useCallback((newTitle) => {
    if(!isMounted()) return;
    document.title = newTitle;
  }, [isMounted]);
}

export default useTitle;