import { useRef } from 'react';

function useLock(callback) {
  const locked = useRef(false);

  return [
    (...args) => {
      if(locked.current) return;
      locked.current = true;
      callback(...args);
    },
    () => {
      locked.current = false;
    },
  ];
}

export default useLock;