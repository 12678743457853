import { useEffect } from "react";
import { useBlocker } from "react-router";

function useUnsavedChanges(hasUnsavedChanges) {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => 
    currentLocation.pathname !== nextLocation.pathname &&
    !!hasUnsavedChanges(nextLocation.pathname)
  );

  useEffect(() => {
    const unload = (e) => {
      if(hasUnsavedChanges(null)) {
        e.preventDefault();
        e.returnValue = true;
      }
    };
    window.addEventListener('beforeunload', unload);
    return () => {
      window.removeEventListener('beforeunload', unload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if(blocker.state === 'blocked') {
      const leave = window.confirm('Changes you made may not be saved. Leave anyway?');
      if(leave) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  return blocker;
}

export default useUnsavedChanges;
