import React, { useCallback, useContext, useEffect, useState } from 'react'
import MenuAlert from '../MenuAlert'
import { globals, socket } from '../../../../App';
import { UserContext } from '../../../../contexts/UserContextProvider';

function Important({ compact }) {
  const { user } = useContext(UserContext);
  const [alerts, setAlerts] = useState([]);
  
  globals._setAlertsImp = (fn) => {
    if(!user) return;
    user.important = fn(user.important);
    setAlerts(user.important);
  };

  const updateAlerts = useCallback(() => {
    if(!user) return;
    setAlerts(user.important);
  }, [user]);

  useEffect(() => {
    updateAlerts();
  }, [updateAlerts]);

  useEffect(() => {
    if(!user) return;

    socket.on('imp', (alert) => {
      globals._setAlertsImp(alerts => addAlert(alerts, alert).slice(0, 99));
    });

    return () => {
      socket.off('imp');
    };
  }, [user]);

  const onread = (_id) => {
    if(!user) return;
    user.important = user.important.map(alert => {
      if(alert._id === _id)
        return { ...alert, read: true };
      return alert;
    });
  };

  const convertToView = (data) => {
    const { _id, timestamp, read } = data;
    return {
      _id,
      timestamp,
      read,
      message: getMessage(data),
      url: getUrl(data),
    };
  };

  return (
    <MenuAlert name="important" alerts={alerts.map(convertToView)} compact={compact} icon="fa-solid fa-bell" 
               onclose={updateAlerts} onread={onread} />
  );
}

const getMessage = ({ group, ...etc }) => {
  if(group === 'job_application') {
    const { applicant, company_name } = etc;
    return <>
      <span className="user-link">@{applicant.username}</span>
      &nbsp;({applicant.full_name}) sent you a job application for {company_name}.
    </>
  } else if(group === 'product_request') {
    const { username, title } = etc.product;
    return <>
      <span className="user-link">@{username}</span>
      &nbsp; contacted you for "{title}"
    </>
  } else if(group === 'shift_match') {
    const { shift_title } = etc;
    return <>
      You have a matching shift with: "{shift_title}..."
    </>
  }
  return etc.message;
};

const getUrl = ({ group, ...etc }) => {
  if(group === 'job_application') {
    const { application_id, application_type } = etc;
    return `/job-application/${application_id}` + (application_type === 'shift' ? '?type=shift' : '');
  } else if(group === 'product_request') {
    const { client } = etc.product;
    return `/chat/${client}`;
  } else if(group === 'shift_match') {
    const { shift_id } = etc;
    return `/shifts/view/${shift_id}`;
  }
  return etc.url;
};

const addAlert = (alerts, alert) => {  
  return [alert, ...alerts];
};

export default Important