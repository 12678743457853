import './AllowNotifications.css';
import React, { useContext } from 'react';

function AllowNotifications({ context }) {
  const { notify, setNotify } = useContext(context);

  return (
    <div className="shifts-allow-notifications">
      <label className="b-contain">
        <span>Notify me when there are matching shifts available</span>
        <input type="checkbox" checked={notify} onChange={e => setNotify(e.target.checked)} />
        <div className="b-input"></div>
      </label>
    </div>
  );
}

export default AllowNotifications;
