import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'

function JobPreview({ id, title, description, timestamp, user_id, company_name, logo, listed, loc_type, loc, type='job' }) {
  return (
    <Link className="fake-link post-container" to={`/${type === 'shift' ? 'shifts' : 'jobs'}/view/${id}`}>
      <div className="post-viewport">
        <div className="post-content">
            <div>
              <FontAwesomeIcon icon="fa-solid fa-location-dot" /> {loc_type === 'remote' ? 'Remote' : loc}
            </div>
            <h1>{!listed && '[UNLISTED] '}{title}</h1>
            <div className="ck-content preview" dir="ltr">
              <p>{description} …</p>
            </div>
        </div>
        <div className="post-footer">
          <div className="post-author">
            <div className="post-timestamp">
            Posted {timestamp}
            </div>
            {logo &&
            <div className="post-pfp">
              <div>
                <img alt={company_name} src={`/users/${user_id}/jobs/${logo}`} style={{ maxHeight: '100px' }} />
              </div>
            </div>}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default memo(JobPreview)