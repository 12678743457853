
export default function transformPost(body) {
  const doc = new DOMParser().parseFromString(body, 'text/html');
  Array.prototype.forEach.call(doc.querySelectorAll('a'), (elem) => {
    elem.setAttribute('target', '_blank');
    elem.setAttribute('rel', 'nofollow noreferrer');
  });
  Array.prototype.forEach.call(doc.querySelectorAll('oembed'), (elem) => {
    const url = elem.getAttribute('url') ?? '';
    const isYoutube = url.startsWith('https://www.youtube.com/');
    const isVimeo = url.startsWith('https://player.vimeo.com/');

    if(!isYoutube && !isVimeo) return;
    if(elem.parentNode.tagName !== 'FIGURE' || elem.parentNode.className !== 'media') return;

    const oembed = document.createElement('div');
    oembed.setAttribute('data-oembed-url', url);
    
    const div = document.createElement('div');
    div.setAttribute('style', 'position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;');

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.setAttribute('style', 'position: absolute; width: 100%; height: 100%; top: 0; left: 0;');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '');

    if(isYoutube) {
      iframe.setAttribute('allow', 'autoplay; encrypted-media');
    } else {
      iframe.setAttribute('webkitallowfullscreen', '');
      iframe.setAttribute('mozallowfullscreen', '');
    }

    div.appendChild(iframe);
    oembed.appendChild(div);
    elem.parentNode.appendChild(oembed);
    elem.parentNode.removeChild(elem);
  });
  return doc.body.innerHTML;
};
