import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/UserContextProvider';
import { useNavigate } from 'react-router';

function Redirect({ to, unless }) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(!user) return;
    
    if(unless === 'guest' && user.perm) {
      navigate(to, { replace: true });
    } else if(unless === 'user' && !user.perm) {
      navigate(to, { replace: true });
    }
  });

  return null;
}

export default Redirect;