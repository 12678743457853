import FormError from '../../../FormError/FormError';
import Tab from '../../../Tabs/Tab/Tab';
import Tabs from '../../../Tabs/Tabs';
import Timeline, { convertToBits, convertToIntervals } from '../../../Timeline/Timeline';
import getTimeIntervals from '../../helpers/getTimeIntervals';
import getTimeText from '../../helpers/getTimeText';
import TimeSelect from '../TimeSelect/TimeSelect';
import './SelectHours.css'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

const USE_TEXT_EDITOR = false;

function SelectHours({ context, desc }) {
  const { days, timelineData, setTimelineData } = useContext(context);

  const [timelineEditor, setTimelineEditor] = useState('text');
  const [timeTextError, setTimeTextError] = useState(null);
  const [day, setDay] = useState(days[0] ?? null);

  const tblocker = useRef(false);
  const tblockTimer = useRef(null);

  const timelineVisualChange = useCallback((intervals) => {
    setTimelineData(timelineData => ({
      ...timelineData,
      [day]: convertToBits(intervals)
    }));
  }, [day, setTimelineData]);

  const timelineTextBlur = (e) => {
    const intervals = getTimeIntervals(e.target.value);
    
    clearTimeout(tblockTimer.current);
    tblocker.current = false;

    if(typeof intervals === 'string') {
      tblocker.current = true;
      tblockTimer.current = setTimeout(() => {
        tblocker.current = false;
      }, 200);

      setTimeTextError(intervals);
      return;
    }

    setTimeTextError(null);
    setTimelineData(timelineData => ({
      ...timelineData,
      [day]: convertToBits(intervals)
    }));
  };

  const timelineIntervals = useMemo(() => {
    if(!day) return [];
    return convertToIntervals(timelineData[day]);
  }, [timelineData, day]);

  const timelineText = useMemo(() => {
    if(!day) return '';
    return getTimeText(convertToIntervals(timelineData[day]));
  }, [timelineData, day]);

  const copyHours = () => {
    const conf = window.confirm('This will override the hours of every other day to match this one.');
    if(!conf) return;
    
    setTimelineData(timelineData => ({
      ...timelineData,
      ...Object.fromEntries(days.map(other => [other, timelineData[day]])),
    }));
  };

  const dayChange = (day) => {
    setDay(day);
    setTimeTextError(null);
  };

  const switchToVisual = (e) => {
    e.preventDefault();
    if(tblocker.current) return;
    setTimelineEditor('visual');
    setTimeTextError(null);
  };

  const switchToText = (e) => {
    e.preventDefault();
    setTimelineEditor('text');
  };

  useEffect(() => {
    if(!days.some(other => other === day)) {
      setTimeTextError(null);
      setDay(days[0] ?? null);
    }
  }, [days, day]);

  if(!day) return null;
  return (
    <>
      <p style={{ marginTop: 30 }}>{desc}</p>
      <Tabs selected={day} onChange={dayChange} blocker={tblocker}>
        {days.map(day => 
          <Tab key={day}>{day}</Tab>
        )}
      </Tabs>
      {timelineEditor === 'visual' ?
      <div>
        <p>Click and drag to create time intervals.</p>
        <p>To delete a time interval, drag one endpoint to another.</p>
        <p style={{ marginBottom: 0 }}>
          <a href="#text" onClick={switchToText}>Switch to time editor</a>
        </p>
        <div style={{ textAlign: 'right', marginTop: 20 }}>
          <button type="button" className="wbtn slim" onClick={copyHours}>Copy hours to all days</button>
        </div>
        <Timeline key={day} data={timelineIntervals} onChange={timelineVisualChange} />
      </div> : USE_TEXT_EDITOR ?
      <div>
        <p>To enter your available times, follow this format: <b>5:00pm-7:00pm</b></p>
        <p>
          If you have multiple times, separate them by commas.<br />
          Note that you should approximate your minutes to 0, 15, 30, or 45 (for example, don't enter <b>5:20pm</b>).
        </p>
        <p>
          <a href="#visual" onClick={switchToVisual}>Switch to visual editor</a>
        </p>
        <FormError msg={timeTextError} style={{ maxWidth: 400 }} />
        <textarea 
          key={day}
          defaultValue={timelineText}
          onBlur={timelineTextBlur} 
          className="toggle-placeholder"
          rows="5"
          placeholder="8:00am-12:15pm, 5:30pm-7:30pm" />
      </div> :
      <TimeSelect key={day} switchToVisual={switchToVisual} day={day} intervals={timelineIntervals} copyHours={copyHours} context={context} />}
    </>
  )
}

export default SelectHours