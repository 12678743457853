import { useEffect, useRef } from 'react';

function useUnmount(callback) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  useEffect(() => {
    return () => {
      callbackRef.current();
    };
  }, []);
}

export default useUnmount;