
function getTimeText(intervals) {
  const pointToTime = (x) => {
    let h = (x>>2) % 24;
    let m = (x%4) * 15;
    let p = h < 12 ? 'am' : 'pm';

    h = h%12 || '12';
    m = m || '00';
    return `${h}:${m}${p}`;
  };
  return intervals.map(([a, b]) => {
    return `${pointToTime(a)}-${pointToTime(b)}`;
  }).join(', ');
}

export default getTimeText;
