import React, { useRef, useState } from 'react';
import FormError from '../../FormError/FormError';
import useValidation from '../../../hooks/useValidation';
import axios from 'axios';
import Button from '../../Button/Button';
import ReCAPTCHA from 'react-google-recaptcha';

function ResetPassword() {
  const { error, useField, validateAll, throwError, removeError } = useValidation({ email: true });
  const submitRef = useRef();
  const captchaRef = useRef();
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = validateAll();
    removeError('generic');
    removeError('success');
    if(!data) return;

    data.email = data.email.trim();
    if(captchaRef.current)
      data.captcha = captchaRef.current.getValue();
    
    submitRef.current.setPending(true);
    axios.post('/account/reset-password', data)
    .then(() => {
      throwError('success', 'An email has been sent to you containing a link to reset your password. Check your inbox and spam folders.');
    })
    .catch((error) => {
      if(error.response.status === 400) {
        throwError('generic', 'No account with that email exists.');
      } else if(error.response.status === 429) {
        throwError('generic', 'Please click on the checkbox below.');
        setShowCaptcha(true);
      } else {
        throwError('generic', 'Something went wrong.');
      }
      captchaRef.current?.reset();
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  return (
    <div className="reset-password container">
      <form className="form-panel" onSubmit={handleSubmit}>
        <h1>Reset Password</h1>
        <FormError msg={error.msg.success} success />
        <FormError msg={error.msg.generic} />
        <div className="form-field" data-error={error.has.email}>
          <label htmlFor="reset-password-email">Account Email*</label>
          <input name="email" id="reset-password-email" ref={useField()} maxLength="256" placeholder="Enter your account's email" />
        </div>
        {showCaptcha && <div style={{ marginTop: 15 }}>
          {<ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_API}
          />}
        </div>}
        <div className="form-field">
          <Button ref={submitRef} type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;