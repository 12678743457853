import React from 'react';
import './MenuDivider.css';
import { ReactComponent as Divider } from './divider.svg';

function MenuDivider() {
  return (
    <div className="menu-divider">
      <Divider />
    </div>
  );
}

export default MenuDivider;