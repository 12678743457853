import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './PostPreview.css'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import pfp_path from '../../util/pfp_path';
import PostVotes from '../PostVotes/PostVotes';

function PostPreview({ id, title, description, rating, voted, responses, created, author_id, author_username, author_pfp }) {
  return (
    <Link className="fake-link post-container" to={`/posts/view/${id}`}>
      <PostVotes total={rating} mine={voted} />
      <div className="post-viewport">
        <div className="post-content">
            <h1>{title}</h1>
            <div className="ck-content preview" dir="ltr">
              <p>{description} …</p>
            </div>
        </div>
        <div className="post-footer">
          <div className="post-leftmeta">
            <FontAwesomeIcon icon="fa-solid fa-comments" /> {responses} response{responses !== 1 && 's'}
          </div>
          <div className="post-author">
            <div className="post-timestamp">
            Posted {created}
            </div>
            <div className="post-pfp">
              <div>
                <img width="64" height="64" alt="" src={pfp_path(author_id, author_pfp)} />
              </div>
              <div className="post-pfp-text">
                <span to={`/user/${author_username}`} className="user-link">{author_username}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default memo(PostPreview)