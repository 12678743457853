import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BackToTop.css';
import React, { useEffect, useState } from 'react'

const MIN_SCROLL_Y = 400;

function BackToTop() {
  const [visible, setVisible] = useState(false);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    const checkScroll = () => {
      setVisible(window.scrollY > MIN_SCROLL_Y);
    };    
    checkScroll();
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <>
    {visible &&
      <div className="back-to-top" onClick={scrollTop}>
        <FontAwesomeIcon icon="fa-solid fa-angle-up" />
      </div>
    }
    </>
  )
}

export default BackToTop