import React from 'react';
import './MenuLogo.css';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './logo.svg';

function MenuLogo({ style }) {
  return (
    <div className="menu-logo" style={style}>
      <Link to="/" style={{ display: 'inline-block' }}>
        <Logo style={{ width: '64px', verticalAlign: 'middle' }} />
      </Link>
    </div>
  );
}

export default MenuLogo;