import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import DateScheduler from '../DateScheduler/DateScheduler';
import WeekScheduler from '../WeekScheduler/WeekScheduler';
import './AdvancedSearch.css';
import { DateContext } from '../../contexts/DateContextProvider';
import { WeekContext } from '../../contexts/WeekContextProvider';
import axios from 'axios';

const labels = {
  search: {
    title: 'Advanced Search',
    desc: `
    Search for shifts based on your availability and be notified when new shifts
    matching your schedule become available.
    `,
    date: "I'm available on specific dates",
    week: "I'm available on specific days of the week",
  },
  job: {
    title: 'Schedule Selector',
    desc: `
    Configure your job's work schedule. Select the days and hours on which you need someone.
    `,
    date: "Looking for someone on specific dates",
    week: "Looking for someone on specific days of the week",
  },
};

function AdvancedSearch({ autosave: [autosave, flush], setTimetableStatus, clearData, active, style, mode='search' }, selfRef) {
  const [method, setMethod] = useState(active);
  const { reset: resetDate, save: saveDate, ...dateData } = useContext(DateContext);
  const { reset: resetWeek, save: saveWeek, ...weekData } = useContext(WeekContext);
  const [hidden, setHidden] = useState(true);
  const c = labels[mode];

  useImperativeHandle(selfRef, () => {
    return {
      active: method,
      date: {
        days: dateData.days,
        timelineData: Object.fromEntries(
          dateData.days.map(day => [day, dateData.timelineData[day]])
        ),
      },
      week: {
        days: weekData.days,
        timelineData: Object.fromEntries(
          weekData.days.map(day => [day, weekData.timelineData[day]])
        ),
      }
    };
  }, [method, dateData.days, dateData.timelineData, weekData.days, weekData.timelineData]);

  const methodChange = (e) => {
    const newMethod = e.target.value;
    if(!newMethod) return;

    if(!method) {
      autosave(newMethod);
      flush();
    } else {
      if(method === 'week') {
        saveWeek(newMethod);
      } else {
        saveDate(newMethod);
      }
    }
    
    setMethod(newMethod);
    setHidden(false);
  };

  const reset = (e) => {
    e.preventDefault();
    const conf = window.confirm(`Are you sure you want to reset ${c.title}? This will completely clear any dates, days, or hours you have selected.`);
    if(!conf) return;

    if(mode === 'search') {
      flush(true);
      setTimetableStatus('waiting');

      axios.post('/shifts/timetable', { action: 'reset' })
      .then(() => {
        resetDate();
        resetWeek();
        setMethod(null);
        setHidden(true);
        clearData();
      })
      .catch(() => {
        alert('Something went wrong.');
      })
      .finally(() => {
        setTimetableStatus('ready');
      });
    } else if(mode === 'job') {
      clearData();
    }
  };

  const hide = (e) => {
    e.preventDefault();
    setHidden(true);
  };

  const show = (e) => {
    e.preventDefault();
    setHidden(false);
  };

  return (
    <div className="advanced-search form-segment" onSubmit={e => e.preventDefault()} style={style}>
      <h2>{c.title}</h2>

      <p>
      {c.desc}
      </p>

      <div className="form-field">
        <div className="shifts-method">
          <label className="b-contain">
            <span>{c.date}</span>
            <input checked={method === 'date'} type="radio" value="date" onChange={methodChange} />
            <div className="b-input"></div>
          </label>
          <label className="b-contain">
            <span>{c.week}</span>
            <input checked={method === 'week'} type="radio" value="week" onChange={methodChange} />
            <div className="b-input"></div>
          </label>
        </div>
      </div>

      {!hidden ? <>
        <div className="advanced-search-util">
          <a href="#hide" onClick={hide}>[hide]</a>
          <a href="#reset" onClick={reset}>[reset]</a>
        </div>

        {method === 'date' && 
          <DateScheduler mode={mode} />
        }
        
        {method === 'week' &&
          <WeekScheduler mode={mode} />
        }
      </> : method &&
        <div className="advanced-search-util">
          <a href="#show" onClick={show}>[click here to show/edit]</a>
        </div>
      }
    </div>
  )
}

export default forwardRef(AdvancedSearch)