import { useCallback } from 'react';

const useUpdater = (dataRef, key, setState, update) => {
  return useCallback((value, shouldUpdate=true) => {
    dataRef.current[key] = typeof value === 'function' ? value(dataRef.current[key]) : value;
    if(shouldUpdate) update(dataRef.current);
    setState(value);
  }, [dataRef, key, setState, update]);
};

export default useUpdater;
