
export const obj_get = (obj, path) => {
  if(!obj) return null;
  const point = path.indexOf('.'); 
  if(point === -1) return obj[path];
  return obj_get(obj[path.slice(0, point)], path.slice(point+1));
};

export const obj_set = (obj, path, fn = (v) => v) => {
  const point = path.indexOf('.');
  if(point === -1) return obj[path] = fn(obj[path]);
  return obj_set(obj[path.slice(0, point)] ??= {}, path.slice(point+1), fn);
};
