import React, { forwardRef, useImperativeHandle, useState } from 'react'

const PRICE_REGEX = /^\d{0,6}$/;

function SellPrice({ disabled }, ref) {
  const [value, setValue] = useState('');

  useImperativeHandle(ref, () => {
    return { setValue, value };
  }, [value]);

  const onChange = (e) => {
    if(PRICE_REGEX.test(e.target.value)) {
      setValue(e.target.value);
    }
  };

  return (
    <input
      name="price" onChange={onChange}
      disabled={disabled} value={!disabled ? value : ''} 
      placeholder={!disabled ? 'No decimals allowed' : ''}
      style={{ width: '200px' }}
    />
  )
}

export default forwardRef(SellPrice)
