import './ViewPost.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useTitle from '../../hooks/useTitle';
import { lastURLSegment } from '../../util/url';
import { useLocation } from 'react-router';
import Comments from '../Comments/Comments';
import GenericPost from '../GenericPost/GenericPost';
import PostContextProvider from '../../contexts/PostContextProvider';
import Error404 from '../Error404/Error404';
import useDescription from '../../hooks/useDescription';

function ViewPost() {
  const { pathname, hash } = useLocation();
  const postId = lastURLSegment(pathname);

  const [postContent, setPostContent] = useState();
  const setTitle = useTitle();
  const setDescription = useDescription();

  const [comments, setComments] = useState([]);

  useEffect(() => {
    axios.get(`/get-post/${postId}`)
    .then(({ data }) => {
      setTitle(data.title);
      setDescription(data.description);
      const { comments, ...post } = data;
      setPostContent(post);
      setComments(comments);
    })
    .catch((error) => {
      if(error.response.status === 404) {
        setPostContent(null);
      }
    });
  }, [postId, setTitle, setDescription]);

  if(postContent === null) return <Error404 />
  if(!postContent) return null;

  let mentions = [];
  mentions.push(postContent.author_username);
  comments.forEach(comment => {
    mentions.push(comment.author_username);
    comment.replies.forEach(reply => {
      mentions.push(reply.author_username);
    })
  });
  mentions = [...new Set(mentions)].sort();

  return (
    <div className="view-post page">
      <PostContextProvider id={postId} setTitle={setTitle} mentions={mentions}>
        <div className="view-post-area">
          <GenericPost type="post" {...postContent} glow={hash === `#p${postId}`} />
          <Comments init={comments} anchor={hash} />
        </div>
      </PostContextProvider>
    </div>
  );
}

export default ViewPost;