import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SellImage.css';
import React from 'react'
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";

function SellImage({ src, name, onDelete, isThumbnail }) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: name });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="sell-image" ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="sell-image-bg" style={{ backgroundImage: `url(${src})` }} />
      <div className="sell-image-toolbar">
        {isThumbnail && !isDragging && <div className="sell-image-first no-select">FIRST</div>}
        <FontAwesomeIcon icon="fa-solid fa-trash-can" style={{ cursor: 'pointer' }} onClick={() => onDelete(name)} />
      </div>
    </div>
  )
}

export default SellImage
