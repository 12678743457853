import './ProductPreview.css';
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { getDisplayPrice } from '../ViewProduct/productInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ProductPreview({ id, user_id, title, description, timestamp, image, width, height, listed, price, priceType, transport, address }) {
  const displayPrice = getDisplayPrice(priceType, price);
  const hasShipping = transport.split(',').includes('shipping');
  const displayTitle = title.length < 100 ? title : title.slice(0, 100) + ' …';

  return (
    <Link className="product-preview fake-link post-container" to={`/products/view/${id}`}>
      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', width: '100%', alignItems: 'center' }}>
        <div className="product-preview-img"> 
          <img src={`/users/${user_id}/products/_${image}`} width={width} height={height} alt={title} />
        </div>
        <div className="post-viewport" style={{ flex: 1, display: 'flex', flexDirection: 'column', maxHeight: '100%' }}>
          <div className="post-content">
            <div className="view-product-price">{displayPrice}</div>
            <h1>{!listed && '[UNLISTED] '}{displayTitle}</h1>
            <div className="product-preview-icons">
              <div><FontAwesomeIcon icon="fa-solid fa-location-dot" /> {address}</div>
              {hasShipping && <div style={{ marginTop: '5px' }}><FontAwesomeIcon icon="fa-solid fa-check" /> Shipping available</div>}
            </div>
            <div className="ck-content preview" dir="ltr">
              <p>{description.slice(0, 100)} …</p>
            </div>
          </div>
          <div className="post-footer" style={{ marginTop: 'auto' }}>
            <div className="post-author">
              <div className="post-timestamp">
              Posted {timestamp}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default memo(ProductPreview)
