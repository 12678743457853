import './TimeSelect.css'
import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PointSelect from './PointSelect/PointSelect';
import { convertToBits } from '../../../Timeline/Timeline';

const DelInv = ({ onClick }) => {
  const click = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a href="#del" style={{ marginLeft: 10 }} onClick={click}>
      <FontAwesomeIcon icon="fa-solid fa-trash" />
    </a>
  );
};

function TimeSelect({ context, switchToVisual, day, intervals, copyHours }) {
  const { setTimelineData } = useContext(context);

  const [data, setData] = useState(() => {
    if(!intervals.length)
      return [[Math.random(), 0, 96]];
    return intervals.map(([a, b]) => [Math.random(), a, b]);
  });

  const updateInv = (newData) => {
    const valid = newData.every(cur => {
      return cur[1] < cur[2];
    });

    if(valid) {
      const intervals = newData.map(([, a, b]) => [a, b]);
      setTimelineData(timelineData => ({
        ...timelineData,
        [day]: convertToBits(intervals)
      }));
    }
  };

  const change = (i, w, v) => {
    const id = data[i][0];
    if(w && v === 0) v = 96;
    let newRow = [id, w === 0 ? v : data[i][1], w === 1 ? v : data[i][2]];

    const newData = [
      ...data.slice(0, i),
      newRow,
      ...data.slice(i+1),
    ];
    setData(newData);
    updateInv(newData);
  };

  const addInv = (e) => {
    e.preventDefault();
    setData([...data, [Math.random(), 0, 96]]);
  };

  const delInv = (i) => {
    const newData = [...data.slice(0, i), ...data.slice(i+1)];
    setData(newData);
    updateInv(newData);
  };

  return (
    <div className="time-select">
      <p>Select your available times for <b>{day}</b> down below.</p>
      <p>
        <a href="#visual" onClick={switchToVisual}>Switch to visual editor</a>
      </p>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        {data.map(([id, a, b], i) => {
          return (
            <div key={id}>
              <PointSelect value={a} onChange={change.bind(null, i, 0)} />
              &nbsp;&nbsp;-&nbsp;&nbsp;
              <PointSelect value={b} onChange={change.bind(null, i, 1)} />
              {i > 0 && <DelInv onClick={delInv.bind(null, i)} />}
            </div>
          )
        })}
      </div>
      <div style={{ marginTop: '10px' }}>
        <a href="#add" onClick={addInv}>
          <FontAwesomeIcon icon="fa-solid fa-plus" />
        </a>
      </div>
      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <button type="button" className="wbtn slim" onClick={copyHours}>Copy hours to all days</button>
      </div>
    </div>
  );
}

export default TimeSelect;