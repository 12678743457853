// anything 'offset' related is pretty much obsolete
// since the way it works is very misleading and
// does not behave the way you would expect.
// assume offset=0 everywhere.
import './Timeline.css';
import useObserver from '../../hooks/useObserver';
import clamp from '../../util/clamp';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

const HOUR_WIDTH = 100;
const QUARTER_WIDTH = HOUR_WIDTH / 4;

export function convertToBits(intervals, offset=0) {
  const shift = offset*4;
  const bits = new Uint8Array(96);
  intervals.forEach(([a, b]) => {
    for(let i = a; i < b; i++)
      bits[(i+shift)%bits.length] = 1;
  });
  return Array.from(bits);
}

export function convertToIntervals(bits, offset=0) {
  const shift = offset*4;
  bits = [...bits.slice(shift), ...bits.slice(0, shift)];

  const intervals = [];
  let j = 0;
  for(let i = 0; i < bits.length; i++) {
    if(i && bits[i] === bits[i-1])
      continue; 
    if(bits[i])
      j = i;
    else if(j < i)
      intervals.push([j, i]);
  }
  if(bits[bits.length-1])
    intervals.push([j, bits.length]);
  return intervals;
}

function intervalEqual(a, b) {
  if(a === null) return b === null;
  if(b === null) return false;
  return a[0] === b[0] && a[1] === b[1];
}

function clone(intervals) {
  return intervals.map(([a, b]) => [a, b]);
}

function Timeline({ offset=0, data=[], onChange, ...props }) {
  const [intervals, setIntervals] = useState(data);
  const [selectedInterval, _setSelectedInterval] = useState(null);
  const [cursor, setCursor] = useState(null);
  const [active, setActive] = useState(null);

  const [columns, setColumns] = useState(24);
  const resizeObserver = useMemo(() => new ResizeObserver(entries => {
    if(!entries.length) return;
    const columns = Math.floor(entries[0].contentRect.width / HOUR_WIDTH);
    setColumns(clamp(columns, 1, 24));
  }), []);
  const [observeTimeline, timelineRef] = useObserver(resizeObserver);

  const lastMouseX = useRef(0);
  const deltaX = useRef(0);

  // only update state if interval has actually changed
  const setSelectedInterval = (newInterval) => {
    _setSelectedInterval(selectedInterval => {
      if(intervalEqual(newInterval, selectedInterval))
        return selectedInterval;
      return newInterval;
    });
  };

  const start = (target, mouseX, realMouseX) => {
    if(active !== null) return;

    const rect = target.getBoundingClientRect();
    const index = parseInt(target.dataset.index, 10);

    const clickX = index*HOUR_WIDTH + mouseX-rect.left;
    const clickPoint = clamp(Math.round(clickX / QUARTER_WIDTH), 0, 96);

    const collision = intervals
    .flatMap((interval, i) => 
      [[interval[0], [i, 0]], [interval[1], [i, 1]]]
    )
    .concat(cursor !== null ? [[cursor, cursor]] : [])
    .filter(([point]) => (
      clickX >= (point-1) * QUARTER_WIDTH &&
      clickX <  (point+1) * QUARTER_WIDTH
    ))
    .reduce((closest, cur) => {
      const distA = Math.abs(closest[0]*QUARTER_WIDTH - clickX);
      const distB = Math.abs(cur[0]*QUARTER_WIDTH - clickX);
      if(distA < distB) return closest;
      if(distA > distB) return cur;
      return closest[0] < cur[0] ? cur : closest;
    }, [Infinity, null]);

    lastMouseX.current = realMouseX;
    if(collision[1] !== null) {
      onObjectClick(collision[1]);
    } 
    // prevent setting the cursor on an interval
    else if(
      !intervals.some(([a, b]) => (
        clickPoint >= a && clickPoint <= b
      ))
    ) {
      onObjectClick(clickPoint);
    }
  };

  const move = (e) => {
    if(active === null) return;
    const isPoint = typeof active === 'number';
    const cursor = isPoint ? active : intervals[active[0]][active[1]];

    const mouseX = e.clientX;
    deltaX.current += mouseX - lastMouseX.current;
    deltaX.current = clamp(deltaX.current, -cursor*QUARTER_WIDTH, (96-cursor)*QUARTER_WIDTH);
    lastMouseX.current = mouseX;

    const quartersMoved = Math.trunc(deltaX.current/QUARTER_WIDTH);
    const endpoint = cursor + quartersMoved;

    if(isPoint) {
      if(endpoint !== cursor) {
        setCursor(null);
        setSelectedInterval([Math.min(cursor, endpoint), Math.max(cursor, endpoint)]);
      } else {
        setCursor(endpoint);
        setSelectedInterval(null);
      }
    } else {
      const other = intervals[active[0]][active[1]^1];
      const [a, b] = [Math.min(other, endpoint), Math.max(other, endpoint)];
      if(a !== b) {
        setCursor(null);
        setSelectedInterval([a, b]);
      } else {
        setCursor(endpoint);
        setSelectedInterval(null);
      }
    }
  };

  const end = () => {
    if(active === null) return;

    const merge = (intervals) => {
      const [x1, x2] = selectedInterval;
      
      const pruned = intervals.filter(([a, b]) => {
        return a < x1 || b > x2;
      });

      const i = pruned.findIndex(([a, b]) => {
        return b >= x1 && a <= x2;
      });

      if(i !== -1) {
        const [o1, o2] = pruned[i];
        pruned.splice(i, 1, [Math.min(x1, o1), Math.max(x2, o2)]);
      } else {
        pruned.push(selectedInterval);
      }

      return pruned;
    };

    if(typeof active === 'number') {
      if(selectedInterval !== null) {
        const newIntervals = merge(intervals);
        setIntervals(newIntervals);
        onChange?.(clone(newIntervals));
        setCursor(null);
      } else {
        setCursor(active);
      }      
    } else {
      const newIntervals = [...intervals];
      newIntervals.splice(active[0], 1);
      if(selectedInterval !== null) {
        const merged = merge(newIntervals);
        setIntervals(merged);
        if(!intervalEqual(intervals[active[0]], selectedInterval))
          onChange?.(clone(merged));
      } else {
        setIntervals(newIntervals);
        onChange?.(clone(newIntervals));
      }
      setCursor(null);
    }

    deltaX.current = 0;
    setActive(null);
    setSelectedInterval(null);
  };

  // null for clicking outside
  // single number p for clicking on point
  // * p: quarter position of point
  // [i, d] for clicking on interval
  // * i: interval index
  // * d: 0 for left point, 1 for right point
  const onObjectClick = (object) => {
    if(active !== null) return;

    if(object === null) {
      setCursor(null);
    } else if(typeof object === 'number') {
      setCursor(object);
    } else {
      setCursor(null);
      setSelectedInterval(intervals[object[0]]);
    }
    setActive(object);
  };

  const startRef = useRef(start);
  const moveRef = useRef(move);
  const endRef = useRef(end);
  const onObjectClickRef = useRef(onObjectClick);
  useEffect(() => {
    startRef.current = start;
    moveRef.current = move;
    endRef.current = end;
    onObjectClickRef.current = onObjectClick;
  });

  useEffect(() => {
    const start = (...args) => startRef.current(...args);
    const end = (...args) => endRef.current(...args);
    const move = (...args) => moveRef.current(...args);
    const onObjectClick = (...args) => onObjectClickRef.current(...args);
    
    // relies on timeline only having timeline-segment as children
    const click = (e) => {
      const segments = Array.from(timelineRef.current.children);
      const getSegment = (target) => segments.find(segment => segment.contains(target));

      let segment = getSegment(e.target);
      if(segment) {
        start(segment, e.clientX, e.clientX);
        return;
      }
      segment = getSegment(document.elementFromPoint(e.clientX + QUARTER_WIDTH/2, e.clientY));
      if(segment) {
        start(segment, e.clientX + QUARTER_WIDTH/2, e.clientX);
        return;
      }
      segment = getSegment(document.elementFromPoint(e.clientX - QUARTER_WIDTH/2, e.clientY));
      if(segment) {
        start(segment, e.clientX - QUARTER_WIDTH/2, e.clientX);
        return;
      }
      onObjectClick(null);
    };

    document.addEventListener('pointerdown', click);
    document.addEventListener('pointermove', move);
    document.addEventListener('pointerup', end);
    document.addEventListener('pointercancel', end);
    return () => {
      document.removeEventListener('pointerdown', click);
      document.removeEventListener('pointermove', move);
      document.removeEventListener('pointerup', end);
      document.removeEventListener('pointercancel', end);
    };
  }, [timelineRef]);

  let displayIntervals = [...intervals];

  // remove active interval
  if(Array.isArray(active))
    displayIntervals.splice(active[0], 1);

  if(selectedInterval !== null) {
    const [x1, x2] = selectedInterval;

    displayIntervals = displayIntervals.map(([a, b]) => {
      if(x1 <= a) a = Math.max(x2, a);
      if(x2 >= b) b = Math.min(x1, b);
      return a < b && [a, b];
    }).filter(Boolean);

    displayIntervals.push(selectedInterval);
  }

  const handles = displayIntervals.flatMap(([a, b]) => (
    [[a, 0], [b, 1]]
  ));

  return (
    <div className="timeline no-select" ref={observeTimeline} {...props}>
      {[...Array(24)].map((_, i) => {
        const time = (offset+i)%24;
        const period = time < 12 ? 'AM' : 'PM';
        const x1 = i*4;
        const x2 = x1+4;
        const firstInRow = i % columns === 0;
        const lastInRow = (i+1) % columns === 0 || i === 23;

        return (
          <div 
            key={i} data-index={i}
            className="timeline-segment"
            style={{ width: HOUR_WIDTH }}
          >
            <div className="timeline-horz" />
            {displayIntervals.map(([a, b]) => {
              a = Math.max(x1, a);
              b = Math.min(x2, b);
              if(a >= b) return null;
              const uLeft = a - i*4;
              const uWidth = b - a;
              const left = uLeft*QUARTER_WIDTH;
              const width = uWidth*QUARTER_WIDTH;
              return (
                <div key={uLeft+','+uWidth} className="timeline-interval" style={{ left, width }} />
              );
            })}
            <div className="timeline-vert" />

            {
              handles
              .filter(([handle]) => {
                return handle >= x1 && handle <= x2;
              })
              .filter(([handle, dir], j, handles) => {
                if(i !== 23 && handle === x2 && (!lastInRow || dir === 1))
                  return false;
                if(i !== 0 && handle === x1 && firstInRow && dir === 0)
                  return false;
                if((lastInRow && handle === x2) || (firstInRow && handle === x1))
                  return true;

                const firstIndex = handles.findIndex(([other]) => other === handle);
                const notDupe = j === firstIndex;
                return notDupe;
              })
              .map(([handle]) => {
                const left = (handle - i*4)*QUARTER_WIDTH;

                return <div key={handle} className="timeline-handle" style={{ left }} />
              })
            }
            {
              cursor !== null && 
              cursor > x1 && cursor <= x2 && 
              <div className={`timeline-handle${!lastInRow || cursor < x2 ? ' timeline-handle-ls' : ''}`}
                   style={{ left: (cursor-x1)*QUARTER_WIDTH }} />
            }
            {
              cursor !== null && 
              cursor >= x1 && cursor < x2 && 
              <div className={`timeline-handle${!firstInRow || cursor > x1 ? ' timeline-handle-rs' : ''}`}
                   style={{ left: (cursor-x1)*QUARTER_WIDTH }} />
            }
            <div className="timeline-label">{time%12 || '12'}{period}</div>
          </div>
        );
      })}
    </div>
  );
}

export default memo(Timeline);