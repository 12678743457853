import axios from "axios";
import { obj_set } from "./obj";
import Notifications from "./Notifications";

class ChatNotifications extends Notifications {
  read(path, value) {
    if(!this.user_id || !value) return;
    obj_set(this.notifications, path, (prev) => {
      if(prev && value <= prev) return prev;
      const op = parseInt(path.split('.').pop(), 10);
      if(op === this.user_id) return;
      axios.post(`/notifications/chat-read`, { id: op, timestamp: value.toString() });
      return value;
    });
  }
}

export default ChatNotifications;