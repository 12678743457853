import React, { forwardRef, useEffect, useState } from 'react'

function Input({ defaultValue, ...props }, ref) {
  const [value, setValue] = useState(defaultValue ?? '');

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if((defaultValue ?? null) === null)
      return;
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <input ref={ref} onChange={onChange} value={value} {...props} />
  )
}

export default forwardRef(Input)