import React, { createContext, useRef } from 'react';
import useShowMore from '../hooks/useShowMore';
import useUnsavedChanges from '../hooks/useUnsavedChanges';

export const PostContext = createContext(null);

function PostContextProvider({ children, id, setTitle, mentions }) {
  const mentionsRef = useRef();
  mentionsRef.current = mentions;
  const activeEditors = useRef({ 
    comment: null,
    replies: {},
    editors: {},
  });

  const scrollObserver = useShowMore();

  useUnsavedChanges(() => {
    if(Object.keys(activeEditors.current.editors).length)
      return true;

    const editors = [activeEditors.current.comment, ...Object.values(activeEditors.current.replies)];
    return editors.some((editor) => {
      return editor?.getData();
    });
  });

  return (
    <PostContext.Provider value={{ mentions: mentionsRef, postId: id, scrollObserver, activeEditors, setTitle }}>
      {children}
    </PostContext.Provider>
  );
}

export default PostContextProvider;