import React, { useContext } from 'react';
import { UserContext } from '../../contexts/UserContextProvider';

function PostActions({ reply, Delete, edit, isAuthor, shareLink }) {
  const { user } = useContext(UserContext);
  let onClick;
  if(user)
    onClick = user.perm ? reply : () => { alert('You must sign up to post a reply.') };

  const onShare = () => {
    navigator.clipboard.writeText(window.location.origin + '/posts/view/' + shareLink);
    alert('The shareable link for this post has been copied to your clipboard.');
  };

  return (
    <div className="post-actions">
      {
        isAuthor && 
        <>
          <button className="button-link" onClick={edit}>Edit</button>
          <button className="button-link" onClick={Delete}>Delete</button>
        </>
      }
      <button className="button-link" onClick={onShare}>Share</button>
      <button className="button-link" onClick={onClick}>Reply</button>
    </div>
  )
}

export default PostActions