import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { lastURLSegment } from '../util/url';
import axios from 'axios';
import { globals, socket } from '../App';
import { UserContext } from './UserContextProvider';
import ChatNotifications from '../util/ChatNotifications';
import { useSearchParams } from 'react-router-dom';

export const ChatContext = createContext(null);

function ChatContextProvider({ children }) {
  const { user } = useContext(UserContext);
  const notifications = useMemo(() => new ChatNotifications(user), [user]);

  const { pathname } = useLocation();
  const ghostUsername = lastURLSegment(pathname);
  const hasGhost = /^\/chat\/\w+$/.test(pathname);
  const [ghost, setGhost] = useState(null);
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(searchParams.get('r') ? 'request' : 'message');
  const [contact, setContact] = useState(null);
  const [lastRead, setLastRead] = useState(null);
  const productImpId = searchParams.get('p');

  useEffect(() => {
    socket.emit('chat join');
    return () => {
      socket.emit('chat leave');
    };
  }, []);

  useEffect(() => {
    if(!hasGhost) return;
    axios.get(`/chat/get-ghost/${ghostUsername}`)
    .then(({ data }) => {
      setGhost(data);
    });
  }, [pathname, ghostUsername, hasGhost])

  useEffect(() => {
    if(!productImpId) return;
    axios.post('/notifications/id-read', { name: 'important', _id: productImpId })
    .then(() => {
      globals._setAlertsImp?.(important => important.map(imp => {
        if(imp._id === productImpId)
          return { ...imp, read: true };
        return imp;
      }));
    });
  }, [productImpId]);

  const changeContact = useCallback((contact) => {
    setContact(contact);
    socket.emit('chat convo', contact?.id ?? 0);
    if(contact) {
      setLastRead(notifications.get(`chat_messages.${contact.id}`));
      notifications.read(`chat_messages.${contact.id}`, contact.last_msg);
      
      const group = `user:${contact.id}/msg`;
      axios.post('/notifications/group-read', { group })
      .then(() => {
        // really weird hack [#1]
        globals._setAlerts?.(mailbox => mailbox.map(mail => {
          if(mail.group === group)
            return { ...mail, read: true };
          return mail;
        }));
      });
    }
  }, [notifications]);

  const reconnectRef = useRef();
  reconnectRef.current = () => {
    socket.emit('chat convo', contact?.id ?? 0);
  };

  useEffect(() => {
    const reconnect = () => reconnectRef.current();
    socket.io.on('reconnect', reconnect);
    return () => {
      socket.io.off('reconnect', reconnect);
    };
  }, []);

  return (
    <ChatContext.Provider value={{ 
      page, setPage, lastRead, contact, setContact: changeContact, 
      notifications, hasGhost, ghost, setGhost, searchParams,
    }}>
      {children}
    </ChatContext.Provider>
  );
}

export default ChatContextProvider;