
const STD_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const matchSameType = (t1, t2) => {
  return Object.entries(t1).reduce((tot, [day, hours]) => {
    if(!t2[day]) return tot;
    return tot + t2[day].filter((b, i) => b && hours[i]).length;
  }, 0);
};

const matchDateAndWeek = (d, w) => {
  return Object.entries(d).reduce((tot, [date, hours]) => {
    const day = STD_DAYS[new Date(date).getDay()];
    if(!w[day]) return tot;
    return tot + w[day].filter((b, i) => b && hours[i]).length;
  }, 0);
};

export const getIntersection = (w1, t1, w2, t2) => {
  if(w1 === w2) return matchSameType(t1, t2);
  if(w1 === 'date') return matchDateAndWeek(t1, t2);
  return matchDateAndWeek(t2, t1);
};
