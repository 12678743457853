import { Link, useNavigate, useParams } from 'react-router-dom'
import './ViewProduct.css'
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios';
import Error404 from '../../Error404/Error404';
import useTitle from '../../../hooks/useTitle';
import TextareaAutoresize from 'react-textarea-autosize';
import pfp_path from '../../../util/pfp_path';
import Button from '../../Button/Button';
import transformPost from '../../GenericPost/transformPost';
import { useMediaQuery } from 'react-responsive';
import ShowMore from '../../ShowMore/ShowMore';
import useShowMore from '../../../hooks/useShowMore';
import useObserver from '../../../hooks/useObserver';
import { UserContext } from '../../../contexts/UserContextProvider';
import useUnsavedChanges from '../../../hooks/useUnsavedChanges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDisplayPrice } from './productInfo';
import { globals } from '../../../App';
import useDescription from '../../../hooks/useDescription';

function ViewProduct() {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const isPreview = id === 'preview';
  const [product, setProduct] = useState();
  const setTitle = useTitle();
  const setDescription = useDescription();
  const sendMessageRef = useRef();
  const body = useMemo(() => product && transformPost(product.body), [product]);
  const mid = useMediaQuery({ query: '(max-width: 1300px)' });
  const sm = useMediaQuery({ query: '(max-width: 820px)' });
  const navigate = useNavigate();

  const showMore = useShowMore();
  const [scrollObserver] = useObserver(showMore);
  const listRef = useRef();
  const tilesRef = useRef();
  const [tile, setTile] = useState(0);

  const onArrowLeft = () => {
    setTile((tile - 1 + product.images.length) % product.images.length);
  };

  const onArrowRight = () => {
    setTile((tile + 1) % product.images.length);
  };

  useLayoutEffect(() => {
    if(!tilesRef.current) return;
    if(sm) {
      // horizontal
      tilesRef.current?.scrollTo({
        behavior: 'smooth',
        left: tilesRef.current.children[tile].offsetLeft - tilesRef.current.offsetLeft,
      });
    } else {
      // vertical
      tilesRef.current?.scrollTo({
        behavior: 'smooth',
        top: tilesRef.current.children[tile].offsetTop - tilesRef.current.offsetTop,
      });
    }
  }, [tile, sm]);

  const toggleListing = () => {
    if(product.listed && !window.confirm([
      "Are you sure you want to unlist this product?",
      "You won't be able to receive any requests while unlisted.",
    ].join('\n'))) {
      return;
    }

    listRef.current.setPending(true);
    const listed = product.listed ? 0 : 1;
    axios.post('/list-product', { listed, id })
    .then(() => {
      setProduct({ ...product, listed });
      if(globals.products?.data) {
        const curId = parseInt(id, 10);
        globals.products.data.rows = globals.products.data.rows.map(row => {
          if(row.id === curId) return { ...row, listed };
          return row;
        });
      }
    })
    .catch(() => {
      alert('Something went wrong.');
    })
    .finally(() => {
      listRef.current.setPending(false);
    });
  };

  useEffect(() => {
    if(isPreview) return;
    axios.get('/products/view/'+id)
    .then(({ data }) => {
      setProduct(data);
      setTitle(data.title);
      setDescription(data.description);
    })
    .catch((error) => {
      if(error.response.status === 404)
        setProduct(null);
    });
  }, [isPreview, id, setTitle, setDescription]);

  useEffect(() => {
    if(!isPreview || !user) return;
    if(!user.perm) {
      setProduct(null);
      return;
    }

    let data = sessionStorage.getItem('sell_preview');
    if(!data) {
      setProduct(null);
    } else {
      data = JSON.parse(data);
      
      data.is_seller = false;
      data.user_id = user.id;
      data.seller_username = user.username;
      data.seller_pfp = user.pfp;

      data.transport = ['shipping', 'inPerson'].filter(s => data[s]);
      data.price = parseInt(data.price, 10);
      data.address = data.address.trim();
      data.phone = data.phone || null;
      if(data.priceType !== 'fixed') data.price = null;
      if(data.cond === 'unspecified') data.cond = null;
      if(data.adType === 'looking') {
        data.cond = data.priceType = data.price = null;
      }

      setProduct(data);
      setTitle(data.title);
    }
  }, [isPreview, user, setTitle]);

  useUnsavedChanges((dest) => {
    if(isPreview) {
      const data = sessionStorage.getItem('sell_preview');
      if(data) {
        const { dirty, id } = JSON.parse(data);
        return dirty && id && !new RegExp(`^/sell/${id}/?$`).test(dest);
      }
    }
  });

  const onEdit = () => {
    sessionStorage.removeItem('sell_preview');
    navigate('/sell/'+id);
  };

  const contactSeller = (e) => {
    e.preventDefault();
    const message = e.target.elements.message.value.trim();
    if(message.length < 5) {
      alert('Message is too short.');
      return;
    }

    sendMessageRef.current.setPending(true);
    axios.post('/chat/pmsg', { product_id: id, content: message })
    .then(({ data }) => {
      navigate(`/chat/${data.user_id}`);
    })
    .catch((error) => {
      if(error.response.status === 500)
        alert('Something went wrong.')
      else
        alert(error.response.data);
    })
    .finally(() => {
      sendMessageRef.current.setPending(false);
    });
  }

  if(product === null) return <Error404 />;
  if(!product) return null;

  const condDisplay = {
    new: 'New',
    likeNew: 'Used - Like New',
    good: 'Used - Good',
    fair: 'Used - Fair',
  };

  const displayPrice = getDisplayPrice(product.priceType, product.price);
  const displayPhone = product.phone?.match(/(\d{3})(\d{3})(\d{4})/).slice(1).join('-');
  const displayCond = condDisplay[product.cond];
  let displayTransport = 'Shipping & In-person available';
  if(!product.transport.includes('shipping'))
    displayTransport = 'Available In-person';
  else if(!product.transport.includes('inPerson'))
    displayTransport = 'Available with Shipping';


  const productInfo = (
    <div className="view-product-info">
      {
        product.requested ?
        <div className="view-product-ibox">
          <button className="wbtn" onClick={() => navigate(`/chat/${product.user_id}`)}>Chat with seller</button>
        </div> :
        <form className="view-product-ibox" onSubmit={contactSeller}>
          <h2>Contact Seller</h2>
          <TextareaAutoresize name="message" style={{ padding: '10px' }} placeholder="Write a message" minRows={8} maxLength={5000} /> 
          <Button disabled={isPreview} ref={sendMessageRef} type="submit" className="wbtn" style={{ marginTop: '10px' }}>
            Send Message
          </Button>
        </form>
      }
      <div className="view-product-ibox" style={{ height: '100%' }}>
        <h2>Seller Profile</h2>
        <div className="post-pfp">
          <div>
            <img width="64" height="64" alt="" src={pfp_path(product.user_id, product.seller_pfp)} />
          </div>
          <div className="post-pfp-text">
            <Link to={`/user/${product.seller_username}`} className="user-link">{product.seller_username}</Link>
          </div>
        </div>
        {product.phone && 
          <div className="view-product-phone">
            <FontAwesomeIcon icon="fa-solid fa-phone" />
            &nbsp;{displayPhone}
          </div>
        }
      </div>
    </div>
  );

  return (
    <div className="view-product page">
      <div className="view-product-area">

        {product.is_seller && 
          <div className="view-job-box view-job-actions" style={{ marginBottom: '30px', width: '100%' }}>
            <button className="wbtn" onClick={onEdit}>Edit</button>
            <Button ref={listRef} className="wbtn wbtn-outline" onClick={toggleListing}>{product.listed ? 'Unlist' : 'Relist'}</Button>
          </div>
        }
        {isPreview &&
          <div className="view-job-box view-job-actions" style={{ marginBottom: '30px', width: '100%' }}>
            <button className="wbtn" onClick={() => navigate(-1)}>Go back</button>
          </div>
        }

        <h1 style={{ marginBottom: '10px', overflowWrap: 'anywhere' }}>{product.title}</h1>
        <div className="view-product-price" style={{ marginBottom: '30px' }}>
          {displayPrice}
        </div>
        
        <div className="view-product-root">
          <div className="view-product-body">
            <div className="view-product-gallery">
              <div className="view-product-image">
                <div className="view-product-image-1">
                  {product.images.length > 1 && <>
                    <div className="view-product-arrow view-product-left" onClick={onArrowLeft}>
                      <FontAwesomeIcon icon="fa-solid fa-circle-arrow-left" />
                    </div>
                    <div className="view-product-arrow view-product-right" onClick={onArrowRight}>
                      <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                    </div>
                  </>}
                  <div className="view-product-image-2">
                    <img src={`/users/${product.user_id}/products/${product.images[tile]}`} alt={product.title} />
                  </div>
                </div>
              </div>
              <div className="view-product-tiles" ref={tilesRef}>
                {product.images.map((image, i) => (
                  <div key={image} onClick={() => setTile(i)} className={`view-product-tile ${i === tile && 'view-product-selected'}`}>
                    <img src={`/users/${product.user_id}/products/${image}`} alt="" />
                  </div>
                ))}
              </div>
            </div>
            <div className="view-product-meta">
              <div><FontAwesomeIcon icon="fa-solid fa-check" /> {displayTransport}</div>
              <div><FontAwesomeIcon icon="fa-solid fa-location-dot" /> {product.address}</div>
              {product.cond && 
                <div><FontAwesomeIcon icon="fa-solid fa-star" /> Condition: <strong>{displayCond}</strong></div>
              }
            </div>
          </div>
          {!mid && productInfo}
        </div>
        <div className="form-segment" style={{ marginTop: '20px', width: '100%', boxSizing: 'border-box' }}>
          <h2 style={{ margin: 0 }}>Product Description</h2>
          <ShowMore ref={scrollObserver} scroll={800}>
            <div className="ck-content preview" dir="ltr" dangerouslySetInnerHTML={{ __html: body }} />
          </ShowMore>
        </div>
        {mid && productInfo}
      </div>
    </div>
  )
}

export default ViewProduct
