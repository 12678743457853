import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackToTop from '../BackToTop/BackToTop';
import './BrowseUsers.css';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading/Loading';
import pfp_path from '../../util/pfp_path';

function BrowseUsers() {
  const { search } = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loaded, setLoaded] = useState();

  const onSearch = (e) => {
    e.preventDefault();
    const query = e.target.elements['search-query'].value.trim();
    if(query)
      navigate('/browse-users/' + encodeURIComponent(query));
    else
      navigate('/browse-users');
  }; 

  useEffect(() => {
    if(!search) {
      axios.get(`/get-users`)
      .then(({ data }) => {
        setUsers(data);
        setLoaded(search);
      });
      return;
    }
    axios.get(`/get-users/${encodeURIComponent(search)}`)
    .then(({ data }) => {
      setUsers(data);
      setLoaded(search);
    });
  }, [search]);

  if(loaded !== search) return <Loading style={{ marginTop: '50px' }} />

  return (
    <div className="posts page">
      <BackToTop />
      <div className="view-post-area">
        <form className="posts-search" onSubmit={onSearch}>
          <input type="text" name="search-query" placeholder="Search users..." defaultValue={search} maxLength="64" autoFocus />
          <button type="submit"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></button>
        </form>
        <div>
          <p style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
            {search && !users.length && 'No results found.'}
          </p>
        </div>
        <div className="user-box-c">
          {users.map(user => {
            return (
              <Link key={user.id} to={`/user/${user.username}`} className="fake-link">
                <div className="user-box">
                  <div className="user-box-p">
                    <img src={pfp_path(user.id, user.pfp)} width="128" height="128" alt="" />
                  </div>
                  <div className="user-box-t">
                    <span className="user-link">{user.username}</span>
                  </div>
                  <div style={{ textAlign: 'center', color: '#00aadd', marginTop: 5 }}>
                    <FontAwesomeIcon icon="fa-solid fa-user" /> Connect
                  </div>
                  <div className="user-box-d">
                    {user.jobTitle}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default BrowseUsers