import './ChatInput.css';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import TextareaAutoresize from 'react-textarea-autosize';
import { ChatContext } from '../../../contexts/ChatContextProvider';
import { globals } from '../../../App';

const MAX_ROWS = 10;

function ChatInput({ sendMessage }, ref) {
  globals.chat_autosave = globals.chat_autosave || {};
  const { contact } = useContext(ChatContext);

  const [value, setValue] = useState('');
  const msgRef = useRef()

  const changeValue = (value) => {
    globals.chat_autosave[contact.id] = value;
    setValue(value);
  };

  useImperativeHandle(ref, () => {
    return {
      value, setValue: changeValue,
    }
  });

  const shiftNewline = (e) => {
    if(e.code === 'Enter' && !e.shiftKey) {
      sendMessage(e);
    }
  };

  const onHeightChange = (height, { rowHeight }) => {
    if(rowHeight > MAX_ROWS) {
      msgRef.current.style.overflow = 'auto';
    } else {
      msgRef.current.style.overflow = '';
    }
  };

  useEffect(() => {
    setValue(globals.chat_autosave[contact.id] ?? '');
    msgRef.current.focus();
  }, [contact]);

  return (
    <div className="chat-input">
      <TextareaAutoresize maxLength="4096" onChange={(e) => changeValue(e.target.value)} value={value} onKeyDown={shiftNewline} ref={msgRef} maxRows={MAX_ROWS} onHeightChange={onHeightChange}></TextareaAutoresize>
    </div>
  )
}

export default forwardRef(ChatInput)