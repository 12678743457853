import Tab from './Tab/Tab';
import './Tabs.css';
import React, { useState } from 'react';

function Tabs({ children, selected: active, defaultSelected, onChange, blocker, ...props }) {
  children = React.Children.toArray(children);

  const [selected, setSelected] = useState(defaultSelected);
  const realSelected = active === undefined ? selected : active;

  const changeTab = (tab) => {
    if(tab === realSelected || blocker?.current) return;
    setSelected(tab);
    onChange?.(tab);
  };

  return (
    <div className="tabs no-select" {...props}>
      <ul className="tab-list">
        {children.map(({ props: { children, ...props } }) => (
          <Tab key={children} selected={realSelected === children} onClick={() => changeTab(children)} {...props}>
            {children}
          </Tab>
        ))}
      </ul>
    </div>
  );
}

export default Tabs;
