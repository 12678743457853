import Autocomplete from "react-google-autocomplete";
import Redirect from '../../Redirect/Redirect';
import './Sell.css';
import React, { useContext, useEffect, useRef, useState } from 'react'
import CustomEditor from "../../CustomEditor/CustomEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SUPPORTED_EXTENSIONS, getext } from "../../../util/extensions";
import Button from "../../Button/Button";
import axios from "axios";
import { UserContext } from "../../../contexts/UserContextProvider";
import SellImage from "./SellImage/SellImage";
import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, rectSortingStrategy } from "@dnd-kit/sortable";
import SellPrice from "./SellPrice/SellPrice";
import SellPhone from "./SellPhone/SellPhone";
import { useNavigate, useParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import useUnsavedChanges from "../../../hooks/useUnsavedChanges";
import { globals } from "../../../App";

const MAX_FILES = 10;
const MAX_FILE_MB = 15;

const PREVIEW_LINK = '/products/view/preview';

function Sell() {
  const { user } = useContext(UserContext);
  const { id } = useParams();

  const editorRef = useRef();
  const uploadImagesRef = useRef();
  const submitRef = useRef();
  const priceRef = useRef();
  const phoneRef = useRef();
  const formRef = useRef();
  const placeRef = useRef('');

  const created = useRef(false);
  const originalLoaded = useRef(false);
  const dirty = useRef(false);
  const previewing = useRef(false);

  const [adType, setAdType] = useState('selling');
  const [priceType, setPriceType] = useState('fixed');
  const [images, setImages] = useState([]);
  const [bodyData, setBodyData] = useState('');

  const [ready, setReady] = useState(false);
  const [errors, setErrors] = useState({});

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 10 } }),
  );

  const navigate = useNavigate();
  
  useEffect(() => {
    delete globals.products;
  }, []);

  useEffect(() => {
    if(!id) return;
    let data = sessionStorage.getItem('sell_preview');
    if(data) data = JSON.parse(data);

    if(data?.id !== id) {
      axios.get('/product/info/'+id)
      .then(({ data }) => {
        loadData(data);
        setTimeout(() => {
          originalLoaded.current = true;
        }, 1000);
      });
    } else {
      loadData(data);
      setTimeout(() => {
        originalLoaded.current = true;
      }, 1000);
    }
  }, [id]);

  useEffect(() => {
    if(!ready) return;
    setTimeout(() => {
      editorRef.current.setData(bodyData);
    }, 0);
  }, [ready, bodyData]);

  useUnsavedChanges(() => {
    return id && dirty.current && !created.current && !previewing.current;
  });

  const adTypeChange = (e) => {
    setAdType(e.target.value);
  };

  const priceTypeChange = (e) => {
    setPriceType(e.target.value);
  };

  const uploadImages = (e) => {
    const files = Array.from(e.target.files);
    if(!files.length) return;

    if(images.length + files.length > MAX_FILES) {
      if(files.length <= MAX_FILES) {
        alert(`Error. You can only upload up to ${MAX_FILES} images total for the "Product Media" section. You may remove ${images.length+files.length-MAX_FILES} image(s) currently in use to make room for the ${files.length} additional image(s) you tried to upload.`);
      } else {
        alert(`Error. You can only upload up to ${MAX_FILES} images total for the "Product Media" section. You tried to upload ${files.length}.`);
      }
      e.target.value = null;
      return;
    }
    const badFilesExt = files.filter(file => !SUPPORTED_EXTENSIONS.includes(getext(file.name)));
    if(badFilesExt.length) {
      alert(`Error. The following files are using unsupported extensions:\n` + badFilesExt.map(file => file.name).join(', '));
      e.target.value = null;
      return;
    }
    const badFilesSize = files.filter(file => file.size > MAX_FILE_MB*1024*1024);
    if(badFilesSize.length) {
      alert(`Error. The following files are too large (the maximum size for each file is ${MAX_FILE_MB}MB):\n` + badFilesSize.map(file => file.name).join(', '));
      e.target.value = null;
      return;
    }

    uploadImagesRef.current.setPending(true);
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    axios.post('/sell/img', formData)
    .then(({ data }) => {
      setImages(images => [...images, ...data]);
    })
    .catch((error) => {
      if(error.response.status === 500)
        alert('Something went wrong.');
      else if(error.response.status === 429)
        alert('You have reached your daily upload limit.');
      else
        alert(error.response.data);
    })
    .finally(() => {
      e.target.value = null;
      uploadImagesRef.current.setPending(false);
    });
  };

  // prevent sending multiple POST requests in case the user multi-clicks delete accidentally
  const delCache = useRef({});

  const deleteImage = (name) => {
    if(!delCache[name]) {
      delCache[name] = 1;
      axios.post('/sell/del-img', { name })
      .finally(() => {
        delete delCache[name];
      });
    }
    setImages(images => images.filter(image => image !== name));
    onFormChange();
  };

  const loadData = (data) => {
    data.priceType = data.priceType || 'fixed';
    for(let [key, val] of Object.entries(data)) {
      let inputs = formRef.current.elements[key];
      if(!inputs) continue;
      if(!(inputs instanceof NodeList)) inputs = [inputs];
      inputs.forEach(input => {
        switch(input.type) {
          case 'checkbox':
            input.checked = !!val;
            break;
          case 'radio':
            input.checked = input.value === val;
            break;
          default:
            input.value = val;
            break;
        }
      });
    }
    dirty.current = data.dirty;
    placeRef.current = data.placeRef ?? data.address;
    priceRef.current.setValue(data.price);
    phoneRef.current.setValue(data.phone);
    setAdType(data.adType);
    setBodyData(data.body);
    setImages(data.images);
    setPriceType(data.priceType);
  };

  const getData = () => {
    const data = Object.fromEntries(new FormData(formRef.current).entries());
    if(id) data.id = id;
    data.body = editorRef.current.getData();
    data.dirty = dirty.current;
    data.placeRef = placeRef.current;
    data.price = priceRef.current.value;
    data.phone = phoneRef.current.value;
    data.images = data.images ? data.images.split(',') : [];
    return data;
  };

  const preview = () => {
    const data = getData();
    if(!validateAll(data)) return;

    previewing.current = true;
    sessionStorage.setItem('sell_preview', JSON.stringify(data));
    navigate(PREVIEW_LINK);
  };

  const clear = (e) => {
    if(!window.confirm("Are you sure you want to clear the contents of your entire product? This will delete all of your current progress.")) {
      e.preventDefault();
      return;
    }
    if(!id) localStorage.removeItem('sell');
    priceRef.current.setValue('');
    phoneRef.current.setValue('');
    setAdType('selling');
    setBodyData('');
    setImages([]);
    setPriceType('fixed');
    setErrors({});
    window.scrollTo(0, 0);
  };

  const [autosave, flush] = useDebounce(() => {
    if(!editorRef.current || created.current || id) return;

    localStorage.setItem('sell', JSON.stringify(getData()));
  }, 1000, { flushOnUnmount: false, stall: 3000 });

  const onReady = (editor) => {
    setReady(true);
    if(id) return;

    editor.on('destroy', () => {
      flush();
    });

    const saved = localStorage.getItem('sell');
    if(!saved) return;
    loadData(JSON.parse(saved));
  };

  const onFormChange = () => {
    if(originalLoaded.current) dirty.current = true;
    autosave();
  };

  const validateAll = (data) => {
    const errors = {};
    const addError = (path, err) => {
      const [group, name] = path.split('/');
      errors[group] ??= [];
      errors[group].push({ name, value: err });
    };

    const validate = (path, fn) => {
      const [, name] = path.split('/');
      const err = fn(data[name]);
      if(typeof err === 'string')
        addError(path, err);
    };
    
    if(!data.shipping && !data.inPerson)
      addError('basic/transport');

    validate('basic/address', (address) => {
      if(!address.trim())
        return 'Enter your address.';
      if(address.trim() !== placeRef.current)
        return 'Select an address from the list of suggestions that appears when you start typing.'; 
    });

    validate('details/price', (price) => {
      if(data.adType !== 'selling' || data.priceType !== 'fixed') return;

      if(!price)               return 'Enter a price.';
      if(!parseInt(price, 10)) return 'Price cannot be zero.';
      if(/^0/.test(price))     return 'Price cannot contain leading zeroes.';
    });

    validate('details/title', (title) => {
      if(!title.trim())
        return 'Enter a title.';
      if(title.trim().replace(/\s+/g, ' ').length < 3)
        return 'Title is too short.';
    });

    validate('details/body', (body) => {
      if(!body)
        return 'Enter a description.';
    });

    validate('media/images', (images) => {
      if(!images.length)
        return 'You must upload at least one image of your product.';
    });

    validate('contact/phone', (phone) => {
      if(!phone) return;
      if(phone.length !== 10) return 'Enter a valid phone number.';
    });

    if(Object.keys(errors).length) {
      addError('general/', 'There were some errors down below.');
      setErrors(errors);
      window.scrollTo(0, 0);
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = getData();

    if(!validateAll(data)) return;

    submitRef.current.setPending(true);
    axios.post('/product/create' + (id ? `/${id}` : ''), data)
    .then(({ data }) => {
      created.current = true;
      if(!id) localStorage.removeItem('sell');
      navigate('/products/view/'+data.id, { replace: !id });
      setErrors({});
    })
    .catch((error) => {
      if(error.response.status === 429) {
        const rl_error = id ? 
                        'You have reached your daily product editing limit.' :
                        'You can only post up to 2 products for free during this limited-time promotion.';
        alert(rl_error);
        setErrors({});
        return;
      }
      const msg = error.response.status === 413 ? 
                  'Your ad contains too much data.' :
                  error.response.data || 'Something went wrong.';
                  
      setErrors({
        general: [
          {
            value: msg,
          }
        ]
      });
      window.scrollTo(0, 0);
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  const wrapErr = (err) => (
    err &&
    <div className="form-error sell-error">
      {err}
    </div>
  );

  const errc = Object.fromEntries(
    Object.entries(errors)
    .map(([name, group]) => {
      const withContent = group.filter(err => err.value);
      if(!withContent.length) return [name, null];
      return [
        name,
        wrapErr(
          <ul>
            {withContent.map(err => <li key={err.name ?? 'general'}>{err.value}</li>)}
          </ul>
        )
      ];
    })
  );
  errc.general = wrapErr(errors.general?.[0].value);

  const errh = Object.fromEntries(
    Object.values(errors)
    .flatMap(group => 
      group
      .filter(({ name }) => name)
      .map(({ name }) => [name, true])
    )
  );

  const selling = adType === 'selling';

  return (
    <div className="sell page">
      <Redirect to="/register" unless="user" />
      <div className="sell-view">
        <h1>Post Product</h1>
        {errc.general}
        <form ref={(elem) => formRef.current = elem ?? formRef.current} onSubmit={onSubmit} onChange={onFormChange}>
          <div className="form-segment">
            <h2>Buying or Selling?</h2>
            <div className="form-field">
              <label className="b-contain">
                <span>I am selling this product</span>
                <input type="radio" name="adType" value="selling" onChange={adTypeChange} checked={selling} />
                <div className="b-input"></div>
              </label>
              <label className="b-contain">
                <span>I am looking for this product</span>
                <input type="radio" name="adType" value="looking" onChange={adTypeChange} checked={!selling} />
                <div className="b-input"></div>
              </label>
            </div>
          </div>
          <div className="form-segment">
            <h2>Basic Information</h2>
            {errc.basic}
            <div className="sell-label" data-err={errh.transport}>
              {selling ? 'How will the buyer receive this item?' : 'How are you willing to receive this item?'}
              &nbsp;(select at least one option*)</div>
            <div className="form-field">
              <label className="b-contain">
                <span style={{ fontSize: '18px' }}>Shipping</span>
                <input type="checkbox" name="shipping" />
                <div className="b-input"></div>
              </label>
            </div>
            <div className="form-field">
              <label className="b-contain">
                <span style={{ fontSize: '18px' }}>In person</span>
                <input type="checkbox" name="inPerson" />
                <div className="b-input"></div>
              </label>
            </div>
            <div className="form-field">
              <label htmlFor="sell-address" style={{ marginRight: '15px' }} data-err={errh.address}>What is your address? (*)</label>
              <Autocomplete onFocus={(e) => e.target.autocomplete = 'new-password'} onPlaceSelected={(_, input) => placeRef.current = input.value} placeholder="Enter and select address" style={{ maxWidth: '600px' }} maxLength="256" id="sell-address" name="address" apiKey={process.env.REACT_APP_GOOGLE_API} options={{ types: ['address'] }} />
            </div>
          </div>
          <div className="form-segment">
            <h2>Product Details</h2>
            {errc.details}
            <div className="form-field" style={{ display: selling ? 'flex' : 'none', gap: '15px', flexWrap: 'wrap', alignItems: 'center' }}>
              <label htmlFor="sell-cond">What condition is the product in?</label>
              <select id="sell-cond" name="cond">
                <option value="unspecified">- Select (optional) -</option>
                <option value="new">New</option>
                <option value="likeNew">Used - Like New</option>
                <option value="good">Used - Good</option>
                <option value="fair">Used - Fair</option>
              </select>
            </div>
            <div className="form-field" style={{ display: selling ? 'block' : 'none' }}>
              <div className="sell-label" data-err={errh.price}>Product Price*</div>
              <div style={{ display: 'flex', alignItems: 'end', marginBottom: '10px' }}>
                <label className="b-contain" style={{ margin: '0' }}>
                  <span>&#8203;</span>
                  <input type="radio" name="priceType" value="fixed" checked={priceType === 'fixed'} onChange={priceTypeChange} />
                  <div className="b-input"></div>
                </label>
                <div>
                  $&nbsp;
                  <SellPrice ref={(elem) => priceRef.current = elem ?? priceRef.current} disabled={priceType !== 'fixed'} />
                </div>
              </div>
              <label className="b-contain">
                <span>Please Contact</span>
                <input type="radio" name="priceType" value="contact" checked={priceType === 'contact'} onChange={priceTypeChange} />
                <div className="b-input"></div>
              </label>
              <label className="b-contain">
                <span>Swap/Trade</span>
                <input type="radio" name="priceType" value="trade" checked={priceType === 'trade'} onChange={priceTypeChange} />
                <div className="b-input"></div>
              </label>
            </div>
            <div className="form-field">
              <label htmlFor="sell-title" data-err={errh.title}>Product Title*</label>
              <input id="sell-title" name="title" maxLength="200" placeholder="Important for search results" />
            </div>
            <div className="form-field">
              <div className="sell-label">
                <div data-err={errh.body}>Product Description*</div>
                <div className="alert" style={{ marginTop: '5px' }}>
                  <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />
                  Any images you add here will only appear in the description section of your product.
                  You should add your main product images in the "Product Media" section down below.
                </div>
              </div>
              <CustomEditor ref={editorRef} onReady={onReady} uploadUrl="/upload/s" onChange={onFormChange} />
            </div>
          </div>
          <div className="form-segment">
            <h2>Product Media</h2>
            {errc.media}
            <p>Here you will add the images presenting your product. The first image is very important as it is the one people can see before clicking on your product. You can drag and drop to reorder the images once you have uploaded them.</p>
            <input type="hidden" name="images" value={images.join()} />
            <input type="file" onChange={uploadImages} multiple hidden accept={SUPPORTED_EXTENSIONS.join()} />
            <Button ref={uploadImagesRef} type="button" className="wbtn" onClick={e => e.target.previousSibling.click()}>Upload Images</Button>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={images} strategy={rectSortingStrategy}>
                <div className="sell-images" style={{ margin: images.length ? '40px 0' : '0' }}>
                  {user?.perm && images.map((image, i) => {
                    const isThumbnail = i === 0;

                    return (
                      <SellImage isThumbnail={isThumbnail} key={image} name={image} src={`/users/${user.id}/products/${image}`} onDelete={deleteImage} />
                    )
                  })}
                </div>
              </SortableContext>
            </DndContext>
          </div>
          <div className="form-segment">
            <h2>Contact Information</h2>
            {errc.contact}
            <div className="form-field sell-collapse">
              <label data-err={errh.phone} htmlFor="sell-phone" style={{ marginRight: '15px', marginTop: '10px', width: '210px' }}>Phone Number (optional)</label>
              <div>
                <SellPhone  ref={(elem) => phoneRef.current = elem ?? phoneRef.current} />
                <p style={{ marginTop: '10px', marginBottom: '0' }}>Your phone number will show up on your Ad.</p>
              </div>
            </div>
            <div className="form-field sell-collapse">
              <label htmlFor="sell-email" style={{ marginRight: '15px', marginTop: '10px', width: '210px' }}>Email</label>
              <div>
                <input disabled id="sell-email" value={user?.email ?? ''} />
                <p style={{ margin: '10px 0' }}>Your email will not be shared with others, but you will be notified about message requests.</p>
              </div>
            </div>
          </div>
          <div className="form-segment">
            <h2>Submission</h2>
            <div className="alert" style={{ marginBottom: '15px' }}>
              <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />
              Posting a product is currently free as part of the limited-time promotion.
            </div>
            <div className="sell-submit">
              <button type="reset" className="wbtn wbtn-outline" onClick={clear}>Clear</button>
              <button type="button" className="wbtn" onClick={preview}>Preview</button>
              <Button type="submit" className="wbtn" ref={submitRef}>{id ? 'Save' : 'Post'}</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if(over && active.id !== over.id) {
      setImages(images => {
        const oldIndex = images.indexOf(active.id);
        const newIndex = images.indexOf(over.id);

        return arrayMove(images, oldIndex, newIndex);
      });
      onFormChange();
    }
  }
}

export default Sell
