import React, { useContext } from 'react'
import Square from '../../../SquareSelect/Square/Square'
import SquareSelect from '../../../SquareSelect/SquareSelect'
import { WeekContext } from '../../contexts/WeekContextProvider'
import SelectHours from '../SelectHours/SelectHours'
import './WeekScheduler.css'
import AllowNotifications from '../AllowNotifications/AllowNotifications'

const ZEROFILL_TIME = Array(96).fill(0);
const labels = {
  search: {
    days: 'Select your available days below.',
    desc: `
    Select your available hours for each day down below.
    Not specifying any hours for a day means that you are available that entire day.
    `,
  },
  job: {
    days: 'Select your available shift days below.',
    desc: `
    Select your available work hours for each day down below.
    `,
  }
};

function WeekScheduler({ mode }) {
  const c = labels[mode];
  const { days, setDays, setTimelineData } = useContext(WeekContext);

  const daysChange = (days) => {
    setDays(days);
    setTimelineData(intervals => ({
      ...Object.fromEntries(days.map(day => [day, ZEROFILL_TIME])),
      ...intervals,
    }));
  };

  return (<>
    <div className="form-field">
      <div className="shifts-label">{c.days}</div>
      <SquareSelect defaultSelected={days} onChange={daysChange}>
        <Square>Monday</Square>
        <Square>Tuesday</Square>
        <Square>Wednesday</Square>
        <Square>Thursday</Square>
        <Square>Friday</Square>
        <Square>Saturday</Square>
        <Square>Sunday</Square>
        <Square selectAll>All</Square>
      </SquareSelect>
    </div>
    <SelectHours context={WeekContext} desc={c.desc} />
    {days.length > 0 && mode === 'search' && <AllowNotifications context={WeekContext} />}
  </>)
}

export default WeekScheduler