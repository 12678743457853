import { useLocation, useNavigate } from 'react-router'
import './ViewUser.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { lastURLSegment } from '../../util/url';
import axios from 'axios';
import pfp_path from '../../util/pfp_path';
import useShowMore from '../../hooks/useShowMore';
import useObserver from '../../hooks/useObserver';
import ShowMore from '../ShowMore/ShowMore';
import Error404 from '../Error404/Error404';
import ActivityPost from '../ActivityPost/ActivityPost';
import BackToTop from '../BackToTop/BackToTop';
import Button from '../Button/Button';
import ActivityProduct from '../ActivityProduct/ActivityProduct';
import useTitle from '../../hooks/useTitle';
import useDescription from '../../hooks/useDescription';
import { UserContext } from '../../contexts/UserContextProvider';

function ViewUser() {
  const { user: me } = useContext(UserContext);
  const { pathname } = useLocation();
  const username = lastURLSegment(pathname);
  const [user, setUser] = useState();
  const showMore = useShowMore();
  const [bioObserver] = useObserver(showMore);
  const [activity, setActivity] = useState(null);
  const [other, setOther] = useState({});
  const [end, setEnd] = useState(false);
  const loadMoreRef = useRef();
  const navigate = useNavigate();
  const setTitle = useTitle();
  const setDescription = useDescription();

  useEffect(() => {
    axios.get(`/user/info/${username}`)
    .then(({ data }) => {
      setUser(data);
      setTitle(`${data.username} | ECP Vision`);
      if(data.bio) {
        setDescription(data.bio);
      }
    })
    .catch((error) => {
      if(error.response.status === 404) {
        setUser(null);
      }
    });

    axios.get(`/user/activity/${username}/0`)
    .then(({ data }) => {
      setActivity(data.rows);
      setEnd(data.end);
    });

    axios.get(`/user/other-activity/${username}`)
    .then(({ data }) => {
      setOther(data);
    });
  }, [username, setTitle, setDescription]);

  const loadMore = () => {
    loadMoreRef.current.setPending(true);
    axios.get(`/user/activity/${username}/${activity.length}`)
    .then(({ data }) => {
        const exists = new Set(activity.map(({ anchor }) => anchor));
        setActivity([
          ...activity,
          ...data.rows.filter(post => {
            return !exists.has(post.anchor);
          })
        ]);
        setEnd(data.end);
    })
    .finally(() => {
      loadMoreRef.current.setPending(false);
    });
  };
  
  const goToChat = () => {
    navigate(`/chat/${username}`);
  };

  const followRef = useRef();
  const follow = () => {
    if(!user) return;
    const state = !user.following;

    followRef.current.setPending(true);
    axios.post('/follow', { state, id: user.id })
    .then(() => {
      setUser({ ...user, following: state });
    })
    .catch(() => {
      alert('Something went wrong.');
    })
    .finally(() => {
      followRef.current.setPending(false);
    });
  };

  if(user === null) return <Error404 />
  if(!user) return null;

  const notSelf = !!(user && me?.perm && user.id !== me.id);

  return (
    <div className="view-user container" style={{ paddingTop: '50px' }}>
      <BackToTop />
      <div className="view-user-container">
        <div className="view-user-port">
          <div className="view-user-pfp">
            <img src={pfp_path(user.id, user.pfp)} alt="" width="128" height="128" />
            <h2 className="user-link" style={{marginTop: 0}}>{user.username}</h2>
          </div>
          <div className="view-user-actions">
            <button className="wbtn slim" onClick={goToChat}>Send Message</button>
            {
              notSelf && 
              (user?.following ? 
                <Button ref={followRef} className="wbtn slim wbtn-outline" style={{ marginLeft: 10 }} onClick={follow}>Unfollow</Button> :
                <Button ref={followRef} className="wbtn slim" style={{ marginLeft: 10 }} onClick={follow}>Follow</Button>
              )
            }
          </div>
          <div style={{ marginTop: 15, fontWeight: 'bold', overflowWrap: 'anywhere', textAlign: 'center' }}>{user.jobTitle}</div>
          <ShowMore ref={bioObserver} className="view-user-bio" scroll={200}>{user.bio || <i>No description provided.</i>}</ShowMore>
        </div>
        {other.products?.length ?
          <div className="view-user-activity">
            <h1>Listed Products</h1>
            {other.products.map(product => (
              <ActivityProduct key={product.id} {...product} />
            ))}
          </div>
        : null}
        <div className="view-user-activity">
          <h1>Recent Activity</h1>
          {
            activity !== null &&
            (
              activity.length ?
              <>
              {activity.map((entry) => (
                <ActivityPost key={entry.anchor} {...entry} />
              ))}
              {!end && <Button className="wbtn slim" ref={loadMoreRef} onClick={loadMore}>Load More</Button>}
              </>
              :
              <div style={{ textAlign: 'center' }}>
                <i>No recent activity.</i>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default ViewUser