import './ChatSearch.css';
import React from 'react'

function ChatSearch({ contacts }) {
  const search = (e) => {
    const query = e.target.value.trim().replace(/ /g, '_');
    contacts.current.filter(query);
  };

  return (
    <div className="chat-search">
      <input onChange={search} type="text" placeholder="Filter by username..." />
    </div>
  )
}

export default ChatSearch