import React, { useEffect, useRef, useState } from 'react';
import './Comments.css';
import WriteComment from '../WriteComment/WriteComment';
import GenericPost from '../GenericPost/GenericPost';

function Comments({ init, anchor }) {
  const [comments, setComments] = useState(init);
  const [focus, setFocus] = useState(anchor);
  const focusRef = useRef(null);

  const addComment = (comment) => {
    setComments([
      ...comments,
      comment,
    ]);
    setFocus('#c'+comment.id);
  };

  const addReply = (commentId, reply) => {
    setComments(comments.map((comment) => (
      comment.id === commentId ?
      {
        ...comment,
        replies: [...comment.replies, reply],
      } :
      comment
    )));
    setFocus('#r'+reply.id);
  };

  useEffect(() => {
    focusRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }, [focus]);

  return (
    <div className="comments">
      {
        comments.map((comment) => {
          const { replies, ...data } = comment;
          const replyFeature = {
            commentId: comment.id,
            newReply: addReply.bind(null, comment.id),
          };
          const focusProps = { ref: focusRef, glow: true };

          return (
            <React.Fragment key={comment.id}>
              <GenericPost type="comment" {...data} {...replyFeature} {...(focus === '#c'+comment.id && focusProps)} />
              {
                comment.replies.map((reply) => {
                  return <GenericPost key={reply.id} type="reply" {...reply} {...replyFeature} {...(focus === '#r'+reply.id && focusProps)} />
                })
              }
            </React.Fragment>
          );
        })
      }
      <WriteComment newComment={addComment} />
    </div>
  );
}

export default Comments;
