import { Link } from 'react-router-dom';
import PostVotes from '../PostVotes/PostVotes';
import './ActivityPost.css';

import React from 'react'

function ActivityPost({ postId, anchor, type, title, description, total, mine, edited, timestamp }) {
  return (
    <Link className="activity-post post-container fake-link" to={`/posts/view/${postId}#${anchor}`} target="_blank">
      <PostVotes total={total} mine={mine} />
      <div className="post-viewport">
        <div className="post-content">
          {title && <h1>{title}</h1>}
          <div className="ck-content preview" dir="ltr">
            <p>{description} …</p>
          </div>
        </div>
        <div className="post-footer">
          <div className="post-rightmeta">
            {type.toUpperCase()}
          </div>
          <div className="post-author">
            <div className="post-timestamp">
            {edited ? 'Edited' : 'Posted'} {timestamp}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ActivityPost