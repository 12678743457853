import { useState, isValidElement, useRef, useCallback } from 'react';

function useValidation(rules) {
  const [error, setError] = useState({ has: {}, msg: {} });

  const required = (value) => value.trim().length;
  const optional = () => true;

  const isMsg = (value) => (
    typeof value === 'string' || isValidElement(value)
  );

  const getRule = (name) => {
    if (rules[name] === undefined && rules._default !== undefined) {
      return getRule('_default');
    }

    if (typeof rules[name] === 'function') {
      return rules[name];
    }

    if (rules[name]) {
      return required;
    }

    return optional;
  };

  const validate = ({name, value}) => {
    const result = getRule(name)(value);
    if (isMsg(result)) {
      throwError(name, result);
      return false;
    }
    if (!result) {
      throwError(name);
      return false;
    }
    removeError(name);
    return true;
  };

  const throwError = (name, msg) => {
    setError((error) => {
      return {
        has: { ...error.has, [name]: true },
        msg: { ...error.msg, [name]: msg  }
      };
    });
  };

  const removeError = (name) => {
    setError(({ has, msg }) => {
      const { [name]: hasName, ...newHas } = has;
      const { [name]: msgName, ...newMsg } = msg;
      return { has: newHas, msg: newMsg };
    });
  };

  const fieldRefs = [];
  const useField = () => {
    const fieldRef = useRef();
    fieldRefs.push(fieldRef);
    return useCallback((field) => {
      fieldRef.current = field;
      field?.addEventListener('blur', () => {
        validate(field);
      });
    }, []);
  };

  const validateAll = () => {
    const activeFieldRefs = fieldRefs.filter((fieldRef) => fieldRef.current);
    if(!activeFieldRefs.reduce((valid, fieldRef) => validate(fieldRef.current) && valid, true))
      return false;
    return Object.fromEntries(activeFieldRefs.map(({ current: { name, value } }) => [name, value]));
  };

  return {
    error,
    throwError,
    removeError,
    validateAll,
    useField,
  };
}

export default useValidation;