import React, { createContext, useRef, useState } from 'react';
import useUpdater from '../hooks/useUpdater';

export const WeekContext = createContext(null);

function WeekContextProvider({ children, autosave: [autosave, flush], data: saved }) {
  const [days, _setDays] = useState(saved?.days ?? []);
  const [timelineData, _setTimelineData] = useState(saved?.timelineData ?? {});
  const [notify, _setNotify] = useState(saved?.notify ?? true);

  const update = autosave.bind(null, 'week');
  const save = (newType) => {
    update(data.current, newType);
    flush();
  };

  const data = useRef({ days, timelineData, notify });
  const setDays = useUpdater(data, 'days', _setDays, update);
  const setTimelineData = useUpdater(data, 'timelineData', _setTimelineData, update);
  const setNotify = useUpdater(data, 'notify', _setNotify, update);

  const reset = () => {
    setDays([], false);
    setTimelineData({}, false);
    setNotify(true, false);
  };

  return (
    <WeekContext.Provider value={{ save, days, setDays, timelineData, setTimelineData, reset, notify, setNotify }}>
      {children}
    </WeekContext.Provider>
  );
}

export default WeekContextProvider;