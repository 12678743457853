import axios from 'axios';
import './ChatBody.css';
import React, { useContext, useRef } from 'react'
import { getext, SUPPORTED_EXTENSIONS } from '../../../util/extensions';
import ChatInput from '../ChatInput/ChatInput';
import Button from '../../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatMessages from '../ChatMessages/ChatMessages';
import { ChatContext } from '../../../contexts/ChatContextProvider';
import useClickOutside from '../../../hooks/useClickOutside';
import { UserContext } from '../../../contexts/UserContextProvider';
import { socket } from '../../../App';

const MAX_FILES = 15;

function ChatBody() {
  const { user: me } = useContext(UserContext);
  const { contact, setContact, setPage } = useContext(ChatContext);

  const msgRef = useRef();
  const chatMessagesRef = useRef();
  const sendRef = useRef();

  const sendMessage = (e) => {
    e.preventDefault();
    if(sendRef.current.pending) return;
    const content = msgRef.current.value.trim();
    if(!content) return;

    sendRef.current.setPending(true);
    axios.post('/chat/msg', { to: contact.id, content, socket: socket.id })
    .then(({ data }) => {
      chatMessagesRef.current.addMessage(data);
      msgRef.current.setValue('');
    })
    .catch((error) => {
      if(error.response.status === 500)
        alert('Something went wrong.');
      else
        alert(error.response.data);
    })
    .finally(() => {
      sendRef.current.setPending(false);
    });
  };

  const inputImg = useRef();
  const inputAttach = useRef();

  const uploadImages = (e) => {
    const files = Array.from(e.target.files);
    if(!files.length) return;

    if(files.length > MAX_FILES) {
      alert(`You cannot upload more than ${MAX_FILES} images at a single time.`);
      e.target.value = null;
      return;
    }
    const badFilesExt = files.filter(file => !SUPPORTED_EXTENSIONS.includes(getext(file.name)));
    if(badFilesExt.length) {
      alert(`Error. The following files are using unsupported extensions:\n` + badFilesExt.map(file => file.name).join(', '));
      e.target.value = null;
      return;
    }
    const badFilesSize = files.filter(file => file.size > 10*1024*1024);
    if(badFilesSize.length) {
      alert(`Error. The following files are too large (the maximum size for each file is 10MB):\n` + badFilesSize.map(file => file.name).join(', '));
      e.target.value = null;
      return;
    }

    if(!window.confirm('You are about to upload the following images:\n' + files.map(file => file.name).join(', '))) {
      e.target.value = null;
      return;
    }

    const formData = new FormData();
    formData.append('to', contact.id);
    formData.append('socket', socket.id);
    files.forEach(file => {
      formData.append('files', file);
    });
    axios.post('/chat/img', formData)
    .then(({ data }) => {
      chatMessagesRef.current.addMessage(data);
    })
    .catch((error) => {
      if(error.response.status === 500)
        alert('Something went wrong.');
      else if(error.response.status === 429)
        alert('You have reached your daily chat upload limit.');
      else
        alert(error.response.data);
    })
    .finally(() => {
      e.target.value = null;
    });
  };

  const uploadFiles = (e) => {
    const files = Array.from(e.target.files);
    if(!files.length) return;

    if(files.length > MAX_FILES) {
      alert(`You cannot upload more than ${MAX_FILES} files at a single time.`);
      e.target.value = null;
      return;
    }
    const badFilesSize = files.filter(file => file.size > 10*1024*1024);
    if(badFilesSize.length) {
      alert(`Error. The following files are too large (the maximum size for each file is 10MB):\n` + badFilesSize.map(file => file.name).join(', '));
      e.target.value = null;
      return;
    }

    if(!window.confirm('You are about to upload the following files:\n' + files.map(file => file.name).join(', '))) {
      e.target.value = null;
      return;
    }

    const formData = new FormData();
    formData.append('to', contact.id);
    formData.append('socket', socket.id);
    files.forEach(file => {
      formData.append('files', file);
    });
    axios.post('/chat/attach', formData)
    .then(({ data }) => {
      chatMessagesRef.current.addMessage(data);
    })
    .catch((error) => {
      if(error.response.status === 500)
        alert('Something went wrong.');
      else if(error.response.status === 429)
        alert('You have reached your daily chat upload limit.');
      else
        alert(error.response.data);
    })
    .finally(() => {
      e.target.value = null;
    });
  };

  const acceptUserRef = useRef();
  const blockUserRef = useRef();
  const blockUserRef2 = useRef();
  const unblockUserRef = useRef();

  const acceptUser = () => {
    acceptUserRef.current.setPending(true);
    axios.post(`/chat/action/message/${contact.id}`)
    .then(() => {
      setContact({ ...contact, page: 'message' });
      setPage('message');
    })
    .catch(() => {
      alert('Something went wrong.');
    })
    .finally(() => {
      acceptUserRef.current.setPending(false);
    });
  };

  const blockUser = (which) => {
    which.current.setPending(true);
    axios.post(`/chat/action/block/${contact.id}`)
    .then(() => {
      setContact({ ...contact, page: 'block' });
    })
    .catch(() => {
      alert('Something went wrong.');
    })
    .finally(() => {
      which.current.setPending(false);
    });
  };

  const unblockUser = () => {
    unblockUserRef.current.setPending(true);
    axios.post(`/chat/action/message/${contact.id}`)
    .then(() => {
      setContact({ ...contact, page: 'message' });
      setPage('message');
    })
    .catch(() => {
      alert('Something went wrong.');
    })
    .finally(() => {
      unblockUserRef.current.setPending(false);
    });
  };

  const optionsRef = useRef();
  const toggleOptions = () => {
    optionsRef.current.hidden = !optionsRef.current.hidden;
  };
  const closeOptions = () => {
    optionsRef.current.hidden = true;
  };

  const optionsMenu = useClickOutside(() => {
    closeOptions();
  });

  if(contact === null) return <div className="chat-body" />;
  const contactBasedRender = () => {
    switch(contact.page) {
      case 'message':
        return (
          <form className="chat-footer" onSubmit={sendMessage}>
            {contact.id !== me?.id &&
            <div className="chat-special" ref={optionsMenu}>
              <div className="chat-special-popup" ref={optionsRef} hidden>
                <div className="chat-special-popup-close">
                  <FontAwesomeIcon icon="fa-solid fa-xmark" onClick={closeOptions} />
                </div>
                <Button className="chat-special-block" ref={blockUserRef2} onClick={blockUser.bind(null, blockUserRef2)}>Block</Button>
              </div>
              <button type="button" onClick={toggleOptions}><FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /></button>
            </div>}
            <div className="chat-special">
              <input type="file" multiple hidden ref={inputAttach} onChange={uploadFiles} />
              <button type="button" onClick={() => inputAttach.current.click()}><FontAwesomeIcon icon="fa-solid fa-paperclip" /></button>
            </div>
            <div className="chat-special">
              <input type="file" multiple hidden ref={inputImg} accept={SUPPORTED_EXTENSIONS.join()} onChange={uploadImages} />
              <button type="button" onClick={() => inputImg.current.click()}><FontAwesomeIcon icon="fa-solid fa-image" /></button>
            </div>
            <ChatInput ref={msgRef} sendMessage={sendMessage} />
            <div>
              <Button ref={sendRef} type="submit">Send</Button>
            </div>
          </form>
        )
      case 'request':
        return (
          <div className="chat-footer">
            <div className="chat-footer-prompt">
              <p className="chat-footer-info">Press "Accept" if you would like to respond.</p>
              <div className="chat-footer-buttons">
                <Button className="chat-footer-accept" onClick={acceptUser} ref={acceptUserRef}>Accept</Button>
                <Button className="chat-footer-block" onClick={blockUser.bind(null, blockUserRef)} ref={blockUserRef}>Block</Button>
              </div>
            </div>
          </div>
        )
      case 'block':
        return (
          <div className="chat-footer">
            <div className="chat-footer-prompt">
              <p className="chat-footer-info">This user cannot send you any messages.</p>
              <div className="chat-footer-buttons">
                <Button onClick={unblockUser} ref={unblockUserRef}>Unblock</Button>
              </div>
            </div>
          </div>
        )
      default:
        return null;
    }
  };

  return (
    <div className="chat-body">
      <ChatMessages ref={chatMessagesRef} />
      {contactBasedRender()}
    </div>
  )
}

export default ChatBody