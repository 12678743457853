import React from 'react';
import './MenuItem.css';
import { NavLink } from 'react-router-dom';

function MenuItem(props) {
  return (
    <li className="menu-item">
      <NavLink to={props.to} className={({ isActive }) => `menu-link ${isActive && 'menu-active'}`} onClick={props.onClick}>
        {props.children}
      </NavLink>
    </li>
  );
}

export default MenuItem;