import './Square.css'
import React from 'react'

function Square({ 
  children, selected, toggle,
  defaultSelected, selectAll,
  ...props
}) {
  return (
    <div className="square" data-selected={!!selected} onClick={toggle} {...props}>
      {children}
    </div>
  )
}

export default Square