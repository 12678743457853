import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

export const UserContext = createContext(null);

function UserContextProvider(props) {
  const [user, setUser] = useState();

  useEffect(() => {
    axios.get('/account/info')
    .then(({ data }) => {
      setUser(data);
    })
    .catch(() => {
      window.location.href = process.env.REACT_APP_BASE_URL + '/error/?r=' + encodeURIComponent(window.location.href);
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;