import { useMemo } from "react";

function useShowMore() {
  return useMemo(() => {
    return new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if(entry.target.scrollHeight > entry.target.getAttribute('data-scroll')) {
          entry.target.setAttribute('data-overflow', '');
        } else {
          entry.target.removeAttribute('data-overflow');
        }
      });
    });
  }, []);
}

export default useShowMore;
