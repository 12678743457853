import { useEffect, useRef } from 'react';

function useClickOutside(handler) {
  const ref = useRef();
  useEffect(() => {
    const handleClick = (e) => {
      if(ref.current && !ref.current.contains(e.target)) {
        handler();
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handler]);
  return ref;
}

export default useClickOutside;