import './Logout.css';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../../contexts/UserContextProvider';
import { useParams } from 'react-router-dom';

function Logout() {
  const { user } = useContext(UserContext);
  const { state } = useParams();

  useEffect(() => {
    if(state === 'r') return;
    axios.post('/account/logout')
    .then(() => {
      window.location.replace('/logout/r');
    });
  }, [state]);

  return (
    <div className="logout container">
      {user?.perm === 0 && <p>You have been logged out.</p>}
    </div>
  );
}

export default Logout;