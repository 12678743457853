import './Home.css';
import React from 'react'
import { ReactComponent as PostsIcon } from './posts.svg';
import { ReactComponent as ChatIcon } from './chat.svg';
import { ReactComponent as JobsIcon } from './jobs.svg';
import { ReactComponent as ShopIcon } from './shop.svg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Home() {
  return (
    <div className="home">
      <div className="home-top">
        <h1>All-in-One platform for Eye Care Professionals</h1>
        <div className="home-cards">
          <Link to="/posts" className="home-card fake-link">
            <PostsIcon width="100" height="100" />
            <div>Explore Forum</div>
          </Link>
          <Link to="/chat" className="home-card fake-link">
            <ChatIcon width="100" height="100" />
            <div>Chat with others</div>
          </Link>
          <Link to="/browse-jobs" className="home-card fake-link">
            <JobsIcon width="100" height="100" />
            <div>Apply for jobs</div>
          </Link>
          <Link to="/products" className="home-card fake-link">
            <ShopIcon width="100" height="100" />
            <div>Buy or Sell</div>
          </Link>
          <Link to="/shifts" className="home-card fake-link">
            <FontAwesomeIcon icon="fa-regular fa-clock" style={{ fontSize: 100 }} />
            <div>Book Shifts</div>
          </Link>
        </div>
      </div>
      <div className="home-sections">
        <div className="home-section">
          <div className="home-section-left">
            <Link to="/posts" className="fake-link">
              <PostsIcon width="150" height="130" />
            </Link>
          </div>
          <div className="home-section-right">
            <h1>Professional Forums</h1>
            <p>
              Exchange insights, seek advice, and share your expertise in our dynamic
              Q&A-style forums.
            </p>
          </div>
        </div>
        <div className="home-section">
          <div className="home-section-left">
            <Link to="/chat" className="fake-link">
              <ChatIcon width="150" height="150" />
            </Link>
          </div>
          <div className="home-section-right">
            <h1>Live Communication</h1>
            <p>
            Our live chat feature allows you to connect with fellow professionals in
real-time. Whether you need quick advice or want to discuss industry trends, our platform
keeps you connected wherever you are.
            </p>
          </div>
        </div>
        <div className="home-section">
          <div className="home-section-left">
            <Link to="/browse-jobs" className="fake-link">
              <JobsIcon width="150" height="150" />
            </Link>
          </div>
          <div className="home-section-right">
            <h1>Job Opportunities</h1>
            <p>
            Whether you're looking to hire top talent or seeking new career
opportunities, ECP Vision's job board is your one-stop destination. Post job listings, apply for
positions, and take the next step in your career with ease.
            </p>
          </div>
        </div>
        <div className="home-section">
          <div className="home-section-left">
            <Link to="/products" className="fake-link">
              <ShopIcon width="150" height="150" />
            </Link>
          </div>
          <div className="home-section-right">
            <h1>Marketplace</h1>
            <p>
            Explore our marketplace to buy and sell products tailored to your profession.
From cutting-edge equipment to essential supplies, connect with verified sellers and negotiate
prices directly within the platform.
            </p>
          </div>
        </div>
        <div className="home-section">
          <div className="home-section-left">
            <Link to="/shifts" className="fake-link">
              <FontAwesomeIcon icon="fa-regular fa-clock" style={{ fontSize: 130 }} />
            </Link>
          </div>
          <div className="home-section-right">
            <h1>Flexible Shift Booking</h1>
            <p>
            Take control of your schedule with our shift booking feature. Whether
you're seeking additional work or looking to fill vacancies in your working hours, our platform
makes scheduling effortless.
            </p>
          </div>
        </div>
      </div>
      <div className="home-adv" style={{ textAlign: 'center' }}>
        <h1>Sign up for free</h1>
        <p style={{ marginTop: '0', fontSize: '20px' }}>Creating an account will only take a minute.</p>
        <Link to="/register" className="wbtn">Sign Up Now</Link>
      </div>
    </div>
  )
}

export default Home