import axios from 'axios';
import PostPreview from '../PostPreview/PostPreview'
import './Posts.css'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import useRandom from '../../hooks/useRandom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../Loading/Loading';
import useLazyLoad from '../../hooks/useLazyLoad';

function Posts({ visible, filter, search }) {
  const [seed, scramble] = useRandom(0, 999_999_999);
  const offset = useRef(0);
  const [posts, setPosts] = useState([]);
  const [end, setEnd] = useState(false);
  const navigate = useNavigate();

  const attachScrollNode = useLazyLoad((done) => {
    axios.get(`/get-posts/${filter}/${offset.current}/` +
              (filter === 'random' ? '?seed='+seed : '') +
              (filter === 'search' ? '?query='+encodeURIComponent(search) : '')
    )
    .then(({ data }) => {
      const { end, rows } = data;
      offset.current += rows.length;
      setEnd(end);
      setPosts(posts => {
        const exists = new Set(posts.map(({ id }) => id));
        return [
          ...posts,
          ...rows.filter(post => {
            return !exists.has(post.id);
          })
        ];
      });
    })
    .finally(() => {
      setTimeout(done, 1000);
    });
  });

  useEffect(() => {
    if(searchRef.current) searchRef.current.value = search;
    offset.current = 0;
    setPosts([]);
    setEnd(false);
  }, [filter, search]);

  const reorder = (e) => {
    if(e.target.value === 'random') scramble();
    navigate('/posts/' + e.target.value);
  };

  const searchRef = useRef();
  const onSearch = (e) => {
    e.preventDefault();
    if(searchRef.current) {
      const query = searchRef.current.value.trim();
      if(query) {
        navigate('/posts/search/' + encodeURIComponent(query));
      } else {
        navigate('/posts');
      }
    }
  };

  if(!visible) return null;
  return (
    <div className="posts page">
      <div className="view-post-area">
        <form className="posts-search" onSubmit={onSearch}>
          <input type="text" name="search-query" placeholder="Search posts..." ref={searchRef} defaultValue={search} maxLength="200" />
          <button type="submit"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></button>
        </form>
        <div className="posts-top">
          <div className="posts-filter">
            <label htmlFor="posts-select">Order by:</label>
            <select id="posts-select" onChange={reorder} value={filter}>
              {filter === 'search' && <option value="search">Relevance</option>}
              <option value="latest">Most Recent</option>
              <option value="trending">Trending</option>
              <option value="best">Highest Rated</option>
              <option value="random">Random</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
          <div className="space-between"></div>
          <div className="posts-write-own">
            <Link to="/write-post" className="orangebtn">Write your own post</Link>
          </div>
        </div>
        {posts.map((post) => 
          <PostPreview key={post.id} {...post} />
        )}
        {!posts.length && end && <p style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>No results found.</p>}
        {!end && <Loading ref={attachScrollNode} />}
      </div>
    </div>
  )
}

export default Posts