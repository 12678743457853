import './ShowMore.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useState } from 'react'

function ShowMore(props, ref) {
  const { className, scroll, style, children, ...attribs } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <div ref={ref} className={`${className} ${expanded && 'content-expanded'}`} 
         data-scroll={scroll} style={{ 
          ...style, 
          maxHeight: expanded ? 'none' : scroll+'px',
          position: 'relative',
          overflowY: 'hidden',
         }}
         {...attribs}>
      {children}
      <div className="show-more">
        <button onClick={() => setExpanded(!expanded)}>
          {!expanded ? 
            <>Show more <FontAwesomeIcon icon="fa-solid fa-caret-down" /></> :
            <>Show less <FontAwesomeIcon icon="fa-solid fa-caret-up" /></>
          }
        </button>
      </div>
    </div>
  )
}

export default forwardRef(ShowMore);