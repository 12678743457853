import axios from 'axios';
import './ViewApplication.css'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import Error404 from '../../Error404/Error404';
import Redirect from '../../Redirect/Redirect';
import { Link, useSearchParams } from 'react-router-dom';
import { getext } from '../../../util/extensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { globals } from '../../../App';

function ViewApplication() {
  const { id } = useParams();
  const [params] = useSearchParams();
  const type = params.get('type');
  const [data, setData] = useState();

  useEffect(() => {
    axios.get('/get-application/'+id+(type === 'shift' ? '?type=shift' : ''))
    .then(({ data }) => {
      setData(data);
    })
    .catch((error) => {
      if(error.response.status === 404)
        setData(null);
    });

    axios.post('/notifications/job-read', { application_id: id, type: type === 'shift' ? 'shift' : 'job' })
    .then(() => {
      globals._setAlertsImp?.(important => important.map(imp => {
        if(imp.application_id?.toString() === id.toString())
          return { ...imp, read: true };
        return imp;
      }));
    });
  }, [id, type]);

  if(data === null) return <Error404 />
  if(!data) return <Redirect to="/login" unless="user" />
  return (
    <div className="view-application container">
      <div className="form-panel" style={{ width: '1000px', overflowWrap: 'anywhere' }}>
        <div className="post-timestamp">Applied {data.timestamp}</div>
        <h1>{data.title}</h1>
        <div>
          <Link to={`/user/${data.user_id}`} className="user-link user-link-underline">@{data.username}</Link> ({data.full_name}) has applied for <Link to={`/${type === 'shift' ? 'shifts' : 'jobs'}/view/${data.id}`}>{data.company_name}</Link>.
        </div>
        <div className="form-field">
          <b>Full Name: </b> {data.full_name}
        </div>
        <div className="form-field">
          <b>Contact Email: </b> <Link to={`mailto:${data.email}`}>{data.email}</Link>
        </div>
        <div className="form-field">
          <b>Resume:</b>
          &nbsp;<FontAwesomeIcon icon="fa-solid fa-eye" /> <Link to={`/users/${data.user_id}/applications/${data.resume}`} target="_blank">View {getext(data.resume).toUpperCase()}</Link>
          &nbsp;&nbsp;&nbsp;
          &nbsp;<FontAwesomeIcon icon="fa-solid fa-download" /> <a href={`/users/${data.user_id}/applications/${data.resume}`} download={`${data.full_name}${getext(data.resume)}`}>Download {getext(data.resume).toUpperCase()}</a>
        </div>
        <div className="form-field">
          <b>Cover Letter:</b>
          <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>{data.cover_letter || <i>Not provided.</i>}</div>
        </div>
      </div>
    </div>
  )
}

export default ViewApplication