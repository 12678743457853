import React, { useRef } from 'react'
import { Transition } from 'react-transition-group';

const transitionStyles = {
  'exited': { display: 'none' },
  'exiting': { display: 'block' },
  'entered': { display: 'block' },
  'entering': { display: 'none' },
};

function FormError({ msg, success, style }) {
  const nodeRef = useRef();
  const lastMsg = useRef(msg);
  const hasMsg = (msg ?? null) !== null;

  if(hasMsg) {
    lastMsg.current = msg;
  }

  return (
    <Transition nodeRef={nodeRef} in={hasMsg} timeout={200}>
      {state => (
        <div
          ref={nodeRef} className={`form-error ${success && 'form-success'}`} 
          style={{...style, ...transitionStyles[state]}}
        >
          {lastMsg.current}
        </div>
      )}
    </Transition>
  );
}

export default FormError