import './ActivityProduct.css';
import React from 'react'
import { Link } from 'react-router-dom'
import { getDisplayPrice } from '../Store/ViewProduct/productInfo'

function ActivityProduct({ id, user_id, title, description, timestamp, image, width, height, price, priceType }) {
  const displayPrice = getDisplayPrice(priceType, price);

  return (
    <Link className="activity-product activity-post post-container fake-link" to={`/products/view/${id}`} target="_blank">
      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', width: '100%', height: 'fit-content' }}>
        <div> 
          <img src={`/users/${user_id}/products/_${image}`} width={width} height={height} alt={title} />
        </div>
        <div className="post-viewport" style={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div className="post-content">
            <div className="view-product-price">{displayPrice}</div>
            <h1>{title}</h1>
            <div className="ck-content preview" dir="ltr">
              <p>{description} …</p>
            </div>
          </div>
          <div className="post-footer" style={{ marginTop: 'auto' }}>
            <div className="post-author">
              <div className="post-timestamp">
              Posted {timestamp}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ActivityProduct