/* eslint-disable react/jsx-no-target-blank */
import axios from 'axios';
import React, { forwardRef, useContext, useId, useMemo, useReducer, useRef, useState, memo } from 'react'
import useLock from '../../hooks/useLock';
import { PostContext } from '../../contexts/PostContextProvider';
import useObserver from '../../hooks/useObserver';
import transformPost from './transformPost';
import CustomEditor from '../CustomEditor/CustomEditor';
import Button from '../Button/Button';
import PostActions from '../PostActions/PostActions';
import pfp_path from '../../util/pfp_path';
import ShowMore from '../ShowMore/ShowMore';
import PostVotes from '../PostVotes/PostVotes';

const votesReducer = (votes, action) => {
  switch(action.type) {
    case 'set':
      return { total: action.total, mine: action.mine };
    case 'cast':
      const total = votes.total - votes.mine + action.mine;
      return { total, mine: action.mine };
    default:
      return votes;
  }
};

const relativeData = {
  post: {
    scroll: 800,
  },
  comment: {
    scroll: 400,
    className: 'smaller-media',
    style: {
      marginTop: '30px',
    },
  },
  reply: {
    scroll: 400,
    className: 'smaller-media',
    style: {
      borderLeft: '5px solid #59CADB',
      borderTop: '1px solid #59CADB',
    }
  },
};

const GenericPost = forwardRef((props, ref) => {
  const { postId, scrollObserver, mentions, activeEditors, setTitle } = useContext(PostContext);
  const [observeScroll] = useObserver(scrollObserver);
  const [votes, dispatchVotes] = useReducer(votesReducer, { total: props.votes, mine: props.user_vote });
  const rel = relativeData[props.type];
  const [titleContent, setTitleContent] = useState(props.title);
  const [bodyContent, setBodyContent] = useState(props.body);
  const body = useMemo(() => transformPost(bodyContent), [bodyContent]);
  const editorRef = useRef(null);
  const replyRef = useRef();
  const [showWriteReply, setShowWriteReply] = useState(false);
  const id = useId();
  const [replyData, setReplyData] = useState('');
  const [editing, setEditing] = useState(false);
  const internalRef = useRef();
  const [deleted, setDeleted] = useState(props.deleted);
  const shareLink = postId + '#' + props.type[0] + props.id;

  const [vote, unlockVote] = useLock((voteButton) => {
    if(props.isAuthor) {
      alert('You cannot vote for your own post.');
      return unlockVote();
    }
    const val = voteButton !== votes.mine ? voteButton : 0;
    dispatchVotes({ type: 'cast', mine: val });

    axios.post(`/${props.type}/vote/${props.id}`, { val })
    .catch((error) => {
      // rollback vote
      dispatchVotes({ type: 'cast', mine: votes.mine });

      if(error.response.status === 403) { 
        alert('You must sign up to vote.');
      } else if(error.response.status === 429) {
        alert('You have reached your daily voting limit.');
      } else if(error.response.status === 404) {
        alert('Failed to vote. This post may have been deleted.');
      } else {
        alert('Something went wrong.');
      }
    })
    .finally(unlockVote);
  });

  const reply = () => {
    if(props.type === 'post') {
      if(activeEditors.current.comment) {
        activeEditors.current.comment.sourceElement.parentNode.scrollIntoView();
        activeEditors.current.comment.focus();
      }
      return;
    }

    if(props.type === 'reply' && !replyData) {
      setReplyData(`<p><a class="mention" data-mention="@${props.author_username}" href="/user/${props.author_username}">@${props.author_username}</a>&nbsp;</p>`);
    }
    setShowWriteReply(true);
  };

  const submitReply = () => { 
    if(!editorRef.current) return;
    replyRef.current.setPending(true);

    const body = editorRef.current.getData();
    axios.post('/reply/create', { id: props.commentId, body })
    .then(({ data }) => {
      setReplyData('');
      setShowWriteReply(false);
      props.newReply(data);
    })
    .catch((error) => {
      if(error.response.status === 413) {
        alert('Your post contains too much data.');
      } else if(error.response.status === 429) {
        alert('You have reached your daily comment/reply limit.');
      } else if(error.response.status === 400) {
        if(error.response.data !== '#ignore')
          alert(error.response.data);
      } else {
        alert('Something went wrong.');
      }
    })
    .finally(() => {
      replyRef.current.setPending(false);
    });
  };

  const cancelReply = () => {
    if(editorRef.current) setReplyData(editorRef.current.getData());
    setShowWriteReply(false);
    delete activeEditors.current.replies[id];
  };

  const onReady = (editor) => {
    editor.focus();
    // move cursor to end
    editor.model.change((writer) => {
      writer.setSelection( writer.createPositionAt( editor.model.document.getRoot(), 'end' ) );
    });
    activeEditors.current.replies[id] = editor;
  };

  const titleRef = useRef();
  const editBodyRef = useRef();
  const saveRef = useRef();

  const edit = () => {
    activeEditors.current.editors[id] = 1;
    setEditing(true);
  };

  const discardChanges = () => {
    delete activeEditors.current.editors[id];
    setEditing(false);
  };

  const saveChanges = () => {
    if(!editBodyRef.current) return;
    const body = editBodyRef.current.getData();
    const title = titleRef.current?.value;

    if(props.type === 'post' && title === titleContent && body === bodyContent) return discardChanges();
    if(props.type !== 'post' && body === bodyContent) return discardChanges();
    saveRef.current.setPending(true);
    
    axios.post(`/${props.type}/edit/${props.id}`, { title, body })
    .then(({ data }) => {
      if(data.title) {
        setTitle(data.title);
        setTitleContent(data.title);
      }
      setBodyContent(data.body);
      discardChanges();
      (ref ?? internalRef).current.classList.remove('post-glow');
      void (ref ?? internalRef).current.offsetWidth; // trigger reflow
      (ref ?? internalRef).current.classList.add('post-glow');
    })
    .catch((error) => {
      if(error.response.status === 413) {
        alert('Your post contains too much data.');
      } else if(error.response.status === 429) {
        alert('You have reached your daily editing limit.');
      } else if(error.response.status === 400) {
        if(error.response.data !== '#ignore')
          alert(error.response.data);
      } else {
        alert('Something went wrong.');
      }
    })
    .finally(() => {
      saveRef.current.setPending(false);
    });
  };

  const Delete = () => {
    if(!window.confirm('Are you sure you want to delete your post? This action cannot be reversed.'))
      return;

    axios.post(`/${props.type}/delete/${props.id}`)
    .then(() => {
      setDeleted(true);
    })
    .catch(() => {
      alert('Something went wrong.');
    });
  };

  return (<>
    <div ref={ref ?? internalRef} className={`post-container ${props.glow && 'post-glow'} ${rel.className}`} style={rel.style}>
      {!editing ? <>
      <PostVotes total={votes.total} mine={votes.mine} upvote={() => vote(1)} downvote={() => vote(-1)}  />
      <div className="post-viewport">
        <ShowMore className="post-content" scroll={rel.scroll} ref={observeScroll}>
          {!deleted ? <>
            {titleContent && <h1>{titleContent}</h1>}
            <div className="ck-content preview" dir="ltr" dangerouslySetInnerHTML={{ __html: body }} />
            </> : 
            <p className="deleted-post">[Deleted Post]</p>
          }
        </ShowMore>
        <div className="post-footer">
          {!deleted && <PostActions edit={edit} Delete={Delete} reply={reply} isAuthor={props.isAuthor} shareLink={shareLink} />}
          <div className="post-author">
            <div className="post-timestamp">
            {props.edited ? 'Edited' : 'Posted'} {props.timestamp}
            </div>
            <div className="post-pfp">
              <div>
                <img width="64" height="64" alt="" src={pfp_path(props.author_id, props.author_pfp)} />
              </div>
              <div className="post-pfp-text">
                <a href={`/user/${props.author_username}`} target="_blank" className="user-link">{props.author_username}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </> : 
      <div className="post-editor">
        {
          titleContent && 
          <div className="form-field">
            <input ref={titleRef} maxLength="200" defaultValue={titleContent} />
          </div>
        }
        <CustomEditor data={bodyContent} ref={editBodyRef} mentions={mentions} />
        <div className="edit-post-submit">
          <Button type="button" className="wbtn wbtn-outline" onClick={discardChanges}>Discard Changes</Button>
          <Button ref={saveRef} type="button" className="wbtn" onClick={saveChanges}>Save</Button>
        </div>
      </div>
    }
    </div>
    {
      showWriteReply &&
      <div className="write-reply post-container smaller-media">
        <div className="write-reply-editor">
          <CustomEditor data={replyData} ref={editorRef} mentions={mentions} onReady={onReady} />
          <div className="write-reply-submit">
            <Button type="button" className="wbtn wbtn-outline" onClick={cancelReply}>Cancel</Button>
            <Button ref={replyRef} type="button" className="wbtn" onClick={submitReply}>Reply</Button>
          </div>
        </div>
      </div>
    }
  </>)
});

export default memo(GenericPost);