import { useNavigate, useParams } from 'react-router';
import './Jobs.css';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import JobPreview from '../JobPreview/JobPreview';
import BackToTop from '../../BackToTop/BackToTop';
import { globals } from '../../../App';

function Jobs() {
  const { filter: _filter, search } = useParams();
  const filter = _filter ?? 'all';
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [loaded, setLoaded] = useState(null);

  const onSearch = (e) => {
    e.preventDefault();
    const query = e.target.elements['search-query'].value.trim();
    if(query) {
      navigate('/browse-jobs/search/' + encodeURIComponent(query));
    } else {
      navigate('/browse-jobs');
    }
  };

  const reorder = (e) => {
    const option = e.target.value;
    if(option === 'all')
      navigate('/browse-jobs');
    else
      navigate('/browse-jobs/' + option);
  };

  const path = `/get-jobs/${filter}` + (search ? `/${encodeURIComponent(search)}` : ``);
  useEffect(() => {
    axios.get(path)
    .then(({ data }) => {
      setJobs(data);
      setLoaded(path);
    });
  }, [path]);

  const getEmpty = () => {
    const map = {
      all: 'No jobs found.',
      search: 'No results found.',
      mine: 'You have not posted any jobs yet.', 
    };
    return (
      <p style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
        {map[filter]}
      </p>
    );
  };

  useLayoutEffect(() => {
    if(loaded !== path) return;
    return () => {
      globals.browseJobs_scrollY = window.scrollY;
      globals.browseJobs_scrollPath = path;
    };
  }, [loaded, path]);

  useLayoutEffect(() => {
    if(loaded !== path) return;
    if(globals.browseJobs_scrollY && globals.browseJobs_scrollPath === path) {
      window.scrollTo(0, globals.browseJobs_scrollY);
    }
  }, [loaded, path]);

  if(loaded !== path) return <Loading style={{ marginTop: '50px' }} />;
  return (
    <div className="posts page">
      <BackToTop />
      <div className="view-post-area">
        <form className="posts-search" onSubmit={onSearch}>
          <input type="text" name="search-query" placeholder="Search jobs..." defaultValue={search} maxLength="200" />
          <button type="submit"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></button>
        </form>
        <div className="posts-top">
          <div className="posts-filter">
            <label htmlFor="posts-select">Showing:</label>
            <select id="posts-select" onChange={reorder} value={filter}>
              {filter === 'search' && <option value="search">Search results</option>}
              <option value="all">Listed jobs</option>
              <option value="mine">My job posts</option>
            </select>
          </div>
          <div className="space-between"></div>
          <div className="posts-write-own">
            <Link to="/post-job" className="orangebtn">Post your own job</Link>
          </div>
        </div>
        {!jobs.length && getEmpty()}
        {jobs.map(job => {
          return (
            <JobPreview key={job.id} {...job} />
          );
        })}
      </div>
    </div>
  )
}

export default Jobs