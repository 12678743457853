import axios from 'axios';
import React, { useRef, useState } from 'react';
import useValidation from '../../../hooks/useValidation';
import { rules } from './registerRules';
import FormError from '../../FormError/FormError';
import { socket } from '../../../App';
import Redirect from '../../Redirect/Redirect';
import PasswordToggle from '../../PasswordToggle/PasswordToggle';
import Button from '../../Button/Button';
import ReCAPTCHA from 'react-google-recaptcha';

const errors = {
  GENERIC: ['generic', 'Something went wrong.'],
  EMAIL_TAKEN: ['emailTaken', 'Email already taken.'],
  USERNAME_TAKEN: ['usernameTaken', 'Username already taken.'],
};

function Register() {
  const { error, useField, validateAll, throwError, removeError } = useValidation(rules);
  const submitRef = useRef();
  const captchaRef = useRef();
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = validateAll();
    Object.values(errors).forEach((error) => {
      removeError(error[0]);
    });
    if(!data) return;

    data.email = data.email.trim();
    data.username = data.username.trim();
    data.socket = socket.id;
    if(captchaRef.current)
      data.captcha = captchaRef.current.getValue();

    submitRef.current.setPending(true);
    axios.post('/account/create', data)
    .then(() => {
      // tempLogin.data = data;
      // navigate('/verify', { replace: true });
      
      let attempts = 0;
      function retry() {
        if(attempts > 10) return;
        attempts++;

        axios.post('/account/login', { 
          user: data.email, 
          password: data.password,
        })
        .then(() => {
          window.location.href = process.env.REACT_APP_LANDING;
        })
        .catch(() => {
          setTimeout(retry, 3000);
        });
      }

      retry();
    })
    .catch((error) => {
      if(error.response.status === 429) {
        throwError('generic', 'Please click on the checkbox below.');
        setShowCaptcha(true);
      } else {
        const data = error.response.data;
        if(typeof data === 'object' && data.duplicate) {
          if(data.email) throwError(...errors.EMAIL_TAKEN);
          if(data.username) throwError(...errors.USERNAME_TAKEN);
        } else {
          throwError(...errors.GENERIC);
        }
      }
      submitRef.current.setPending(false);
      captchaRef.current?.reset();
    });
  };

  const verifyUsername = (e) => {
    removeError(errors.USERNAME_TAKEN[0]);
    if(error.has.username) return;
    const username = encodeURIComponent(e.target.value.trim());
    axios.get(`/exists/username/${username}`)
    .then(({ data }) => {
      if(data) {
        throwError(...errors.USERNAME_TAKEN);
      }
    });
  };

  const verifyEmail = (e) => {
    removeError(errors.EMAIL_TAKEN[0]);
    if(error.has.email) return;
    const email = encodeURIComponent(e.target.value.trim());
    axios.get(`/exists/email/${email}`)
    .then(({ data }) => {
      if(data) {
        throwError(...errors.EMAIL_TAKEN);
      }
    });
  };

  return (
    <div className="register container">
      <Redirect to={process.env.REACT_APP_LANDING} unless="guest" />
      <form className="form-panel" onSubmit={handleSubmit}>
        <h1>Create Account</h1>
        <FormError msg={error.msg.generic} />
        <div className="form-field" data-error={error.has.email}>
          <FormError msg={error.msg.email ?? error.msg.emailTaken} />
          <label htmlFor="register-email">Email*</label>
          <input name="email" id="register-email" ref={useField()} maxLength="256" onBlur={verifyEmail} />
        </div>
        <div className="form-field" data-error={error.has.username}>
          <FormError msg={error.msg.username ?? error.msg.usernameTaken} />
          <label htmlFor="register-username">Username*</label>
          <input name="username" id="register-username" ref={useField()} maxLength="24" onBlur={verifyUsername} />
        </div>
        <div className="form-field" data-error={error.has.password}>
          <FormError msg={error.msg.password} />
          <label htmlFor="register-password">Password*</label>
          <PasswordToggle name="password" id="register-password" ref={useField()} maxLength="256" />
        </div>
        {showCaptcha && <div style={{ marginTop: 15 }}>
          {<ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_API}
          />}
        </div>}
        <div className="form-field">
          <Button ref={submitRef} type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
}

export default Register;