import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import Posts from '../Posts/Posts';
import ViewPost from '../ViewPost/ViewPost';
import BackToTop from '../BackToTop/BackToTop';
import { globals } from '../../App';

const DEFAULT_SORT = 'latest';

function PostsWrapper() {
  const { pathname } = useLocation();
  const args = pathname.split('/');
  const type = args[2] || DEFAULT_SORT;
  const isView = type === 'view';
  const filter = useRef(DEFAULT_SORT);
  const search = useRef('');
  if(!isView) {
    filter.current = type;
    search.current = type === 'search' ? decodeURIComponent(args[3]) : '';
  }

  useEffect(() => {
    return () => {
      globals.posts_lastPath = pathname;
    };
  }, [pathname]);

  useEffect(() => {
    return () => {
      delete globals.posts_lastPath;
    };
  }, []);

  return (
    <>
      <Posts visible={!isView} filter={filter.current} search={search.current} />
      {isView && <ViewPost />}
      <BackToTop />
    </>
  )
}

export default PostsWrapper