
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useIsMounted from './useIsMounted';

function useDescription() {
  const metaTag = useMemo(() => document.querySelector('meta[name="description"]'), []);
  const descRef = useRef(metaTag.getAttribute('content'));
  const isMounted = useIsMounted();

  useEffect(() => {
    const oldDesc = descRef.current;
    return () => {
      metaTag.setAttribute('content', oldDesc);
    };
  }, [metaTag]);

  return useCallback((newDesc) => {
    if(!isMounted()) return;
    metaTag.setAttribute('content', newDesc);
  }, [metaTag, isMounted]);
}

export default useDescription;