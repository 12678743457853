import axios from 'axios';
import React, { useRef, useState } from 'react';
import useValidation from '../../../hooks/useValidation';

import { rules } from './loginRules';
import FormError from '../../FormError/FormError';
import { Link } from 'react-router-dom';
import Redirect from '../../Redirect/Redirect';
import PasswordToggle from '../../PasswordToggle/PasswordToggle';
import Button from '../../Button/Button';
import ReCAPTCHA from 'react-google-recaptcha';

function Login() {
  const { error, useField, validateAll, throwError, removeError } = useValidation(rules);
  const submitRef = useRef();
  const captchaRef = useRef();
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = validateAll();
    removeError('generic');
    if(!data) return;

    data.user = data.user.trim();
    if(captchaRef.current)
      data.captcha = captchaRef.current.getValue();

    submitRef.current.setPending(true);
    axios.post('/account/login', data)
    .then(() => {
      window.location.href = process.env.REACT_APP_LANDING;
    })
    .catch((error) => {
      if(error.response.status === 400) {
        throwError('generic', 'Invalid login credentials.');
      } else if(error.response.status === 429) {
        throwError('generic', 'Please click on the checkbox below.');
        setShowCaptcha(true);
      } else {
        throwError('generic', 'Something went wrong.');
      }
      captchaRef.current?.reset();
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  return (
    <div className="login container">
      <Redirect to={process.env.REACT_APP_LANDING} unless="guest" />
      <form className="form-panel" onSubmit={handleSubmit}>
        <h1>Sign In</h1>
        <FormError msg={error.msg.generic} />
        <div className="form-field" data-error={error.has.user}>
          <label htmlFor="login-user">Username or Email*</label>
          <input name="user" id="login-user" ref={useField()} maxLength="256" />
        </div>
        <div className="form-field" data-error={error.has.password}>
          <label htmlFor="login-password">Password*</label>
          <PasswordToggle name="password" id="login-password" ref={useField()} maxLength="256" />
          <div>
            <Link to="/reset-password">Forgot your password?</Link>
          </div>
        </div>
        {showCaptcha && <div style={{ marginTop: 15 }}>
          {<ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_API}
          />}
        </div>}
        <div className="form-field">
          <Button ref={submitRef} type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
}

export default Login;