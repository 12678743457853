
const sec = 1000;
const min = 60*sec;
const hours = 60*min;
const days = 24*hours;
const weeks = 7*days;
const months = 30*days;
const years = 365*days;

const conv = {
  [sec]: 'second',
  [min]: 'minute',
  [hours]: 'hour',
  [days]: 'day',
  [weeks]: 'week',
  [months]: 'month',
  [years]: 'year',
};

const hla_Format = (delta, unit) => {
  delta = delta/unit|0;
  unit = conv[unit];
  if(delta !== 1) unit += 's';
  return `${delta} ${unit} ago`;
};

module.exports = {
  isSameDay: (d1, d2) => (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  ),

  howLongAgo: (then, now) => {
    const delta = now - then;

    if(delta < sec)
      return 'just now';
    if(delta < min)
      return hla_Format(delta, sec);
    if(delta < hours)
      return hla_Format(delta, min);
    if(delta < days)
      return hla_Format(delta, hours);
    if(delta < weeks)
      return hla_Format(delta, days);
    if(delta < months)
      return hla_Format(delta, weeks);
    if(delta < years)
      return hla_Format(delta, months);

    return hla_Format(delta, years);
  },
};
