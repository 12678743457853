import React from 'react'

function Tab({ selected, children, ...props }) {
  return (
    <li className={`tab ${selected && 'tab-selected'}`} {...props}>
      {children}
    </li>
  )
}

export default Tab