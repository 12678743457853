
export const getTimeFromSchedule = (schedule) => {
  return Object.fromEntries(
    Object.entries(schedule[schedule.type])
    .map(([day, hours]) => {
      if(hours.every(b => !b)) return [day, Array(96).fill(1)];
      return [day, hours];
    })
  );
};
