import { useNavigate, useParams, useLocation } from 'react-router';
import './Products.css';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import BackToTop from '../../BackToTop/BackToTop';
import ProductPreview from '../ProductPreview/ProductPreview';
import useLazyLoad from '../../../hooks/useLazyLoad';
import { globals } from '../../../App';

function Products() {
  const { pathname } = useLocation();
  const { filter: _filter, search } = useParams();
  const filter = _filter ?? 'all';
  const navigate = useNavigate();

  const offset = useRef(globals.products?.data?.offset ?? 0);
  const [products, setProducts] = useState(globals.products?.data?.rows ?? []);
  const [end, setEnd] = useState(globals.products?.data?.end ?? false);

  const onSearch = (e) => {
    e.preventDefault();
    const query = e.target.elements['search-query'].value.trim();
    if(query) {
      navigate('/products/search/' + encodeURIComponent(query));
    } else {
      navigate('/products');
    }
  };

  const reorder = (e) => {
    const option = e.target.value;
    if(option === 'all')
      navigate('/products');
    else
      navigate('/products/' + option);
  };

  const attachScrollNode = useLazyLoad((done) => {
    axios.get(`/get-products/${filter}/${offset.current}/` +
              (filter === 'search' ? '?query='+encodeURIComponent(search) : '')
    )
    .then(({ data }) => {
      const { end, rows } = data;
      offset.current += rows.length;
      setEnd(end);
      setProducts(products => {
        const exists = new Set(products.map(({ id }) => id));
        return [
          ...products,
          ...rows.filter(product => {
            return !exists.has(product.id);
          })
        ];
      });
    })
    .finally(() => {
      setTimeout(done, 1000);
    });
  });

  useEffect(() => {
    if(globals.products) {
      delete globals.products;
      return;
    }
    offset.current = 0;
    setProducts([]);
    setEnd(false);
  }, [filter, search]);

  const pathRef = useRef(pathname);
  const productsRef = useRef(products);
  const endRef = useRef(end);

  useEffect(() => {
    pathRef.current = pathname;
    productsRef.current = products;
    endRef.current = end;
  }, [pathname, products, end]);

  useEffect(() => {
    return () => {
      if(!offset.current) {
        delete globals.products;
        return;
      }
      globals.products = globals.products || {};
      globals.products.data = {
        timestamp: new Date(),
        path: pathRef.current,
        rows: productsRef.current,
        end: endRef.current,
        offset: offset.current,
      };
    };
  }, []);

  useLayoutEffect(() => {
    return () => {
      if(!offset.current) {
        delete globals.products;
        return;
      }
      globals.products = globals.products || {};
      globals.products.scrollY = window.scrollY;
    };
  }, []);

  useLayoutEffect(() => {
    if(globals.products?.scrollY) {
      window.scrollTo(0, globals.products.scrollY);
    }
  }, []);

  const getEmpty = () => {
    const map = {
      all: 'No products found.',
      search: 'No results found.',
      mine: 'You have not posted any products yet.', 
    };
    return (
      <p style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
        {map[filter]}
      </p>
    );
  };

  return (
    <div className="posts page">
      <BackToTop />
      <div className="view-post-area">
        <form className="posts-search" onSubmit={onSearch}>
          <input type="text" name="search-query" placeholder="Search products..." defaultValue={search} maxLength="200" />
          <button type="submit"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></button>
        </form>
        <div className="posts-top">
          <div className="posts-filter">
            <label htmlFor="posts-select">Showing:</label>
            <select id="posts-select" onChange={reorder} value={filter}>
              {filter === 'search' && <option value="search">Search results</option>}
              <option value="all">Listed products</option>
              <option value="mine">My products</option>
            </select>
          </div>
          <div className="space-between"></div>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 15 }}>
            <div className="posts-write-own">
              <Link to="/sell" className="orangebtn">Post a product ad</Link>
            </div>
            <div className="posts-write-own">
              <Link to="/sell" className="orangebtn">Looking for product</Link>
            </div>
          </div>
        </div>
        {products.map(product => {
          return (
            <ProductPreview key={product.id} {...product} />
          );
        })}
        {!products.length && end && getEmpty()}
        {!end && <Loading ref={attachScrollNode} />}
      </div>
    </div>
  )
}

export default Products