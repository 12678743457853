
export function lastURLSegment(path) {
  if(path.slice(-1) === '/') path = path.slice(0, -1);
  return decodeURIComponent(path.split('/').pop());
}

export function getURLParams(path) {
  if(path === '/') return [];
  if(path.slice(-1) === '/') path = path.slice(0, -1);
  const args = path.split('/').slice(1);
  return args.map(arg => decodeURIComponent(arg));
}

export function validateUrl(str) {
  let url;
  
  try {
    url = new URL(str);
  } catch {
    try {
      url = new URL('http://' + str);
    } catch {
      return false;
    }
  }

  if(url.protocol !== 'http:' && url.protocol !== 'https:')
    return false;
  if(str.includes('@'))
    return false;
  return /\.[a-zA-Z]{2,}$/.test(str);
}

export function prettifyUrl(str) {
  let url;
  try {
    url = new URL(str);
  } catch {
    url = new URL('http://'+str);
  }
  return url.hostname;
}

export function linkifyUrl(str) {
  try {
    new URL(str);
  } catch {
    return 'http://'+str;
  }
  return str;
}
