import React, { useEffect, useRef, useState } from 'react';
import inbox from './inbox.png';
import './VerifyEmail.css';
import { socket, tempLogin } from '../../../App';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button/Button';
import toast, { Toaster } from 'react-hot-toast';

const toastOptions = {
  duration: 2000,
  position: 'top-center',
};

// fails silently
const login = () => {
  if(!tempLogin.data) return;

  axios.post('/account/login', { 
    user: tempLogin.data.email, 
    password: tempLogin.data.password,
  })
  .then(() => {
    window.location.href = process.env.REACT_APP_LANDING;
  })
  .catch(() => {});
};

function VerifyEmail() {
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if(!tempLogin.data) {
      navigate('/', { replace: true });
      return;
    }

    socket.off('verified').on('verified', () => {
      login();
    });
    setEmail(tempLogin.data.email);
  }, [navigate]);

  useEffect(() => {
    let tries = 0;
    let id = setInterval(() => {
      login();
      if(++tries >= 900)
        clearInterval(id);
    }, 4000);

    return () => {
      clearInterval(id);
    };
  }, []);

  const resendRef = useRef();
  const resend = () => {
    resendRef.current.setPending(true);
    axios.post('/account/create', tempLogin.data)
    .then(() => {
      toast.success('Email resent.');
    })
    .catch(() => {
      toast.error('Something went wrong.');
    })
    .finally(() => {
      resendRef.current.setPending(false);
    });
  };

  return (
    <div className="verify-email container">
      <Toaster toastOptions={toastOptions} />
      <div>
        <div>
          <img src={inbox} alt="inbox" width="256" height="256" />
        </div>
        <div>
          <p className="verify-email-msg">Check your inbox or spam folder for a verification email.</p>
          <p style={{ overflowWrap: 'anywhere' }}>
            <b>{email}</b>
          </p>
          <p>
            If you do not receive an email within a minute, press the resend button down below.
          </p>
          <Button ref={resendRef} className="wbtn slim" onClick={resend}>Resend</Button>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;