import axios from 'axios';
import { ChatContext } from '../../../contexts/ChatContextProvider';
import ChatMessage from '../ChatMessage/ChatMessage';
import './ChatMessages.css';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Loading from '../../Loading/Loading';
import useLazyLoad from '../../../hooks/useLazyLoad';
import { UserContext } from '../../../contexts/UserContextProvider';
import { socket } from '../../../App';

function ChatMessages(props, outerRef) {
  const ref = useRef();
  const { user: me } = useContext(UserContext);
  const { contact, notifications, lastRead } = useContext(ChatContext);
  const [messages, setMessages] = useState([]);

  const offset = useRef(0);
  const [end, setEnd] = useState(false);

  useEffect(() => {
    if(ref.current?.hasAttribute('data-scroll-down')) {
      setTimeout(() => {
        ref.current.scrollTop = ref.current.scrollHeight;
        ref.current.removeAttribute('data-scroll-down');
      }, 0);
    }
  });

  const addMessage = (message, autoscroll=true) => {
    offset.current++;
    setMessages(messages => [
      ...messages,
      message,
    ]);

    if(!ref.current) return;
    const nearBottom = Math.abs(ref.current.scrollHeight - ref.current.scrollTop - ref.current.clientHeight) <= 50;
    if(autoscroll || nearBottom)
      ref.current.setAttribute('data-scroll-down', '');
  };

  useImperativeHandle(outerRef, () => {
    return {
      addMessage,
    };
  }, []);

  const lastRequested = useRef();
  const latestContact = useRef();
  latestContact.current = contact.id;

  const loader = useLazyLoad((done) => {
    lastRequested.current = contact.id;

    axios.get(`/chat/get-convo/${contact.id}/${offset.current}`)
    .then(({ data }) => {
      if(lastRequested.current !== latestContact.current)
        return;

      setEnd(data.end);
      if(data.messages.length) {
        if(offset.current === 0)
          ref.current?.setAttribute('data-scroll-down', '');
        offset.current += data.messages.length;
        data.messages[data.messages.length-1].autofocus = true;
        setMessages([
          ...data.messages,
          ...messages,
        ]);
      }
    })
    .finally(() => {
      setTimeout(done, 1000);
    });
  });

  useEffect(() => {
    offset.current = 0;
    setMessages([]);
    setEnd(false);
  }, [contact]);

  useEffect(() => {
    socket.on('chat message', (message, author_id) => {
      if(author_id === contact.id) {
        notifications.read(`chat_messages.${contact.id}`, message._timestamp);
        addMessage(message, false);
      }
    });
    return () => {
      socket.off('chat message');
    };
  }, [contact, notifications]);

  let firstNewCreated = false;
  return (
    <div className="chat-messages" ref={ref}>
      {!end && <Loading ref={loader} />}
      {
        !messages.length && end ? 
        <div className="chat-messages-empty">
          Send a message to {contact.username === me?.username ? 'yourself' : contact.username}
        </div> :
        messages.map((data) => {
          const isUnread = me && data.op && lastRead && new Date(lastRead) < new Date(data._timestamp);
          let firstNew = false;
          if(isUnread && !firstNewCreated) {
            firstNew = true;
            firstNewCreated = true;
          }
          return <ChatMessage key={`${data.op ? '_' : ''}${data.id}`} {...data} firstNew={firstNew} />;
        })
      }
    </div>
  )
}

export default forwardRef(ChatMessages);