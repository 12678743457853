import React, { useCallback, useContext, useEffect, useState } from 'react'
import MenuAlert from '../MenuAlert'
import { globals, socket } from '../../../../App';
import { UserContext } from '../../../../contexts/UserContextProvider';
import axios from 'axios';

/* 
if true, all ungrouped notifications (group: null)
automatically get marked as read upon opening the mailbox.
*/
const READ_ALL_ON_OPEN = false;

function Mailbox({ compact }) {
  const { user } = useContext(UserContext);
  const [alerts, setAlerts] = useState([]);
  
  // really weird hack [#1]
  globals._setAlerts = (fn) => {
    if(!user) return;
    user.mailbox = fn(user.mailbox);
    setAlerts(user.mailbox);
  };

  const updateAlerts = useCallback(() => {
    if(!user) return;
    setAlerts(user.mailbox);
  }, [user]);

  useEffect(() => {
    updateAlerts();
  }, [updateAlerts]);

  useEffect(() => {
    if(!user) return;
    
    socket.on('mail', (alert) => {
      globals._setAlerts(alerts => addAlert(alerts, alert).slice(0, 99));
    });

    return () => {
      socket.off('mail');
    };
  }, [user]);

  const readAll = () => {
    if(!alerts.length) return;
    const a = alerts[alerts.length-1].timestamp;
    const b = alerts[0].timestamp;
    axios.post('/notifications/mail-read', { a, b })
    .then(() => {
      if(!user) return;
      user.mailbox = user.mailbox.map(alert => {
        const when = new Date(alert.timestamp);
        if(!alert.group && when >= new Date(a) && when <= new Date(b))
          return { ...alert, read: true };
        return alert;
      });
    });
  };

  const onopen = () => {
    if(READ_ALL_ON_OPEN) {
      readAll();
    }
  };

  const onread = (_id) => {
    if(!user) return;
    user.mailbox = user.mailbox.map(alert => {
      if(alert._id === _id)
        return { ...alert, read: true };
      return alert;
    });
  };

  const convertToView = (data) => {
    const { _id, timestamp, read } = data;
    return {
      _id,
      timestamp,
      read,
      message: getMessage(data),
      url: getUrl(data),
    };
  };

  return (
    <MenuAlert name="mailbox" alerts={alerts.map(convertToView)} compact={compact} icon="fa-solid fa-envelope" 
               onopen={onopen} onclose={updateAlerts} onread={onread} />
  );
}

const getMessage = ({ group, ...etc }) => {
  if(group?.endsWith('/msg')) {
    const { author, count } = etc;
    return <>
      <span className="user-link">@{author.username}</span>
      &nbsp;has sent you {count !== 1 ? count : 'a'} message{count !== 1 ? 's' : ''}.
    </>
  }
  if(group === 'follow') {
    const { title, author, type } = etc;
    const prefix = {
      post: 'posted:',
      job: 'posted a job:',
      product: 'posted a product:',
      shift: 'posted a shift:'
    }[type];

    return <>
      <span className="user-link">@{author.username}</span>
      &nbsp;{prefix} "{title}"
    </>
  }
  if(!group) {
    const { author, type } = etc;
    if(['post_mention', 'comment_mention', 'reply_mention'].includes(type)) {
      return <>
        <span className="user-link">@{author.username}</span>
        &nbsp;mentioned you in their {type.split('_')[0]}.
      </>
    }
    if(type === 'comment') {
      return <>
        <span className="user-link">@{author.username}</span>
        &nbsp;commented on your post.
      </>
    }
    if(type === 'reply') {
      return <>
        <span className="user-link">@{author.username}</span>
        &nbsp;replied to your comment.
      </>
    }
  }
  return etc.message;
};

const getUrl = ({ group, ...etc }) => {
  if(group?.endsWith('/msg')) {
    const { author, type } = etc;
    if(type === 'request') {
      return `/chat?r=${author.id}`;
    } else {
      return `/chat/${author.id}`;
    }
  }
  if(group === 'follow') {
    const { type, content_id } = etc;
    if(type === 'post') {
      return `/posts/view/${content_id}#p${content_id}`;
    } else if(type === 'job') {
      return `/jobs/view/${content_id}`;
    } else if(type === 'product') {
      return `/products/view/${content_id}`;
    } else if(type === 'shift') {
      return `/shifts/view/${content_id}`;
    }
  }
  if(!group) {
    const { content_id, post_id, type } = etc;
    if(type === 'post_mention') {
      return `/posts/view/${content_id}#p${content_id}`;
    }
    if(type === 'comment_mention' || type === 'comment') {
      return `/posts/view/${post_id}#c${content_id}`;
    }
    if(type === 'reply_mention' || type === 'reply') {
      return `/posts/view/${post_id}#r${content_id}`;
    }
  }
  return etc.url;
};

const mergeData = (prev, cur) => {
  if(!prev || prev.read || !cur.group || cur.group !== prev.group)
    return null;

  if(cur.group.endsWith('/msg')) {
    return {
      ...cur,
      count: prev.count + cur.count,
    };
  }

  return null;
};

const addAlert = (alerts, alert) => {  
  const merged = mergeData(alerts[0], alert);
  if(merged === null) return [alert, ...alerts];
  return [
    {
      ...merged,
      _id: alerts[0]._id,
    }, 
    ...alerts.slice(1)
  ]; 
};

export default Mailbox