import React, { useState, forwardRef, useImperativeHandle } from 'react';

const Button = forwardRef(({ children, disabled, ...attribs }, ref) => {
  const [manuallyDisabled, setManuallyDisabled] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      setPending: (pending) => {
        setManuallyDisabled(pending);
      },
      pending: manuallyDisabled,
    };
  }, [manuallyDisabled]);

  return (
    <button {...attribs} disabled={manuallyDisabled || disabled}>{children}</button>
  );
});

export default Button;