import './ErrorGeneral.css';
import React from 'react'

function ErrorGeneral() {
  const tryAgain = () => {
    window.location.reload();
  };

  const goHome = () => {
    window.location.href = '/';
  };

  return (
    <div className="error-general container">
      <div>
        <p>An unexpected error has occurred.</p>
        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <button className="wbtn wbtn-outline slim" onClick={goHome}>Back to Home</button>
          <button className="wbtn slim" onClick={tryAgain}>Try Again</button>
        </div>
      </div>
    </div>
  )
}

export default ErrorGeneral