import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import BackToTop from '../BackToTop/BackToTop'
import JobPreview from '../Jobs/JobPreview/JobPreview'
import Loading from '../Loading/Loading'
import './Shifts.css'
import AdvancedSearch from './components/AdvancedSearch/AdvancedSearch'
import LoadingTimetable from './components/LoadingTimetable/LoadingTimetable'
import DateContextProvider from './contexts/DateContextProvider'
import WeekContextProvider from './contexts/WeekContextProvider'
import useAutosave from './hooks/useAutosave'
import shifts_preview from './shifts_preview.png'
import Autocomplete from 'react-google-autocomplete';
import { globals } from '../../App'
import { getTimeFromSchedule } from './helpers/getTimeFromSchedule'
import { getIntersection } from './helpers/getIntersection'

const INIT_SCHEDULE = { type: 'date', date: {}, week: {} };

// minimum number of intersecting 15 minute segments to consider 2 jobs a match
const MIN_MATCH = 1;

function Shifts() {
  const [dateData, setDateData] = useState(null);
  const [weekData, setWeekData] = useState(null);
  const [active, setActive] = useState(null);
  const [schedule, setSchedule] = useState(INIT_SCHEDULE);
  const autosave = useAutosave(setSchedule);

  // loading | failed | ready | preview | waiting
  const [timetableStatus, setTimetableStatus] = useState('loading');

  const clearData = useCallback(() => {
    setDateData(null);
    setWeekData(null);
    setActive(null);
    setSchedule(INIT_SCHEDULE);
  }, []);

  useEffect(() => {
    if(timetableStatus !== 'loading') return;

    axios.get('/shifts/saved')
    .then(({ data }) => {
      setDateData(data.date ?? null);
      setWeekData(data.week ?? null);
      setActive(data.active ?? null);
      setTimetableStatus('ready');
      
      if(data.active) {
        setSchedule({ type: data.active, date: data.date.timelineData, week: data.week.timelineData });  
      }
    })
    .catch(({ response }) => {
      if(response.status === 403) {
        setTimetableStatus('preview');
      } else {
        setTimetableStatus('failed');
      }
    });
  }, [timetableStatus]);

  const [params, setParams] = useSearchParams();
  const filter = params.get('filter') ?? 'all';
  const search = params.get('search') ?? '';
  const loc = params.get('loc') ?? '';
  const dist = params.get('dist') ?? 'anywhere';

  const [jobs, setJobs] = useState([]);
  const [loaded, setLoaded] = useState(null);

  const path = `/shifts/get?${params.toString()}`;

  useEffect(() => {
    axios.get(path)
    .then(({ data }) => {
      setJobs(data);
      setLoaded(path);
    });
  }, [path]);

  const onSearch = (e) => {
    e.preventDefault();
    const query = e.target.elements['search-query'].value.trim();
    if(query) {
      setParams({ filter: 'search', search: query, loc, dist });
    } else {
      setParams({ loc, dist });
    }
  };

  const reorder = (e) => {
    const option = e.target.value;
    setParams({ filter: option, loc, dist });
  };

  // Autocomplete's onPlaceSelected does not update the callback, so we'll use a ref
  const locChangeRef = useRef();
  locChangeRef.current = (loc) => {
    setParams({ filter, search, loc, dist: dist === 'anywhere' ? '400' : dist });
  };

  const distChange = (e) => {
    const dist = e.target.value;
    setParams({ filter, search, loc, dist });
  };

  useLayoutEffect(() => {
    if(loaded !== path) return;
    return () => {
      globals.browseShifts_scrollY = window.scrollY;
      globals.browseShifts_scrollPath = path;
    };
  }, [loaded, path]);

  useLayoutEffect(() => {
    if(loaded !== path || timetableStatus === 'loading') return;
    if(globals.browseShifts_scrollY && globals.browseShifts_scrollPath === path) {
      window.scrollTo(0, globals.browseShifts_scrollY);
    }
  }, [loaded, path, timetableStatus]);

  const jobMatches = useMemo(() => {
    const timetable = getTimeFromSchedule(schedule);
    return jobs.map(job => 
      getIntersection(schedule.type, timetable, job.type, job.timetable)
    );
  }, [jobs, schedule]);

  const getEmpty = () => {
    const map = {
      all: 'No results found.',
      search: 'No results found.',
      mine: 'You have not posted any shifts yet.', 
    };
    return (
      <p style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
        {map[filter]}
      </p>
    );
  };

  const matchingJobs = jobs.filter((_job, i) => jobMatches[i] >= MIN_MATCH);
  const otherJobs = jobs.filter((_job, i) => jobMatches[i] < MIN_MATCH);

  const previewJob = (job) => {
    return (
      <JobPreview key={job.id} {...job} type="shift" />
    );
  };

  return (
    <div className="shifts page">
      <BackToTop />
      <div className="view-post-area">
        <h1>Find Temporary Shifts</h1>
        {
          timetableStatus === 'ready' ?
          <DateContextProvider data={dateData} autosave={autosave}>
            <WeekContextProvider data={weekData} autosave={autosave}>
              <AdvancedSearch autosave={autosave} setTimetableStatus={setTimetableStatus} clearData={clearData} active={active} />
            </WeekContextProvider>
          </DateContextProvider> :

          timetableStatus === 'loading' || timetableStatus === 'failed' || timetableStatus === 'waiting' ?
          <LoadingTimetable
            failed={timetableStatus === 'failed'}
            retry={() => setTimetableStatus('loading')}
          /> :
        
          timetableStatus === 'preview' &&
          <div className="form-segment" style={{ display: 'flex', flexWrap: 'wrap', gap: '5px 30px' }}>
            <div>
              <img src={shifts_preview} alt="Shifts Preview" />
            </div>
            <div style={{ flex: 1, minWidth: 250 }}>
              <h2>Choose your own schedule</h2>
              <p style={{ fontSize: 20 }}>
                <Link to="/register">Sign up</Link> to use advanced search, 
                which allows you to choose the times you are available and be automatically paired with matching shifts.
              </p>
              <p style={{ fontSize: 20 }}>
                You can also choose to be notified when any shifts matching your schedule become available.
              </p>
            </div>
          </div>
        }
        <div className="view-post-area">
          <div className="shifts-search">
            <form className="posts-search" onSubmit={onSearch} style={{ minWidth: 300, flex: 1 }}>
              <input type="text" name="search-query" placeholder="Search jobs..." defaultValue={search} maxLength="200" />
              <button type="submit"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></button>
            </form>
            
            <div className="posts-search" style={{ minWidth: 300 }}>
              <Autocomplete defaultValue={loc} onFocus={(e) => e.target.autocomplete = 'new-password'} onPlaceSelected={(_, input) => locChangeRef.current(input.value)} maxLength="128" placeholder="Enter your city" apiKey={process.env.REACT_APP_GOOGLE_API} />
              <select value={dist} onChange={distChange}>
                <option value="100">within 100km</option>
                <option value="200">within 200km</option>
                <option value="300">within 300km</option>
                <option value="400">within 400km</option>
                <option value="anywhere">anywhere</option>
              </select>
            </div>
          </div>

          <div className="posts-top">
            <div className="posts-filter">
              <label htmlFor="posts-select">Showing:</label>
              <select id="posts-select" onChange={reorder} value={filter}>
                {filter === 'search' && <option value="search">Search results</option>}
                <option value="all">Listed shifts</option>
                <option value="mine">My posted shifts</option>
              </select>
            </div>
            <div className="space-between"></div>
            <div className="posts-write-own">
              <Link to="/post-shift" className="orangebtn">Post your own shift</Link>
            </div>
          </div>
          {loaded !== path ? <Loading style={{ marginTop: '50px' }} /> : 
          <div className="posts">
            {filter !== 'mine' && <>
              {matchingJobs.length > 0 && <>
                <h2>Matching your schedule</h2>
                {matchingJobs.map(previewJob)}
              </>}
              
              {otherJobs.length > 0 && <>
                <h2>{matchingJobs.length ? 'Other available shifts' : 'Available shifts'}</h2>
                {otherJobs.map(previewJob)}
              </>}

              {!matchingJobs.length && !otherJobs.length && getEmpty()}
            </>}
            {filter === 'mine' && <>
              <h2>Your shifts</h2>
              {jobs.map(previewJob)}
              {!jobs.length && getEmpty()}
            </>}
          </div>}
        </div>
      </div>
    </div>
  )
}

export default Shifts