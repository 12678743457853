import './WritePost.css';
import React, { useRef } from 'react';
import CustomEditor from '../CustomEditor/CustomEditor';
import axios from 'axios';
import Redirect from '../Redirect/Redirect';
import { useNavigate } from 'react-router';
import Button from '../Button/Button';
import useDebounce from '../../hooks/useDebounce';

function WritePost() {
  const editorRef = useRef(null);
  const titleRef = useRef();
  const submitRef = useRef();
  const navigate = useNavigate();

  const reset = () => {
    titleRef.current.value = '';
    editorRef.current.setData('');
    localStorage.removeItem('post');
  };

  const clear = () => {
    if(!editorRef.current) return;

    if(window.confirm('Are you sure you want to clear the contents of your entire post including the title?')) {
      reset();
    }
  };

  const submit = () => {
    if(!editorRef.current) return;

    const title = titleRef.current.value;
    const body = editorRef.current.getData();

    submitRef.current.setPending(true);
    axios.post('/post/create', { body, title })
    .then(({ data }) => {
      reset();
      navigate('/posts/view/' + data.id, { replace: true });
    })
    .catch((error) => {
      if(error.response.status === 413) {
        alert('Your post contains too much data.');
      } else if(error.response.status === 429) {
        alert('You have reached your daily posting limit.');
      } else if(error.response.status === 400) {
        alert(error.response.data);
      } else {
        alert('Something went wrong.');
      }
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  const [autosave, flush] = useDebounce(() => {
    if(!editorRef.current) return;
    const title = titleRef.current.value;
    const body = editorRef.current.getData();
    localStorage.setItem('post', JSON.stringify({ title, body }));
  }, 1000, { flushOnUnmount: false, stall: 3000 });

  const onReady = (editor) => {
    editor.on('destroy', () => {
      flush();
    });

    const saved = localStorage.getItem('post');
    if(saved) {
      const { title, body } = JSON.parse(saved);
      titleRef.current.value = title ?? '';
      editor.setData(body ?? '');
    }
  };

  return (
    <div className="write-post page">
      <Redirect to="/register" unless="user" />
      <div className="write-post-area">
        <h1>Create Post</h1>
        <div className="form-field">
          <label htmlFor="write-post-title">Title*</label>
          <input ref={(title) => titleRef.current = title ?? titleRef.current} id="write-post-title" maxLength="200" onChange={autosave} />
        </div>
        <CustomEditor ref={editorRef} onReady={onReady} onChange={autosave} />
        <div className="write-post-buttons">
          <button type="button" tabIndex="-1" className="wbtn wbtn-outline" onClick={clear}>Clear</button>
          <Button ref={submitRef} type="button" tabIndex="-1" className="wbtn" onClick={submit}>Post</Button>
        </div>
      </div>
    </div>
  );
}

export default WritePost;