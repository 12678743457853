import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MenuAlert.css';
import React, { useCallback, useEffect, useState } from 'react';
import useClickOutside from '../../../hooks/useClickOutside';
import { howLongAgo } from '../../../util/time';

const MODAL_WIDTH = 400;

function MenuAlert({ name, compact, icon, alerts, onopen, onclose, onread }) {
  const [show, setShow] = useState(false);
  const [offset, setOffset] = useState(0);
  const [modalWidth, setModalWidth] = useState(MODAL_WIDTH);
  const [now, setNow] = useState(0);

  const closeMenu = () => {
    setShow(false);
    setOffset(0);
    onclose?.();
  };

  const openMenu = () => {
    setShow(true);
    setNow(new Date());
    determineOffset();
    onopen?.();
  };

  const toggle = () => {
    if(show) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const alertRef = useClickOutside(closeMenu);
  const determineOffset = useCallback(() => {
    const width = Math.min(MODAL_WIDTH, document.documentElement.clientWidth);
    const rect = alertRef.current.getBoundingClientRect();
    const left = rect.left + rect.width/2 - width/2;
    const right = left + width;
    setOffset( Math.round(-(Math.min(left, 0) || Math.max(right-document.documentElement.clientWidth, 0))) );
    setModalWidth(width);
  }, [alertRef]);

  useEffect(() => {
    window.addEventListener('resize', determineOffset);
    return () => {
      window.removeEventListener('resize', determineOffset);
    };
  }, [determineOffset]);

  const redirect = (url, _id, read, e) => {
    const newTab = e.ctrlKey || e.metaKey;

    if(!read) {
      onread?.(_id);
      navigator.sendBeacon('/s/notifications/id-read', new Blob(
        [JSON.stringify({ name, _id })],
        { type: 'application/json' }
      ));
    }

    if(newTab) {
      window.open(url, '_blank');
    } else {
      if(window.location.href.slice(window.location.origin.length) === url) {
        window.location.reload();
      } else {
        window.location.href = url;
        const forceReload = () => {
          window.removeEventListener('hashchange', forceReload);
          window.location.reload();
        };
        window.addEventListener('hashchange', forceReload);
      }
      closeMenu();
    }
  };

  const unreadCount = alerts.filter(({ read }) => !read).length;
  return (
    <div ref={alertRef} className={`menu-alert ${compact && 'menu-alert-compact'}`}>
      <FontAwesomeIcon icon={icon} onClick={toggle} />
      {unreadCount > 0 && <div className="menu-alert-count">{unreadCount}</div>}
      <div className="menu-alert-modal" style={{ 
        display: show ? '' : 'none',
        width: `${modalWidth}px`,
        marginLeft: `${-modalWidth/2}px`,
        transform: `translateX(${offset}px)`,
      }}>
        <div className="menu-alert-connector" style={{ transform: `translateX(${-offset}px)` }}></div>
        <ul className="menu-alerts hide-scroll">
          {alerts.map(({ _id, message, timestamp, read, url }) => (
            <li key={_id} className={`${!read && 'menu-alert-unread'}`} onClick={redirect.bind(null, url, _id, read)}>
              {message}
              <div className="menu-alert-timestamp">{howLongAgo(new Date(timestamp), now)}</div>
            </li>
          ))}
          {!alerts.length && <li className="menu-alert-empty">No notifications yet.</li>}
        </ul>
      </div>
    </div>
  );
}

export default MenuAlert;