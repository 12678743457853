import React, { useEffect, useRef } from 'react';
import './MenuGroup.css';
import { ReactComponent as Arrow } from './arrow.svg';

function MenuGroup(props) {
  const itemRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      const visible = dropdownRef.current.offsetParent !== null;

      if(!itemRef.current.contains(e.target)) {
        dropdownRef.current.style.display = 'none';
      } else if(!visible) {
        dropdownRef.current.style.display = 'block';
      }
    }

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleMouseOver = () => {
    dropdownRef.current.style.display = '';
  };

  return (
    <li className="menu-item menu-group">
      <div ref={itemRef} className="menu-link" onMouseOver={handleMouseOver}>
        {props.label} <Arrow />
      </div>
      <ul ref={dropdownRef} className="menu-dropdown">{props.children}</ul>
    </li>
  );
}

export default MenuGroup;