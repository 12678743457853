import React, { forwardRef } from 'react'
import spinner from '../../res/spinner.gif'

function Loading({ style }, ref) {
  return (
    <div className="loading" ref={ref} style={{ textAlign: 'center', ...style }}>
      <img src={spinner} alt="Loading" width="64" height="64" />
    </div>
  )
}

export default forwardRef(Loading)