import './CustomEditor.css';
import React, { forwardRef } from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MentionCustomization from './MentionCustomization';

const CustomEditor = forwardRef((props, ref) => {
  const uploadDest = props.uploadUrl ?? '/upload';

  const editorConfiguration = {
    extraPlugins: [MentionCustomization],
    mediaEmbed: {
      removeProviders: ['dailymotion', 'spotify', 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
      previewsInData: true,
    },
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
      'insertImage', 'mediaEmbed', 'insertTable', 'blockQuote', 'undo', 'redo'
    ],
    simpleUpload: {
      uploadUrl: process.env.REACT_APP_BASE_URL + (process.env.NODE_ENV === 'production' ? '/s' : '') + uploadDest,
      withCredentials: true,
    },
    mention: {
      feeds: [
          {
              marker: '@',
              dropdownLimit: 101,
              feed: (query) => {
                  if(!/^[a-zA-Z0-9_]+$/.test(query) || query.length > 24) return [];
  
                  const mentions = (props.mentions?.current ?? [])
                  .filter((mention) => (
                    mention.toUpperCase().startsWith(query.toUpperCase())
                  )).slice(0, 100);
  
                  if(!mentions.some((mention) => (
                    mention.toUpperCase() === query.toUpperCase())
                  )) mentions.push(query);
  
                  return mentions.map((mention) => '@'+mention);
              },
              minimumCharacters: 1
          }
      ]
    },
  };

  return (
    <CKEditor
      editor={ Editor }
      config={ editorConfiguration }
      data={ props.data }
      onReady={ (editor) => {
        ref.current = editor;
        props.onReady?.(editor);
      } }
      onChange={ props.onChange }
    />
  );
});

export default CustomEditor;
