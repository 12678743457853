import React, { forwardRef, useState } from 'react';
import { ReactComponent as Eye } from '../../res/eye.svg';
import { ReactComponent as EyeClosed } from '../../res/eyeClosed.svg';

const PasswordToggle = forwardRef((props, ref) => {
  const { visible, ...attribs } = props;
  const [showPassword, setShowPassword] = useState(visible ?? false);

  return (
    <>
      <input type={showPassword ? 'text' : 'password'} ref={ref} {...attribs} />
      <div className="input-icon no-select" onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <EyeClosed /> : <Eye />}
      </div>
    </>
  );
});

export default PasswordToggle;