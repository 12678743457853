import './Error404.css';
import React from 'react';

function Error404({ children }) {
  return (
    <div className="error-404 container">
      {children ?? <p><strong>404.</strong> The requested resource does not exist.</p>}
    </div>
  );
}

export default Error404;