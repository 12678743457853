import { useNavigate, useParams } from 'react-router';
import './ApplyJob.css';
import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios';
import Error404 from '../../Error404/Error404';
import Redirect from '../../Redirect/Redirect';
import { UserContext } from '../../../contexts/UserContextProvider';
import TextareaAutoresize from 'react-textarea-autosize';
import Button from '../../Button/Button';
import useUnsavedChanges from '../../../hooks/useUnsavedChanges';
import useValidation from '../../../hooks/useValidation';
import { getext } from '../../../util/extensions';
import FormError from '../../FormError/FormError';
import { useSearchParams } from 'react-router-dom';

const SUPPORTED_EXTENSIONS = ['.pdf', '.text', '.txt', '.rtf', '.doc', '.docx'];

const rules = {
  email: true,
  full_name: true,
};

function ApplyJob() {
  const { id } = useParams();
  const [params] = useSearchParams();
  const type = params.get('type');
  const [job, setJob] = useState();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const dirty = useRef(false);
  const created = useRef(false);
  const submitRef = useRef();
  const { error, useField, throwError, removeError, validateAll } = useValidation(rules);
  const emailField = useField();
  const nameField = useField();

  useEffect(() => {
    axios.get(type === 'shift' ? '/shifts/view/'+id : '/get-job/'+id)
    .then(({ data }) => {
      if(data.apply_url) {
        window.location.replace(type === 'shift' ? '/shifts/view/'+id : '/jobs/view/'+id);
      } else {
        setJob(data);
      }
    })
    .catch((error) => {
      if(error.response.status === 404)
        setJob(null);
    });
  }, [id, type]);

  const resumeUpload = (e) => {
    const [file] = e.target.files;
    if(!file) 
      return throwError('resume');
    if(!SUPPORTED_EXTENSIONS.includes(getext(file.name)))
      return throwError('resume', 'File extension not supported.');
    if(file.size > 8*1024*1024)
      return throwError('resume', 'File is too large. The limit is 8MB.');
    removeError('resume');
    return true;
  };

  const onChange = () => {
    dirty.current = true;
  };

  useUnsavedChanges(() => {
    return dirty.current && !created.current;
  });

  const handleApply = (target) => {
    const resumeValid = resumeUpload({ target: target.elements.resume });
    const detailsValid = validateAll();
    removeError('generic');
    if(!resumeValid || !detailsValid) return;
    submitRef.current.setPending(true);
    const formData = new FormData(target);
    formData.append('id', id);
    formData.append('type', type === 'shift' ? 'shift' : 'job');
    axios.post('/apply-job', formData)
    .then(() => {
      created.current = true;
      alert([
        `You have successfully applied to ${job.company_name}.\n`,
        "Any responses from the company will go directly to the email you have provided.",
      ].join('\n'));
      navigate(-1);
    })
    .catch((error) => {
      if(error.response.data)
        throwError('generic', error.response.data);
      else
        throwError('generic', 'Something went wrong.');
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  if(job === null) return <Error404 />
  if(!job || !user) return null;
  return (
    <div className="apply-job container" style={{ overflowWrap: 'anywhere' }}>
      <Redirect to="/register" unless="user" />
      <form className="form-panel" style={{ width: '800px' }} onSubmit={e => e.preventDefault()} onChange={onChange}>
        <h1>Applying to {job.company_name}</h1>
        <div style={{ maxWidth: '400px' }}>
          <FormError msg={error.msg.generic} />
          <div className="form-field" data-error={error.has.email}>
            <label htmlFor="apply-job-email">Email*</label>
            <input id="apply-job-email" defaultValue={user.email} name="email" ref={emailField} maxLength="128" />
            <small>Responses to your application will be delivered to this email.</small>
          </div>
          <div className="form-field" data-error={error.has.full_name}>
            <label htmlFor="apply-job-name">Full Name*</label>
            <input id="apply-job-name" name="full_name" ref={nameField} maxLength="64" />
          </div>
          <div className="form-field" data-error={error.has.resume}>
            <FormError msg={error.msg.resume} />
            <label htmlFor="apply-job-resume">Upload Resume*</label>
            <input type="file" name="resume" onChange={resumeUpload} accept={SUPPORTED_EXTENSIONS.join()} />
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="apply-job-cl">Cover Letter</label>
          <TextareaAutoresize id="apply-job-cl" minRows="8" maxRows="16" maxLength="5000" name="cover_letter" />
        </div>
        <div style={{ marginTop: '20px', textAlign: 'right' }}>
          <Button type="button" onClick={e => handleApply(e.target.closest('form'))} ref={submitRef} className="wbtn">Apply</Button>
        </div>
      </form>
    </div>
  );
}

export default ApplyJob;