import React, { useContext, useState } from 'react';
import MenuItem from './MenuItem/MenuItem';
import MenuGroup from './MenuGroup/MenuGroup';
import './Menu.css';
import MenuLogo from './MenuLogo/MenuLogo';
import { useMediaQuery } from 'react-responsive';
import MenuDivider from './MenuDivider/MenuDivider';
import MenuToggle from './MenuToggle/MenuToggle';
import { UserContext } from '../../contexts/UserContextProvider';
import { useNavigate } from 'react-router';
import Mailbox from './MenuAlert/Mailbox/Mailbox';
import Important from './MenuAlert/Important/Important';
import { globals } from '../../App';

function Menu() {
  const { user } = useContext(UserContext);
  const collapse = useMediaQuery({ query: '(max-width: 1068px)' });
  const compact = useMediaQuery({ query: '(max-width: 468px)' });
  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();
  const handlePostsLink = (e) => {
    const currentPath = window.location.pathname.split('/');
    if(currentPath[1] === 'posts' && currentPath[2] === 'view') {
      const lastPath = (globals.posts_lastPath ?? '').split('/');
      if(lastPath[1] === 'posts' && lastPath[2] !== 'view') {
        e.preventDefault();
        navigate(-1);
      }
    }
  };

  const handleProductsLink = (e) => {
    if(globals.products?.data) {
      if(new Date() - globals.products.data.timestamp > parseInt(process.env.REACT_APP_PAGESAVE_MS, 10)) {
        delete globals.products;
      } else {
        e.preventDefault();
        navigate(globals.products.data.path);
      }
    }
  };

  return (
    <nav className="menu no-select">
      {collapse ?
        <>
          <MenuToggle onToggle={() => setMenuOpen(!menuOpen)} onClose={() => setMenuOpen(false)} active={menuOpen} />
          {!compact && <>
            <MenuDivider />
            <MenuLogo />
          </>
          }
        </>
      :
        <>
          <MenuLogo />
          <MenuDivider />
        </>
      }
      <ul className={`menu-list ${collapse && 'menu-collapse'} ${collapse && menuOpen && 'menu-expanded'}`}>
        {compact && <MenuLogo style={{ display: 'block', textAlign: 'center', padding: '5px 0' }} />}
        <MenuItem to="/posts" onClick={handlePostsLink}>Posts</MenuItem>
        {user?.perm ? <MenuItem to="/chat">Chat</MenuItem> : null}
        <MenuItem to="/browse-jobs">Jobs</MenuItem>
        <MenuItem to="/products" onClick={handleProductsLink}>Marketplace</MenuItem>
        <MenuItem to="/shifts">Shifts</MenuItem>
        <MenuItem to="/browse-users">Members</MenuItem>
        <MenuItem to="/contact-us">Contact Us</MenuItem>
      </ul>
      {user && <ul className="menu-list menu-right">
        {user.perm === 0 ? 
          <>
            <MenuItem to="/login">Log in</MenuItem>
            <MenuItem to="/register">Sign up</MenuItem>
          </>
        :
        <>
          <Mailbox compact={compact} />
          <Important compact={compact} />
          <MenuGroup label="My Account">
            <MenuItem to={`/user/${user.username}`}>View profile</MenuItem>
            <MenuItem to="/edit-profile">Edit profile</MenuItem>
            <MenuItem to="/logout">Log out</MenuItem>
          </MenuGroup>
        </>
        }
      </ul>}
    </nav>
  );
}

export default Menu;
