import './Chat.css';
import React, { useRef } from 'react'
import Redirect from '../Redirect/Redirect';
import ChatBody from './ChatBody/ChatBody';
import ChatContextProvider from '../../contexts/ChatContextProvider';
import ChatContacts from './ChatContacts/ChatContacts';
import ChatMenu from './ChatMenu/ChatMenu';
import ChatSearch from './ChatSearch/ChatSearch';
import { Link } from 'react-router-dom';

function Chat() {
  const contactsRef = useRef(null);

  return (
    <div className="chat page">
      <Redirect to="/login" unless="user" />
      <ChatContextProvider page="messages">
        <div className="chat-area">
          <div className="chat-heading">
            <h1 style={{ margin: '0', flex: '1', whiteSpace: 'nowrap' }}>Chat with other users</h1>
            <Link to="/browse-users" className="wbtn" style={{ padding: '0 28px' }}>Browse Users</Link>
          </div>
          <div className="chat-box">
            <div className="chat-side hide-scroll">
              <ChatSearch contacts={contactsRef} />
              <ChatMenu />
              <ChatContacts ref={contactsRef} />
            </div>
            <ChatBody />
          </div>
        </div>
      </ChatContextProvider>
    </div>
  )
}

export default Chat