import './PostVotes.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function PostVotes({ total, mine, upvote, downvote }) {
  const interactable = upvote || downvote;
  return (
    <div className="post-votes">
      <FontAwesomeIcon className={interactable && "interactable-icon"} icon={`${mine === 1 ? 'fa-solid' : 'fa-regular'} fa-thumbs-up`} size="2x" onClick={upvote} />
      <div className="post-votes-value">{total}</div>
      <FontAwesomeIcon className={interactable && "interactable-icon"} icon={`${mine === -1 ? 'fa-solid' : 'fa-regular'} fa-thumbs-down`} size="2x" onClick={downvote} />
    </div>
  )
}

export default PostVotes