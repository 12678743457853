import React, { useState } from 'react'

function PointSelect({ onChange, value: v }) {
  const [value, setValue] = useState(() => ({
    h: (Math.floor(v%48/4) || 12).toString().padStart(2, '0'),
    m: (v%4*15).toString().padStart(2, '0'),
    p: v%96 < 48 ? 'AM' : 'PM',
  }));

  const change = (e) => {
    const newVal = {
      ...value,
      [e.target.dataset.prop]: e.target.value,
    };

    setValue(newVal);
    
    const h = parseInt(newVal.h, 10) % 12;
    const m = parseInt(newVal.m, 10);
    const p = newVal.p === 'PM';
    const v = h*4 + m/15 + p*48;
    
    onChange?.(v);
  };

  return (
    <div className="point-select" style={{ display: 'inline-block', marginBottom: 3 }}>
      <select onChange={change} data-prop="h" value={value.h}>
        {[...Array(12)].map((_, i) => {
          const v = (i || 12).toString().padStart(2, '0');
          return <option key={i}>{v}</option>
        })}
      </select>
      <select onChange={change} data-prop="m" value={value.m}>
        {['00', '15', '30', '45'].map((m, i) => {
          return <option key={i}>{m}</option>
        })}
      </select>
      <select onChange={change} data-prop="p" value={value.p}>
        <option>AM</option>
        <option>PM</option>
      </select>
    </div>
  )
}

export default PointSelect