import { ChatContext } from '../../../contexts/ChatContextProvider'
import './ChatMenu.css'
import React, { useContext } from 'react'

function ChatMenu() {
  const { page, setPage } = useContext(ChatContext);

  const pages = [
    ['message', 'Messages'],
    ['request', 'Requests'],
    ['block', 'Blocked'],
  ];

  return (
    <div className="chat-menu no-select">
      {pages.map(([key, label]) => (
        <div key={key} onClick={() => setPage(key)} className={`${key === page && 'active'}`}>{label}</div>
      ))}
    </div>
  )
}

export default ChatMenu