import React, { forwardRef, useImperativeHandle, useState } from 'react'

const PHONE_REGEX = /^\d{0,10}$/;
const PHONE_GROUPS = /^(\d{1,3})?(\d{1,3})?(\d{1,4})?$/;
const SANITIZE = (number) => number.replace(/-/g, '');
const CONVERT = (number) => number.match(PHONE_GROUPS).slice(1).filter(Boolean).join('-');

function SellPhone(props, ref) {
  const [value, setValue] = useState('');

  useImperativeHandle(ref, () => {
    return { setValue, value };
  }, [value]);

  const onChange = (e) => {
    const newValue = SANITIZE(e.target.value);
    if(PHONE_REGEX.test(newValue)) {
      setValue(newValue);
    }
  };

  const displayValue = CONVERT(value);
  
  return (
    <input 
      id="sell-phone" name="phone"
      value={displayValue} onChange={onChange}
      placeholder="e.g. 123 456 7890"
    />
  )
}

export default forwardRef(SellPhone)
