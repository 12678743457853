import { useCallback } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import axios from 'axios';

const THROTTLE = 3000, STALL = 5000;
const MAX_RETRIES = 1;

const useAutosave = (setSchedule) => {
  const save = useCallback(({ leave, retry, retries }, type, state, newType) => {
    let data;
    if(state) {
      const { days, timelineData, notify } = state;
      const timetable = Object.fromEntries(days.map(day => [day, timelineData[day]]));

      data = {
        type,
        newType,
        timetable,
        notify,
      };

      if(retries === 0) {
        setSchedule(schedule => {
          return {
            ...schedule,
            type: newType ?? type,
            [type]: timetable,
          };
        });
      }
    } else {
      data = {
        type,
        action: 'typeOnly',
      };

      if(retries === 0) {
        setSchedule(schedule => {
          return {
            ...schedule,
            type,
          };
        });
      }
    }

    if(!leave) {
      axios.post('/shifts/timetable', data)
      .catch(() => {
        if(retries < MAX_RETRIES) {
          retry();
        }
      });
    } else {
      const w = navigator.sendBeacon('/s/shifts/timetable', new Blob(
        [JSON.stringify(data)],
        { type: 'application/json' }
      ));
      if(!w && retries < MAX_RETRIES) {
        retry();
      }
    }
  }, [setSchedule]);

  return useDebounce(save, THROTTLE, { stall: STALL });
};

export default useAutosave;