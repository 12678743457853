import { obj_get } from "./obj";

class Notifications {
  constructor(user) {
    this.user_id = user?.id;
    this.notifications = user?.notifications ?? {};
  }

  get(path) {
    if(!this.user_id) return;
    return obj_get(this.notifications, path);
  }
}

export default Notifications;