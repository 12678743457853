import axios from 'axios';
import { UserContext } from '../../../contexts/UserContextProvider'
import pfp_path from '../../../util/pfp_path';
import Redirect from '../../Redirect/Redirect';
import './EditProfile.css'
import React, { useContext, useRef } from 'react'
import Button from '../../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import { SUPPORTED_EXTENSIONS, getext } from '../../../util/extensions';
import useUnsavedChanges from '../../../hooks/useUnsavedChanges';

const toastOptions = {
  duration: 2000,
  position: 'bottom-right',
};

function EditProfile() {
  const { user, setUser } = useContext(UserContext);  
  const jobTitleRef = useRef();
  const bioRef = useRef();
  const submitRef = useRef();
  const pfpRef = useRef();
  const dirty = useRef(false);

  const saveChanges = (e) => {
    e.preventDefault();
    submitRef.current.setPending(true);

    const jobTitle = jobTitleRef.current.value.trim();
    const bio = bioRef.current.value.trim();

    axios.post('/account/set-bio', { bio, jobTitle })
    .then(() => {
      toast.success('Profile updated.');
      setUser({ ...user, bio, jobTitle });
      dirty.current = false;
    })
    .catch(() => {
      toast.error('Something went wrong.');
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  const removePfp = () => {
    pfpRef.current.setPending(true);
    axios.post('/account/remove-pfp')
    .then(() => {
      toast.success('Profile picture removed.');
      setUser({ ...user, pfp: null });
    })
    .catch(() => {
      toast.error('Something went wrong.');
    })
    .finally(() => {
      pfpRef.current.setPending(false);
    });
  };

  const imageUpload = (e) => {
    const file = e.target.files[0];
    if(!file) return;
    const ext = getext(file.name);

    if(!SUPPORTED_EXTENSIONS.includes(ext)) {
      toast.error('File extension not allowed.');
      e.target.value = null;
      return;
    }

    const formData = new FormData();
    formData.append('upload', file);
    axios.post('/account/set-pfp', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(({ data }) => {
      toast.success('Profile picture updated.');
      setUser({ ...user, pfp: data });
    })
    .catch((error) => {
      const msg = error.response.status === 500 ?
                  'Something went wrong.' :
                  error.response.data;
      toast.error(msg);
    })
    .finally(() => {
      e.target.value = null;
    });
  };

  useUnsavedChanges(() => dirty.current);

  if(!user) return null;

  return (
    <div className="edit-profile container">
      <Redirect to="/login" unless="user" />
      <Toaster toastOptions={toastOptions} />
      <div className="edit-profile-viewport">
        <div className="edit-profile-pfp">
          <div className="edit-profile-pfpport">
            <img src={pfp_path(user.id, user.pfp)} alt="" width="128" height="128" />
            <label className="edit-profile-pfpoverlay">
              <FontAwesomeIcon icon="fa-solid fa-camera" />
              <div style={{fontSize: '16px'}}>Upload</div>
              <input type="file" onChange={imageUpload} accept={SUPPORTED_EXTENSIONS.join()} />
            </label>
          </div>
          {
            user.pfp && 
            <div style={{ marginTop: '10px' }}>
              <Button className="wbtn wbtn-outline" onClick={removePfp} ref={pfpRef}>Remove</Button>
            </div>
          }
        </div>
        <form className="edit-profile-info" onSubmit={saveChanges} onChange={() => dirty.current = true}>
          <div className="form-field">
            <label htmlFor="edit-profile-job-title">Your Job Title</label>
            <input defaultValue={user.jobTitle} ref={jobTitleRef} type="text" id="edit-profile-job-title" maxLength="128" />
          </div>
          <div className="form-field">
            <label htmlFor="edit-profile-bio">Profile Description</label>
            <textarea className="edit-profile-bio" id="edit-profile-bio" defaultValue={user.bio} ref={bioRef} maxLength="2048"></textarea>
          </div>
          <div className="edit-profile-submit">
            <Button className="wbtn" ref={submitRef}>Save Changes</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditProfile