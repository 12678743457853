
import { useRef } from 'react';

function rand(min, max) {
  return min + Math.floor(Math.random()*(max-min+1));
}

function useRandom(min, max) {
  const value = useRef(rand(min, max));

  return [value.current, () => value.current = rand(min, max)];
}

export default useRandom;