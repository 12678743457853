import { useMemo, useRef } from "react";

function useLazyLoad(fn) {
  const loading = useRef(false);
  const cbRef = useRef(fn);
  cbRef.current = fn;

  const elemRef = useRef();
  const observer = useMemo(() => {
    return new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting && !loading.current) {
        if(loading.current) return;
        loading.current = true;
        cbRef.current(() => {
          loading.current = false;
          if(elemRef.current) {
            observer.unobserve(elemRef.current);
            observer.observe(elemRef.current);
          }
        });
      }
    });
  }, []);

  return (elem) => {
    elemRef.current = elem;
    if(elem) {
      observer.observe(elem);
    } else {
      observer.disconnect();
    }
  };
}

export default useLazyLoad;