import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FormError from '../../FormError/FormError';
import useValidation from '../../../hooks/useValidation';
import axios from 'axios';
import { rules } from '../../Account/Register/registerRules';
import PasswordToggle from '../../PasswordToggle/PasswordToggle';
import Button from '../../Button/Button';

function ChangePassword() {
  const { error, useField, validateAll, throwError, removeError } = useValidation({ password: rules.password });
  const submitRef = useRef();

  const [searchParams] = useSearchParams();
  const [formDisabled, setFormDisabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = validateAll();
    removeError('generic');
    removeError('success');
    if(!data) return;

    data.id = searchParams.get('i');
    data.token = searchParams.get('t');
    submitRef.current.setPending(true);
    axios.post('/account/change-password', data)
    .then(() => {
      throwError('success', 'Your password has been successfully changed.');
      setFormDisabled(true);
    })
    .catch((error) => {
      if(error.response.status === 400) {
        throwError('generic', 'Reset password link is invalid or expired.');
      } else {
        throwError('generic', 'Something went wrong.');
      }
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  return (
    <div className="change-password container">
      <form className="form-panel" onSubmit={handleSubmit}>
        <h1>Reset Password</h1>
        <FormError msg={error.msg.success} success />
        <FormError msg={error.msg.password ?? error.msg.generic} />
        <div className="form-field" data-error={error.has.password}>
          <label htmlFor="change-password-1">New Password*</label>
          <PasswordToggle disabled={formDisabled} name="password" id="change-password-1" ref={useField()} maxLength="256" placeholder="Enter your new password" />
        </div>
        <div className="form-field">
          <Button ref={submitRef} type="submit" disabled={formDisabled}>Submit</Button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;