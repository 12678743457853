import './Contact.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TextareaAutoresize from 'react-textarea-autosize'
import Button from '../Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../contexts/UserContextProvider';
import Input from '../Input/Input';
import axios from 'axios';
import useValidation from '../../hooks/useValidation';
import FormError from '../FormError/FormError';
import ReCAPTCHA from 'react-google-recaptcha';
import useUnsavedChanges from '../../hooks/useUnsavedChanges';

const rules = {
  email: true,
  subject: (value) => {
    value = value.trim();
    if(!value)
      return false;
    if(value.length < 3)
      return 'Subject is too short.';
    return true;
  },
  message: (value) => {
    value = value.trim();
    if(!value)
      return false;
    if(value.length < 5)
      return 'Message is too short.';
    return true;
  }
};

function Contact() {
  const { user } = useContext(UserContext);

  const submitRef = useRef();
  const captchaRef = useRef();
  const dirty = useRef(false);

  const [defaultEmail, setDefaultEmail] = useState();
  const { error, useField, throwError, removeError, validateAll } = useValidation(rules);
  const [formDisabled, setFormDisabled] = useState(false);

  useEffect(() => {
    if(!user?.email) return;
    setDefaultEmail(user.email);
  }, [user]);

  const send = () => {
    removeError('general');
    removeError('captcha');

    const data = validateAll();
    if(!data) {
      throwError('general', 'There were some errors down below.');
      window.scrollTo(0, 0);
      return;
    }
    
    data.captcha = captchaRef.current.getValue();
    if(!data.captcha) {
      throwError('captcha', 'Click on the checkbox.');
      return;
    }

    submitRef.current.setPending(true);    
    axios.post('/contact', data)
    .then(() => {
      throwError('success', 'Your message has been submitted. Thank you for reaching out.');
      setFormDisabled(true);
    })
    .catch(() => {
      throwError('general', 'Something went wrong.');
    })
    .finally(() => {
      submitRef.current.setPending(false);
      window.scrollTo(0, 0);
    });
  };

  const onChange = () => {
    dirty.current = true;
  };

  useUnsavedChanges(() => dirty.current && !formDisabled);

  return (
    <div className="contact container">
      <form className="form-panel" onChange={onChange} onSubmit={e => e.preventDefault()}>
        <h1>Contact Us</h1>
        {!error.has.success && <div className="alert">
          <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />
          Let us know if you have any questions, problems, or feature requests. We will email you back as soon as possible.
        </div>}
        <FormError msg={error.msg.general} />
        <FormError msg={error.msg.success} success />
        <div className="form-field" data-error={error.has.email}>
          <label htmlFor="contact-email">Your Email*</label>
          <Input readOnly={formDisabled} ref={useField()} defaultValue={defaultEmail} name="email" id="contact-email" maxLength="128" style={{ maxWidth: '400px', display: 'block' }} />
        </div>
        <div className="form-field" data-error={error.has.subject}>
          <FormError msg={error.msg.subject} />
          <label htmlFor="contact-subject">Subject*</label>
          <input readOnly={formDisabled} ref={useField()} name="subject" id="contact-subject" maxLength="200" />
        </div>
        <div className="form-field" data-error={error.has.message}>
          <FormError msg={error.msg.message} />
          <label htmlFor="contact-message">Message*</label>
          <TextareaAutoresize readOnly={formDisabled} ref={useField()} name="message" id="contact-message" minRows="14" maxRows="24" maxLength="20000" />
        </div>
        {!formDisabled && <div style={{ marginTop: '15px' }}>
          <FormError msg={error.msg.captcha} />
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_API}
          />
        </div>}
        <div style={{ marginTop: '15px', textAlign: 'right' }}>
          <Button disabled={formDisabled} ref={submitRef} className="wbtn" type="button" onClick={send}>Submit</Button>
        </div>
      </form>
    </div>
  )
}

export default Contact