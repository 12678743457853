import './SquareSelect.css'
import React, { useState } from 'react'

function SquareSelect({ children, onChange, defaultSelected, ...props }) {
  children = React.Children.toArray(children);

  const getKey = ({ props: { value, children } }) => (
    value ?? children
  );

  const [selected, setSelected] = useState(() => Object.fromEntries(
    defaultSelected ? 
    defaultSelected.map(value => [value, 1]) :
    children
    .filter(({ props: { selectAll, defaultSelected } }) => !selectAll && defaultSelected)
    .map(child => [getKey(child), 1])
  ));
  
  const _setSelected = (selected) => {
    setSelected(selected);
    onChange?.(
      children
      .map(child => getKey(child))
      .filter(key => selected[key])
    );
  };

  const set = (key, state) => {
    if(state) {
      _setSelected({
        ...selected,
        [key]: 1
      });
    } else {
      const { [key]: _, ...pruned } = selected;
      _setSelected(pruned);
    }
  };

  const setAll = (state) => {
    if(state) {
      _setSelected(Object.fromEntries(
        children
        .filter(({ props: { selectAll } }) => !selectAll)
        .map(child => [getKey(child), 1])
      ));
    } else {
      _setSelected({});
    }
  };

  return (
    <div className="square-select no-select" {...props}>
      {children.map(child => {
        const key = getKey(child);
        if(!child.props.selectAll) {
          return React.cloneElement(child, {
            key,
            selected: selected[key],
            toggle: set.bind(null, key, !selected[key]),
          });
        }

        const allSelected = Object.values(selected).length >= children.length-1;
        return React.cloneElement(child, {
          key,
          selected: allSelected,
          toggle: setAll.bind(null, !allSelected),
        });
      })}
    </div>
  )
}

export default SquareSelect