import axios from 'axios';
import Button from '../Button/Button';
import CustomEditor from '../CustomEditor/CustomEditor';
import './WriteComment.css';
import React, { useContext, useRef } from 'react';
import { PostContext } from '../../contexts/PostContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { Link } from 'react-router-dom';

function WriteComment({ newComment }) {
  const { postId, activeEditors, mentions } = useContext(PostContext);
  const { user } = useContext(UserContext);

  const editorRef = useRef(null);
  const submitRef = useRef();

  const onReady = (editor) => {
    activeEditors.current.comment = editor;
  };

  const submitComment = () => {
    if(!editorRef.current) return;
    submitRef.current.setPending(true);

    const body = editorRef.current.getData();
    axios.post('/comment/create', { id: postId, body })
    .then(({ data }) => {
      editorRef.current.setData('');
      newComment(data);
    })
    .catch((error) => {
      if(error.response.status === 413) {
        alert('Your post contains too much data.');
      } else if(error.response.status === 429) {
        alert('You have reached your daily comment/reply limit.');
      } else if(error.response.status === 400) {
        if(error.response.data !== '#ignore')
          alert(error.response.data);
      } else {
        alert('Something went wrong.');
      }
    })
    .finally(() => {
      submitRef.current.setPending(false);
    });
  };

  return (
    <div className="write-comment post-container">
      <h2 className="write-comment-heading">Leave a comment</h2>
      <div className="write-comment-editor">
        {
          user && (
            user.perm ? 
            <div className="smaller-media">
              <CustomEditor ref={editorRef} onReady={onReady} mentions={mentions} />
              <div className="write-comment-submit">
                <Button ref={submitRef} type="button" className="wbtn" onClick={submitComment}>Comment</Button>
              </div>
            </div> :
            <div style={{ textAlign: 'center' }}>
              You must <Link to="/register">sign up</Link> to post a comment.
            </div>
          )
        }
      </div>
    </div>
  );
}

export default WriteComment;