
import { useRef, useCallback } from 'react';

function useObserver(observer) {
  const ref = useRef();

  return [
    useCallback((elem) => {
      if(elem) {
        observer.observe(elem);
      } else {
        observer.unobserve(ref.current);
      }
      ref.current = elem;
    }, [observer]),
    ref
  ];
}

export default useObserver;