import './App.css';
import React, { Route, createRoutesFromElements, RouterProvider, Routes } from 'react-router';
import Menu from './components/Menu/Menu';
import Register from './components/Account/Register/Register';
import { io } from 'socket.io-client';
import VerifyEmail from './components/Account/VerifyEmail/VerifyEmail';
import Login from './components/Account/Login/Login';
import Logout from './components/Account/Logout/Logout';
import Error404 from './components/Error404/Error404';
import UserContextProvider from './contexts/UserContextProvider';
import ResetPassword from './components/Account/ResetPassword/ResetPassword';
import ChangePassword from './components/Account/ChangePassword/ChangePassword';
import WritePost from './components/WritePost/WritePost';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { createBrowserRouter } from 'react-router-dom';
import PostsWrapper from './components/PostsWrapper/PostsWrapper';
import EditProfile from './components/Account/EditProfile/EditProfile';
import ViewUser from './components/ViewUser/ViewUser';
import Chat from './components/Chat/Chat';
import PostJob from './components/Jobs/PostJob/PostJob';
import ViewJob from './components/Jobs/ViewJob/ViewJob';
import ApplyJob from './components/Jobs/ApplyJob/ApplyJob';
import ViewApplication from './components/Jobs/ViewApplication/ViewApplication';
import Jobs from './components/Jobs/Jobs/Jobs';
import BrowseUsers from './components/BrowseUsers/BrowseUsers';
import Home from './components/Home/Home';
import ErrorGeneral from './components/ErrorGeneral/ErrorGeneral';
import Sell from './components/Store/Sell/Sell';
import ViewProduct from './components/Store/ViewProduct/ViewProduct';
import Contact from './components/Contact/Contact';
import Products from './components/Store/Products/Products';
import Shifts from './components/Shifts/Shifts';
import PostShift from './components/Shifts/components/PostShift/PostShift';
import ViewShift from './components/Shifts/components/ViewShift/ViewShift';
// import Pay from './components/Pay/Pay';

// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';

export const socket = io(process.env.REACT_APP_BASE_URL, { withCredentials: true });
export const tempLogin = {};
export const globals = {};

const siteRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorGeneral />}>
      <Route path="/verify" element={<VerifyEmail />} />
      <Route path="*" element={<Pages />} />
    </Route>
  )
);

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
// const stripeOptions = {
//   mode: 'payment',
//   amount: 1099,
//   currency: 'cad',
//   appearance: {
//     theme: 'stripe'
//   },
// };

function App() {
  return (
    <div className="App">
      {/*<Elements stripe={stripePromise} options={stripeOptions}>*/}
        <UserContextProvider>
          <RouterProvider router={siteRouter} />
        </UserContextProvider>
      {/*</Elements>*/}
    </div>
  );
}

function Pages(props) {
  return (
    <>
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout/:state?" element={<Logout />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/write-post" element={<WritePost />} />
        <Route path="/posts/*" element={<PostsWrapper />} />
        <Route path="/user/*" element={<ViewUser />} />
        <Route path="/browse-users/:search?" element={<BrowseUsers />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/chat/*" element={<Chat />} />
        <Route path="/post-job/:id?" element={<PostJob />} />
        <Route path="/job-application/:id" element={<ViewApplication />} />
        <Route path="/jobs/view/:id" element={<ViewJob />} />
        <Route path="/jobs/apply/:id" element={<ApplyJob />} />
        <Route path="/browse-jobs/:filter?/:search?" element={<Jobs />} />
        <Route path="/sell/:id?" element={<Sell />} />
        <Route path="/products/view/:id" element={<ViewProduct />} />
        <Route path="/products/:filter?/:search?" element={<Products />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/shifts" element={<Shifts />} />
        <Route path="/post-shift/:id?" element={<PostShift />} />
        <Route path="/shifts/view/:id" element={<ViewShift />} />
        {/*<Route path="/payment" element={<Pay />} />*/}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default App;
library.add(fab, fas, far);
