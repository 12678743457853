
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useUnmount from './useUnmount';

// callback will run after (throttle < ms < throttle + stall)
function useDebounce(callback, throttle, { stall=Infinity, flushOnUnmount=true }) {
  const exec = useRef(null);
  const callId = useRef(null);
  const timerId = useRef(null);
  const firstTime = useRef(0);
  const retryCount = useRef(0);

  const flush = useCallback((cancel, leave) => {
    clearTimeout(timerId.current);
    if(!cancel) {
      exec.current?.(leave);
    } else {
      exec.current = null;
    }
  }, []);

  const call = useCallback((...args) => {
    const pending = exec.current !== null;
    const retries = retryCount.current;
    
    if(retries === 0)
      callId.current = Math.random();
    const lastCallId = callId.current;

    exec.current = (leave) => {
      exec.current = null;
      
      const retry = () => {
        if(callId.current !== lastCallId) return;
        retryCount.current = retries+1;
        call(...args);
        retryCount.current = 0;
      };
      
      callback({
        leave,
        retry,
        retries, 
      }, ...args);
    };

    const now = Date.now();
    if(!pending) {
      firstTime.current = now;
    } else if(now - firstTime.current >= stall) {
      return;
    }

    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => exec.current(false), throttle);
  }, [callback, throttle, stall]);

  useEffect(() => {
    const onLeave = () => flush(false, true);
    const onVisibilityChange = () => {
      if((document.visibilityState || document.webkitVisibilityState) === 'hidden') {
        onLeave();
      }
    };
    
    document.addEventListener('visibilitychange', onVisibilityChange);
    document.addEventListener('webkitvisibilitychange', onVisibilityChange);
    window.addEventListener('pagehide', onLeave);
    window.addEventListener('beforeunload', onLeave);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
      document.removeEventListener('webkitvisibilitychange', onVisibilityChange);
      window.removeEventListener('pagehide', onLeave);
      window.removeEventListener('beforeunload', onLeave);
    };
  }, [flush]);

  useUnmount(() => {
    if(flushOnUnmount) {
      flush();
    } else {
      clearTimeout(timerId.current);
    }
  });

  return useMemo(() => [call, flush], [call, flush]);
}

export default useDebounce;